import React from "react";
import './cartography.scss';
import CartographyManager from "../../manager/cartography/CartographyManager";

const CartographyPage = () => {

    return (
        <CartographyManager/>
    );
};

export default CartographyPage;
