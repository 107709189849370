import React from "react";
import { connect } from "react-redux";
import './createUserRequest.scss';
import { updateCurrentUserRequest, addAttachmentRequest } from "../../actions";
import { withRouter } from "react-router";
import Step3Form from "../../components/forms/createUserRequest/Step3Form";
import UserRequestsService from "../../services/UserRequestsService";
import { StepManager } from "./StepManager";

class Step3Manager extends StepManager {
    state = { isDisabledBtn: true };

    constructor(props: any) {
        super(props);
        if (!props.mdmData.categories) {
            UserRequestsService.getMdmData()
        }
    }

    formValuesToUserRequest(formValues: any): any {
        let { userRequest } = this.props;
        const userRequestData = userRequest.data;

        // Delete all step3 props
        delete userRequestData['requestOperationType'];
        delete userRequestData['requestCleansingType'];
        delete userRequestData['requestCategory'];
        delete userRequestData['requestSubCategory'];
        delete userRequestData['requestExpectedTreatment'];
        const dataFormValues = { ...formValues };
        delete dataFormValues['attachments'];

        return { ...userRequest, data: { ...userRequestData, ...dataFormValues } }
    }

    public render() {
        const { userRequest, mdmData, previousStepHandle, requestFiles, user } = this.props;

        return (
            <Step3Form
                values={userRequest}
                saveDraft={this.saveDraft.bind(this)}
                otherAttachment={requestFiles.other}
                nextStepHandle={this.nextStepHandle.bind(this)}
                previousStepHandle={previousStepHandle.bind(this)}
                mdmData={mdmData}
                user={user}
            />
        );
    }
}

const mapStateToProps = (state: any) => ({
    userRequest: state.userRequests.currentRequest,
    requestFiles: state.userRequests.currentRequestFiles,
    mdmData: state.userRequests.mdmData,
    user: state.user.info
});

const mapDispatchToProps = {
    updateCurrentUserRequest: updateCurrentUserRequest,
    addAttachmentRequest: addAttachmentRequest
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Step3Manager));
