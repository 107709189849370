import { Constants } from "../constants";
import { INotificationConfiguration, ISetNotificationConfigurationAction } from "../types";

// actions 
export const login = (userInfo: any) => ({
    type: Constants.LOG_IN,
    payload: userInfo
});

export const logout = () => ({
    type: Constants.LOG_OUT
});

export const setNotificationConfiguration = (nc:  INotificationConfiguration): ISetNotificationConfigurationAction => ({
    type: Constants.SET_NOTIFICATION_CONFIGURATION,
    payload: nc
});
