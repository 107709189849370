import React from "react";
import { connect } from "react-redux";
import './createUserRequest.scss';
import Step5Form from "../../components/forms/createUserRequest/Step5Form";
import { StepManager } from "./StepManager";
import { withRouter } from "react-router";
import UserRequestsService from "../../services/UserRequestsService";
import Step5DetailManager from "../Detail/Step5DetailManager";
import { AppUrls } from "../../constants";

class Step5Manager extends StepManager {
    state = {
        isDisabledBtn: true,
        mobile: false,
    };

    componentDidMount() {
        this.setState({
            mobile: window.innerWidth < 768
        });
        window.addEventListener('resize', () => {
            this.setState({
                mobile: window.innerWidth < 768
            });
        }, false);
    }

    formValuesToUserRequest(formValues: any) {
        const { userRequest } = this.props;

        return {
            ...userRequest, ...formValues
        }
    }

    saveUserRequest() {
        const { userRequest, requestFiles, history } = this.props;
        const id = userRequest.id;
        if (id !== undefined && id) {
            UserRequestsService.putUserRequest(userRequest, requestFiles, true).then(() => {
                history.push(AppUrls.HOME);
            });
        } else {
            UserRequestsService.postUserRequest(userRequest, history, requestFiles);
        }

    }

    public render() {
        const { userRequest, previousStepHandle, categories, requestFiles, organisations, mdmData, user } = this.props;
        return (
            <>
                <Step5DetailManager
                    requestFiles={requestFiles}
                    userRequest={userRequest}
                    categories={categories}
                    organisations={organisations}
                    mdmData={mdmData}
                    user={user}
                    mobile={this.state.mobile}
                />
                <Step5Form
                    values={userRequest}
                    originalRequest={userRequest.originalRequest}
                    saveDraft={this.saveDraft.bind(this)}
                    nextStepHandle={this.nextStepHandle.bind(this)}
                    previousStepHandle={previousStepHandle.bind(this)}
                    saveUserRequest={this.saveUserRequest.bind(this)}
                    mobile={this.state.mobile}
                />
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    userRequest: state.userRequests.currentRequest,
    requestFiles: state.userRequests.currentRequestFiles,
    categories: state.userRequests.mdmData.categories,
    organisations: state.userRequests.mdmData.organisations,
    mdmData: state.userRequests.mdmData,
    user: state.user.info
});

const mapDispatchToProps = {};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Step5Manager));
