import React, { createRef } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { LayersControl, Marker, Popup, TileLayer } from "react-leaflet";
import { LatLng } from "leaflet";
import { MAP_AZURE_KEY, MAP_AZURE_URI, MAP_URI, RequestListPages } from "../../constants";
import MapComponent from '../../components/elements/mapComponent';
import MapSearch from '../../components/elements/mapSearch';
import MapLegend from '../../components/elements/mapLegend';
import MapPopup from '../../components/elements/mapPopup';
import icons from "../../components/elements/mapComponent/icons";
import UserRequestsService from "../../services/UserRequestsService";
import './carto.scss'
import {
    setCurrentPage,
    triggerAllFilters,
    triggerAssignedFilters,
    triggerMyFilters,
    triggerPendingFilters
} from "../../actions";
import FilterList from "../../components/forms/filterList/FilterList";
import userRequestsService from "../../services/UserRequestsService";
import { SzButton, SzInput } from "@suezenv/react-theme-components";

class CartographyManager extends React.Component<any> {
    type = RequestListPages.ALL;
    refMarker = createRef<Marker>();
    marker: LatLng = new LatLng(43.604652, 1.444209); // Toulouse centered
    bounds = [
        new LatLng(43.519822, 1.317482),
        new LatLng(43.678199, 1.557808)
    ]; // Toulouse boundaries
    zooms = { zoom: 12, minZoom: 11, maxZoom: 19, searchZoom: 15 };
    itemsPerPage = 200;
    state = {
        appliedSearchQuery: '',
        loadingIntervention: false,
        marker: this.marker,
        searchQuery: '',
        shapes: null,
        type: this.type,
        showFilters: false,
    };
    constructor(props: any) {
        super(props);

        if (!this.props.userRequests.shapes) {
            UserRequestsService.getGeoShapes();
        }

        // Read categories to test if object has been initialized already
        if (undefined === props.mdmData.category) {
            UserRequestsService.getMdmData()
        }
    }

    formatDate(dateString: string) {
        const date = new Date(dateString);
        const toTwoDigit = (value: number) => (`0${value}`).slice(-2);
        return `${toTwoDigit(date.getDate())}/${toTwoDigit(date.getMonth() + 1)}/${date.getFullYear()}`;
    }

    formatRequests(userRequests: any): any {
        let formattedRequests: any = [];
        if (userRequests.items) {
            userRequests.items.map((item: any) => {
                const { number, street, city, latitude, longitude } = item.address;

                let cleanAddress = `${street}, ${city}`;
                if (number) {
                    cleanAddress = `${number} ${cleanAddress}`
                }

                const request = {
                    status: item.currentStatus.status.label,
                    statusId: item.currentStatus.status.id,
                    id: item.incrementalId,
                    category: item.data.requestCategory.label,
                    address: cleanAddress,
                    createdAt: this.formatDate(item.createdAt),
                    processingDuration: item.data.processingDuration,
                    requesterName: item.data.requesterName,
                    interventionReference: item.data.interventionReference,
                    requestId: item.id,
                    latitude: latitude,
                    longitude: longitude,
                };
                formattedRequests.push(request);

                return formattedRequests;
            });
        }
        return formattedRequests;
    }

    componentDidUpdate(prevProps: any) {
        const { shapes } = this.props.userRequests;
        if (prevProps.userRequests.shapes !== shapes) {
            this.setState({ shapes: shapes })
        }
    }

    componentDidMount() {
        const { allFilters, userRequests } = this.props;

        this.setState({ shapes: null });
        this.setState({ shapes: userRequests.shapes })
        this.setIndicators(allFilters);
    }

    getIntervention = (interventionReference: string) => {
        this.setState({
            loadingIntervention: true
        });
        if (interventionReference) {
            UserRequestsService.getInterventions(interventionReference, true).then(() => {
                this.setState({
                    loadingIntervention: false
                })
            }).catch(() => {
            })
        } else {
            UserRequestsService.clearInterventions()
            this.setState({
                loadingIntervention: false
            })
        }
    };

    getFilteredRequests(filter: any, page = 1, sortField = 'createdAt', direction = 'desc') {
        this.setState({ filter: filter });
        if (this.state.searchQuery.length > 0) {
            this.setState({
                appliedSearchQuery: this.state.searchQuery
            })
        }
        this.setState({ filter: { ...filter, all: filter } });
        UserRequestsService.getFilteredRequestVisibleToUser(page, {
            ...filter,
            query: this.state.searchQuery,
        }, sortField, direction, this.itemsPerPage)
    }

    toggleFilters = () => {
        const { showFilters } = this.state;
        this.setState({ showFilters: !showFilters });
    };

    onChangeHandle = (e: any): any => {
        const value = e.target.value;
        this.setState({ searchQuery: value });
    };

    onSearchHandle = (): any => {
        const { allFilters } = this.props;
        const { searchQuery } = this.state;
        let filter = {
            query: '',
        };

        if (searchQuery.length > 0) {
            filter.query = searchQuery;
        }
        this.setState({
            appliedSearchQuery: searchQuery,
        });

        userRequestsService.getFilteredRequestVisibleToUser(1, {
            ...filter,
            ...allFilters,
            query: searchQuery,
        });
        this.refreshIndicatorsHandle(allFilters);
    };

    onSubmitHandle = (e: any): any => {
        e.preventDefault();
    };

    refreshIndicatorsHandle = (filter: any = {}) => {
        this.setIndicators(filter);
    };

    resetSearchQuery = () => {
        this.setState({ searchQuery: '' }, () => {
            this.onSearchHandle();
        });
    };

    setIndicators(filter = {}) {
        return userRequestsService.getAllIndicators({
            ...filter,
            query: this.state.searchQuery,
        });
    }

    render() {
        const {
            currentInterventions,
            userRequests,
            t,
            mdmData,
            allIndicators,
            myIndicators,
            pendingIndicators,
            assignedIndicators,
            allFilters,
            myFilters,
            pendingFilters,
            assignedFilters,
        } = this.props;
        const { type, marker, loadingIntervention, showFilters, searchQuery, appliedSearchQuery } = this.state;
        const { BaseLayer } = LayersControl;
        const { lat, lng } = marker;
        const bounds = this.bounds;
        const zooms = this.zooms;
        const data = this.formatRequests(userRequests[type]);

        return (
            <>
                <div className="row mt-5">
                    <div className="col-3">
                        <h1>{t('MENU_CARTOGRAPHY')}</h1>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12">
                        <div className="row position-relative">
                            <div className="col-6">
                                <form onSubmit={this.onSubmitHandle}>
                                    <div className="form-row">
                                        <div className="col-10">
                                            <SzInput
                                                onChange={this.onChangeHandle}
                                                placeholder={t('search')}
                                                value={searchQuery}
                                            />
                                        </div>
                                        <div className="col-1">
                                            <SzButton
                                                className="filter-search-submit pl-4"
                                                icon="search"
                                                onClick={this.onSearchHandle}
                                                type="submit"
                                            >
                                                <span className="text-hide">{t('ok')}</span>
                                            </SzButton>
                                        </div>
                                        <div className="col-1 position-relative">
                                            {showFilters && (
                                                <div className="box-shadow w-100 h-100 position-absolute ptn-5" />
                                            )}
                                            <SzButton
                                                className="request-filter mx-2 z-base pl-4"
                                                icon="filter-1"
                                                onClick={this.toggleFilters}
                                                variant="secondary"
                                            >
                                                <span className="text-hide">{t('filter')}</span>
                                            </SzButton>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <FilterList
                                searchQuery={appliedSearchQuery}
                                resetSearchQuery={this.resetSearchQuery}
                                toggleFilters={this.toggleFilters.bind(this)}
                                data={mdmData}
                                type={type}
                                showFilters={showFilters}
                                refreshIndicatorsHandle={this.refreshIndicatorsHandle}
                                getFilteredRequests={this.getFilteredRequests.bind(this)}
                                allIndicators={allIndicators}
                                myIndicators={myIndicators}
                                pendingIndicators={pendingIndicators}
                                assignedIndicators={assignedIndicators}
                                allFilters={allFilters}
                                myFilters={myFilters}
                                pendingFilters={pendingFilters}
                                assignedFilters={assignedFilters}
                                triggerAllFilters={triggerAllFilters}
                                triggerMyFilters={triggerMyFilters}
                                triggerPendingFilters={triggerPendingFilters}
                                triggerAssignedFilters={triggerAssignedFilters}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    </div>
                </div>
                <div className="h-title">
                    <MapComponent
                        geoShapes={this.state.shapes}
                        zooms={zooms}
                        center={[lat, lng]}
                    >
                        <MapSearch
                            maxBounds={bounds}
                            searchZoom={zooms.searchZoom}
                        />

                        <LayersControl position='topright'>
                            <BaseLayer checked name={t('map_plan_view')}>
                                <TileLayer url={MAP_URI}
                                    attribution='© <a href="https://osm.org/copyright">OpenStreetMap</a> contributors' />
                            </BaseLayer>
                            <BaseLayer name={t('map_satellite_view')}>
                                <TileLayer url={MAP_AZURE_URI}
                                           attribution='© TomTom, Microsoft'
                                           subscriptionKey={MAP_AZURE_KEY}
                                           tilesetId='microsoft.imagery'
                                           language='fr-FR'
                                           view='Auto'
                                />
                            </BaseLayer>
                        </LayersControl>

                        <MapLegend />

                        {data.map((request: any, index: any) => {
                            const { latitude, longitude, statusId } = request;
                            return latitude && longitude ? (
                                <Marker
                                    icon={icons[statusId]}
                                    key={index}
                                    position={[latitude, longitude]}
                                    ref={this.refMarker}
                                >
                                    <Popup
                                        key={request.id}
                                        onOpen={() => {
                                            this.getIntervention(request.interventionReference)
                                        }}
                                        onClose={() => {
                                            if (this.props.errorIntervention.isError) {
                                                UserRequestsService.resetInterventionError()
                                            }
                                        }}
                                    >
                                        <MapPopup
                                            request={request}
                                            interventions={currentInterventions}
                                            loadingIntervention={loadingIntervention}
                                        />
                                    </Popup>
                                </Marker>
                            ) : null;
                        })}
                    </MapComponent>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    userRequests: state.userRequests,
    currentInterventions: state.userRequests.currentInterventions,
    mdmData: state.userRequests.mdmData,
    allFilters: state.userRequests.allFilters,
    errorIntervention: state.errorIntervention,


    myIndicators: state.userRequests.myIndicators,
    allIndicators: state.userRequests.allIndicators,
    pendingIndicators: state.userRequests.pendingIndicators,
    assignedIndicators: state.userRequests.assignedIndicators,
    myFilters: state.userRequests.myFilters,
    pendingFilters: state.userRequests.pendingFilters,
    assignedFilters: state.userRequests.assignedFilters,
    pages: state.userRequests.pages,
    sortBy: state.userRequests.sortBy,
    user: state.user.info,
});
const mapDispatchToProps = {
    triggerAllFilters: triggerAllFilters
};

export default connect(
    mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CartographyManager));
