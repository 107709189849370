import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './notificationSettings.scss';
import {
  SzAlert,
  SzBox,
  SzButton,
  SzCheckbox,
  SzTypographie
} from '@suezenv/react-theme-components';
import { setNotificationConfiguration } from '../../../actions/user';
import {
  Constants,
  HttpCode,
  notificationPerimeters,
  notificationsEvents
} from '../../../constants';
import NotificationConfigurationService from '../../../services/NotificationConfigurationService';
import { INotificationConfiguration } from '../../../types';

class NotificationSettings extends React.Component<any> {
  defaultStatusChange = false;
  defaultNewComment = false;
  defaultNewAttachment = false;
  defaultPerimeter = notificationPerimeters.all;
  setNotificationConfiguration: any;
  state: {
    statusChange: boolean;
    newComment: boolean;
    newAttachment: boolean;
    perimeter: notificationPerimeters;
    displaySuccessAlert: boolean;
    displayErrorAlert: boolean;
  } = {
    statusChange: this.defaultStatusChange,
    newComment: this.defaultNewComment,
    newAttachment: this.defaultNewAttachment,
    perimeter: this.defaultPerimeter,
    displayErrorAlert: false,
    displaySuccessAlert: false
  };

  constructor(props: any) {
    super(props);
    this.setNotificationConfiguration = props.setNotificationConfiguration;
    this.getConfiguration(this.props.userId);
  }

  componentDidUpdate(prevProps: any) {
    let statusChange,
      newComment,
      newAttachment = false;
    if (this.props.notificationConfiguration.events) {
      const events = this.props.notificationConfiguration.events;
      statusChange = events.indexOf(notificationsEvents.statusChange) !== -1;
      newComment = events.indexOf(notificationsEvents.newComment) !== -1;
      newAttachment = events.indexOf(notificationsEvents.newAttachment) !== -1;
    }
    const perimeter = this.props.notificationConfiguration.perimeter;
    if (
      this.state.newAttachment !== newAttachment ||
      this.state.newComment !== newComment ||
      this.state.statusChange !== statusChange ||
      this.state.perimeter !== perimeter
    ) {
      this.setState({
        ...this.state,
        statusChange: statusChange,
        newComment: newComment,
        newAttachment: newAttachment,
        perimeter: perimeter
      });
    }
  }

  public getConfiguration = (userId: string) => {
    NotificationConfigurationService.getUserNotificationConfiguration(
      userId
    ).then(response => {
      if (response.status === HttpCode.OK) {
        if (!response.data.userId) {
          response.data.userId = this.props.userId;
        }
        return this.setNotificationConfiguration(response.data);
      } else {
        return this.setNotificationConfiguration(
          this.getDefaultConfiguration()
        );
      }
    });
  };

  public setConfiguration(key: string, value: string | boolean) {
    let conf = this.props.notificationConfiguration;

    if (key === Constants.NOTIFICATION_PERIMETER_LABEL) {
      conf.perimeter = value;
    } else {
      const index = conf.events.indexOf(key);
      if (index === -1 && value === true) {
        conf.events.push(key);
      } else if (index !== -1 && value === false) {
        conf.events = conf.events.filter((current: string, index: string) => {
          return current !== key && current !== '';
        });
      }
    }
    this.setNotificationConfiguration(conf);
  }

  private getDefaultConfiguration(): INotificationConfiguration {
    return {
      events: [],
      perimeter: this.defaultPerimeter,
      id: '',
      userId: this.props.userId
    };
  }

  public sendConfiguration = (conf: INotificationConfiguration) => {
    if (!conf.id) {
      NotificationConfigurationService.postUserNotificationConfiguration(conf)
        .then(() => {
          this.getConfiguration(this.props.userId);
          this.setState({
            ...this.state,
            displaySuccessAlert: true,
            displayErrorAlert: false
          });
        })
        .catch(() => {
          this.setState({
            ...this.state,
            displaySuccessAlert: false,
            displayErrorAlert: true
          });
        });
    } else {
      NotificationConfigurationService.putUserNotificationConfiguration(
        conf,
        this.props.userId
      )
        .then(() => {
          this.getConfiguration(this.props.userId);
          this.setState({
            ...this.state,
            displaySuccessAlert: true,
            displayErrorAlert: false
          });
        })
        .catch(() => {
          this.setState({
            ...this.state,
            displaySuccessAlert: false,
            displayErrorAlert: true
          });
        });
    }
  };

  private onValidate() {
    this.sendConfiguration(this.props.notificationConfiguration);
  }

  public changeHandle(name: string, e: any) {
    this.setState({ [name]: e });
  }

  public render() {
    const { t } = this.props;

    return (
      <>
        <div className="mb-4">
          <div className={'notifications-modal-subtitle w-100'}>
            <SzTypographie variant="h1">
              {t('notification_subtitle')}
            </SzTypographie>
            {this.state.displaySuccessAlert && (
              <SzAlert variant={'success'}>
                {t('notification_success_alert')}
              </SzAlert>
            )}
            {this.state.displayErrorAlert && (
              <SzAlert variant={'danger'}>
                {t('notification_error_alert')}
              </SzAlert>
            )}
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <SzBox className={'box box-border box-table-lookalike h-100'}>
                  <div className={'box-header'}>
                    <SzTypographie variant="text">
                      {t('notification_events')}
                    </SzTypographie>
                  </div>
                  <div className={'box-body'}>
                    <SzCheckbox
                      id="notification_status"
                      className={'big-checkbox mb-3'}
                      name={'notification_status'}
                      type={'checkbox'}
                      label={t('notification_status_changed')}
                      onChange={() => {
                        this.setConfiguration(
                          notificationsEvents.statusChange,
                          !this.state.statusChange
                        );
                      }}
                      checked={this.state.statusChange}
                    />
                    <SzCheckbox
                      id="notification_comment_added"
                      className={'big-checkbox mb-3'}
                      name="notification_comment_added"
                      type={'checkbox'}
                      label={t('notification_comment_added')}
                      onChange={() => {
                        this.setConfiguration(
                          notificationsEvents.newComment,
                          !this.state.newComment
                        );
                      }}
                      checked={this.state.newComment}
                    />
                    <SzCheckbox
                      className={'big-checkbox mb-3'}
                      name={'notification_attachment_added'}
                      id={'notification_attachment_added'}
                      type={'checkbox'}
                      label={t('notification_attachment_added')}
                      onChange={() => {
                        this.setConfiguration(
                          notificationsEvents.newAttachment,
                          !this.state.newAttachment
                        );
                      }}
                      checked={this.state.newAttachment}
                    />
                  </div>
                </SzBox>
              </div>
              <div className="col">
                <SzBox className={'box box-border box-table-lookalike h-100'}>
                  <div className={'box-header'}>
                    <SzTypographie variant="text">
                      {t('notification_perimeter')}
                    </SzTypographie>
                  </div>
                  <div className={'box-body'}>
                    <SzCheckbox
                      className={'big-checkbox mb-3'}
                      name="notification_perimeter_mine"
                      id="notification_perimeter_mine"
                      type={'radio'}
                      label={t('notification_perimeter_mine')}
                      onChange={() => {
                        this.setConfiguration(
                          Constants.NOTIFICATION_PERIMETER_LABEL,
                          notificationPerimeters.mine
                        );
                      }}
                      checked={
                        this.state.perimeter === notificationPerimeters.mine
                      }
                    />
                    <SzCheckbox
                      className={'big-checkbox mb-3'}
                      name="notification_perimeter_all"
                      id="notification_perimeter_all"
                      type={'radio'}
                      label={t('notification_perimeter_all')}
                      onChange={() => {
                        this.setConfiguration(
                          Constants.NOTIFICATION_PERIMETER_LABEL,
                          notificationPerimeters.all
                        );
                      }}
                      checked={
                        this.state.perimeter === notificationPerimeters.all
                      }
                    />
                    <SzCheckbox
                      className={'big-checkbox mb-3'}
                      name="notification_perimeter_assign"
                      id="notification_perimeter_assign"
                      type={'radio'}
                      label={t('notification_perimeter_assign')}
                      onChange={() => {
                        this.setConfiguration(
                          Constants.NOTIFICATION_PERIMETER_LABEL,
                          notificationPerimeters.assign
                        );
                      }}
                      checked={
                        this.state.perimeter === notificationPerimeters.assign
                      }
                    />
                    <SzTypographie variant={'text'}>
                      {this.state.perimeter === notificationPerimeters.all &&
                        t('notification_perimeter_all_info')}
                      {this.state.perimeter === notificationPerimeters.mine &&
                        t('notification_perimeter_owner_info')}
                      {this.state.perimeter === notificationPerimeters.assign &&
                        t('notification_perimeter_assign_info')}
                    </SzTypographie>
                  </div>
                </SzBox>
              </div>
            </div>
          </div>
          <SzBox
            className={
              'notifications-send-container w-100 d-flex justify-content-center'
            }
          >
            <div>
              <SzButton
                onClick={() => {
                  this.onValidate();
                }}
              >
                {t('notification_save')}
              </SzButton>
            </div>
          </SzBox>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  loading: state.loading,
  notificationConfiguration: state.user.notification,
  userId: state.user.info.id
});

const mapDispatchToProps = {
  setNotificationConfiguration
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NotificationSettings));
