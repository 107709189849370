import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Navigation from "../../components/navigation";
import { Redirect, useHistory } from "react-router-dom";
import { HttpCode, ErrorRoutes } from "../../constants";
import UserRequestsService from "../../services/UserRequestsService";

const NavigationManager = (props: any) => {
    const [isErrorPage, setisErrorPage] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('');
    let history = useHistory();

    useEffect(() => {
        setisErrorPage(history.location.pathname.includes('error'));
        if (props.error.status === HttpCode.NOT_FOUND || props.error.status === HttpCode.UNSUPPORTED_MEDIA) {
            setRedirectUrl(ErrorRoutes.NOT_FOUND)
        } else if (props.error.status === HttpCode.ACCESS_DENIED) {
            setRedirectUrl(ErrorRoutes.ACCESS_DENIED)
        } else {
            setRedirectUrl(ErrorRoutes.OUPS)
        }
    }, [history.location.pathname, props.error]);

    useEffect(() => {
        if (props.errorIntervention.isError) {
            UserRequestsService.resetInterventionError()
        }
    }, [history.location.pathname, props.errorIntervention]);

    return (
        !isErrorPage && props.error.isError ?
            <Redirect to={redirectUrl}/>
            :
            <Navigation
                logged={props.user.logged}
                loading={props.loading.loading}
                isError={isErrorPage}
                isErrorIntervention={props.errorIntervention.isError}
                user={props.user}>
                {props.children}
            </Navigation>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.loading,
    user: state.user,
    error: state.error,
    errorIntervention: state.errorIntervention
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationManager);
