import React, { createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { LayersControl, Marker, TileLayer } from 'react-leaflet';
import { MAP_AZURE_KEY, MAP_AZURE_URI, MAP_URI } from "../../constants";
import MapComponent from '../../components/elements/mapComponent'
import { MapGeoCodeService } from '../../services/MapGeoCodeService';
import userRequestsService from '../../services/UserRequestsService';

class MapManager extends React.Component<any> {
    refMarker = createRef<Marker>();
    marker = { lat: 43.600000, lng: 1.433333 };
    zooms = { zoom: 12, minZoom: 6, maxZoom: 19 };
    state = { showMarker: false, marker: this.marker, shapes: null };

    constructor(props: any) {
        super(props);
        const { latitude, longitude } = props.userRequest.address;
        this.state = {
            showMarker: (latitude !== ''),
            marker: { lat: latitude ? latitude : this.marker.lat, lng: longitude ? longitude : this.marker.lng },
            shapes: null
        };
        if (!this.props.geoShapes) {
            userRequestsService.getGeoShapes();
        }
    }

    showMarkerHandle(event: any) {
        if ((event !== null) && !this.state.showMarker) {
            this.setState({ marker: event.latlng });
            MapGeoCodeService.cordinatesToAddress(event.latlng.lat, event.latlng.lng);
        }
        this.setState({ showMarker: true });
    }

    onDragendHandle(event: any) {
        const currentMarker = this.refMarker.current;
        if (currentMarker != null) {
            this.setState({ marker: currentMarker.leafletElement.getLatLng() });
            const { lat, lng } = currentMarker.leafletElement.getLatLng();
            MapGeoCodeService.cordinatesToAddress(lat, lng);
        }
    }

    componentDidUpdate(prevProps: any) {
        const { latitude, longitude } = this.props.userRequest.address;
        if (prevProps !== this.props) {
            this.setState({
                showMarker: (latitude !== ''),
                marker: { lat: latitude ? latitude : this.marker.lat, lng: longitude ? longitude : this.marker.lng }
            });
        }
        if (prevProps.geoShapes !== this.props.geoShapes) {
            this.setState({ shapes: this.props.geoShapes })
        }
    }

    componentDidMount() {
        const { geoShapes } = this.props;
        this.setState({ shapes: null });
        this.setState({ shapes: geoShapes })
    }

    render() {
        const { t } = this.props;
        const { showMarker } = this.state;
        const { lat, lng } = this.state.marker;
        const { BaseLayer } = LayersControl;
        const zooms = this.zooms;

        return (
            <MapComponent
                geoShapes={this.state.shapes}
                onClick={this.showMarkerHandle.bind(this)}
                zooms={zooms}
                center={[lat, lng]}
            >
                <LayersControl position='topright'>
                    <BaseLayer checked name={t('map_plan_view')}>
                        <TileLayer url={MAP_URI}
                                   attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'/>
                    </BaseLayer>
                    <BaseLayer name={t('map_satellite_view')}>
                        <TileLayer url={MAP_AZURE_URI}
                                   attribution='© TomTom, Microsoft'
                                   subscriptionKey={MAP_AZURE_KEY}
                                   tilesetId='microsoft.imagery'
                                   language='fr-FR'
                                   view='Auto'
                        />
                    </BaseLayer>
                </LayersControl>

                {showMarker &&
                <Marker
                    position={[lat, lng]}
                    ref={this.refMarker}
                    draggable={true}
                    ondragend={this.onDragendHandle.bind(this)}
                >
                </Marker>
                }

            </MapComponent>
        );
    }
}

const mapStateToProps = (state: any) => ({
    userRequest: state.userRequests.currentRequest,
    geoShapes: state.userRequests.shapes
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(MapManager));
