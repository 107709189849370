import { config } from '../../config';
import { createInstance } from '@datapunt/matomo-tracker-react';

export const matmotInstance = createInstance(
	{
		urlBase: config.matomoUrlBase,
		siteId: config.matomoSiteId,
		// userId: 'UID76903202', // optional, default value: `undefined`.
		// trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
		// srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
		//disabled: config.matomoDisabled, // optional, false by default. Makes all tracking calls
	}
);