import Axios from "axios";
import { cordinatesToAddressAction, autoCompleteAddressAction } from "../actions/geocode";
import { store } from '../store';
import { loadingSpinner } from "../actions";

const MAP_REVERSE_API_URL = "https://gtm.toulouse-metropole.fr/reverse/?limit=1";
const MAP_GEO_CODE_API_URL = "https://gtm.toulouse-metropole.fr/search/?q=";
const NBR_CHAR_START_AUTO_COMPLETE = 3;
const AUTO_COMPLETE_COUNTRY = 'France';
const CancelToken = Axios.CancelToken;

let second = new Date().getSeconds();
let cancel: any

export class MapGeoCodeService {

    public static cordinatesToAddress(lat: any, lng: any) {
        store.dispatch(loadingSpinner(true));
        let payload = {
            address: {
                address: '',
                city: '',
                street: '',
                number: '',
                postalCode: '',
                latitude: lat,
                longitude: lng,
                country: '',
                isGeocodeOk: false
            }
        };
        Axios.get(`${MAP_REVERSE_API_URL}&lat=${lat}&lon=${lng}`)
            .then(function (response) {
                if (0 !== response.data.features.length) {
                    const { properties } = response.data.features[0];
                    const { city, housenumber, label, postcode, street } = properties;
                    payload = {
                        address: {
                            address: label,
                            city: city,
                            street: street,
                            number: housenumber,
                            postalCode: postcode,
                            latitude: lat,
                            longitude: lng,
                            country: AUTO_COMPLETE_COUNTRY,
                            isGeocodeOk: true
                        }
                    };
                    store.dispatch(cordinatesToAddressAction(payload));
                    store.dispatch(loadingSpinner(false));
                    return;
                }
            }).catch(() => {
            // In the catch we do nothing but send the default payload
        }).finally(() => {
            store.dispatch(cordinatesToAddressAction(payload));
            store.dispatch(loadingSpinner(false));
        })
    }

    public static autoCompleteItemToAddress(lat: any, lng: any, street: any, houseNumber: any, postcode: any, city: any, address: any) {
        const payload = {
            address: {
                address: address,
                city: city,
                street: street,
                number: houseNumber,
                postalCode: postcode,
                latitude: lat,
                longitude: lng,
                country: AUTO_COMPLETE_COUNTRY
            }
        };
        store.dispatch(cordinatesToAddressAction(payload));
    }

    public static autoCompleteAddress(address: String) {
        let items: any = []
        let currentSecond = new Date().getSeconds()
        if (currentSecond !== second) {
            if (address.length >= NBR_CHAR_START_AUTO_COMPLETE) {
                second = currentSecond;
                if (cancel !== undefined) {
                    cancel();
                }
                return Axios.get(`${MAP_GEO_CODE_API_URL}${address}`, {
                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                    })
                })
                    .then(function (response) {
                        response.data.features.map((value: any): any => {
                            const item = {
                                lat: value.geometry.coordinates[1],
                                lng: value.geometry.coordinates[0],
                                address: value.properties.label,
                                houseNumber: value.properties.housenumber,
                                street: value.properties.street ? value.properties.street : value.properties.name,
                                postcode: value.properties.postcode,
                                city: value.properties.city,
                            }
                            items.push(item);

                            return items;
                        });

                        store.dispatch(autoCompleteAddressAction(items));

                    });
            } else {
                store.dispatch(autoCompleteAddressAction(items));
            }
        }

        return new Promise<void>(() => {
        });
    }
}
