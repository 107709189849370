import React from "react";
import { SzTypographie, SzIcon } from "@suezenv/react-theme-components";
import UserRequestsService from "../../services/UserRequestsService";

export abstract class AbstractDetailManager extends React.Component<any> {
    getTreatments() {
        const { categories, userRequest } = this.props
        const { data } = userRequest;

        let categoriesLoaded = null !== categories
        let categoryChosen = (
            userRequest.data.requestCategory !== undefined
            && userRequest.data.requestCategory !== null
        )
        let mandatoryTreatments: any = []
        let additionalTreatments: any = []

        if (categoriesLoaded && categoryChosen) {
            const selectedTreatments = data.requestExpectedTreatment ? data.requestExpectedTreatment : {};
            const categoryId = (data.requestCategory.id !== undefined) ? data.requestCategory.id : data.requestCategory;
            if (categoryId && categories[categoryId]) {
                const hasSubCategory = categories[categoryId].subcategories.length !== 0;
                const subCategoryId = (data.requestSubCategory && data.requestSubCategory.id !== undefined) ? data.requestSubCategory.id : data.requestSubCategory;
                const selectSubCategory = hasSubCategory ? categories[categoryId].subcategories[subCategoryId] : null;
                const categoryTreaments = hasSubCategory ? selectSubCategory.treatments : categories[categoryId].treatments;
                Object.entries(selectedTreatments).map(function (value: any) {
                    const treatmentId: any = (value[1].id !== undefined) ? value[1].id : value[1];
                    const treatmentDefinition = categoryTreaments[treatmentId]
                    if (treatmentDefinition && true === treatmentDefinition.metadata.mandatory) {
                        mandatoryTreatments.push(treatmentDefinition.label)
                    } else if (treatmentDefinition) {
                        additionalTreatments.push(treatmentDefinition.label)
                    }
                    return value;
                });
            }
        }
        return { mandatoryTreatments, additionalTreatments }
    }

    getRisks() {
        const { t, userRequest } = this.props

        let selectedRisks: any = []
        let risksList = {
            'human': userRequest.data.requestHumanRisk,
            'property': userRequest.data.requestPropertyRisk,
            'service': userRequest.data.requestServiceRisk
        }
        Object.entries(risksList).map(function (value) {
            if (1 === parseInt(value[1]) || true === value[1]) {
                selectedRisks.push(value[0]);
            }
            return value;
        });

        let risks = ''

        // handle 0 risk
        if (0 === selectedRisks.length) {
            return '-'
        }

        // handle 1 risk
        if (1 === selectedRisks.length) {
            risks = t(`create_summary_bloc1_${selectedRisks[0]}`)
        }

        // handle two risks and more
        if (2 <= selectedRisks.length) {
            const lastRisk = selectedRisks.pop()
            const firstRisks = selectedRisks.map((risk: any) => {
                return t(`create_summary_bloc1_${risk}`)
            }).join(', ')

            const andKeyword = t("create_summary_bloc1_risks_and")
            const lastRiskText = t(`create_summary_bloc1_${lastRisk}`)
            risks = [firstRisks, andKeyword, lastRiskText].join(' ')
        }

        const fullRisks = t("create_summary_bloc1_risks_for", { risks: risks })

        return fullRisks
    }
    renderAttachment(iconClassName: string) {
        const { requestFiles, userRequest } = this.props;
        const { locationPlan, other } = requestFiles;
        if (!locationPlan && other.length === 0) {
            return '-';
        }
        return (<>
            {locationPlan && <>
                <div onClick={() => this.downloadAttachmentHandle(locationPlan.file, userRequest)} className={iconClassName}>
                    <SzIcon variant="bold" icon="view-1" />
                    <SzTypographie variant="text">{locationPlan.file.name}</SzTypographie>
                </div>
            </>}
            {other.map((attachment: any, index: number) => (
                <div key={index} onClick={() => this.downloadAttachmentHandle(attachment.file, userRequest)} className={iconClassName} >
                    <SzIcon variant="bold" icon="view-1" />
                    <SzTypographie variant="text">{attachment.file.name}</SzTypographie>
                </div>
            ))
            }
        </>);
    }
    formatDate(stringDate: string) {
        const date = new Date(stringDate);
        const toTwoDigit = (value: number) => (`0${value}`).slice(-2);
        return `${toTwoDigit(date.getDate())}/${toTwoDigit(date.getMonth() + 1)}/${date.getFullYear()}`;
    }

    downloadAttachmentHandle(file: any, userRequest: any) {
        const windowUrl = window.URL;
        const link = document.createElement('a');
        link.setAttribute('download', file.name);
        if (file.id !== undefined) {
            UserRequestsService.downloadAttachment(userRequest.id, file.id).then((response: any) => {

                const url = windowUrl.createObjectURL(new Blob([response.data]));
                link.href = url;
                document.body.appendChild(link);
                link.click();

            })
        } else {
            const url = windowUrl.createObjectURL(new Blob([file]));
            link.href = url;
            document.body.appendChild(link);
            link.click();
        }
    }
}
