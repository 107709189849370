import React from "react";
import { connect } from "react-redux";
import Step1Form from "../../components/forms/createUserRequest/Step1Form";
import './createUserRequest.scss';
import { updateCurrentUserRequest, addAttachmentRequest } from "../../actions";
import { withRouter } from "react-router";
import { StepManager } from "./StepManager";


class Step1Manager extends StepManager {
    state = { isDisabledBtn: true };

    formValuesToUserRequest(formValues: any) {
        const { address, city, country, latitude, longitude, number, postalCode, street, addressAdditionalInformation } = formValues;
        let numberAdresse = number ? number : '';
        const addressObj = {
          address,
          city,
          country,
          latitude,
          longitude,
          number: numberAdresse,
          postalCode,
          street,
        };
        let { userRequest } = this.props;
        return {
            ...userRequest, address: addressObj, data: { ...userRequest.data, addressAdditionalInformation }
        }
    }

    public render() {
        const { userRequest, addressAutoComplete, requestFiles, currentStep } = this.props;
        return (
            <Step1Form
                values={userRequest}
                currentStep={currentStep}
                locationPlan={requestFiles.locationPlan}
                saveDraft={this.saveDraft.bind(this)}
                addressAutoComplete={addressAutoComplete}
                nextStepHandle={this.nextStepHandle.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state: any) => ({
    userRequest: state.userRequests.currentRequest,
    requestFiles: state.userRequests.currentRequestFiles,
    addressAutoComplete: state.addressAutoComplete
});

const mapDispatchToProps = {
    updateCurrentUserRequest: updateCurrentUserRequest,
    addAttachmentRequest: addAttachmentRequest
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Step1Manager));
