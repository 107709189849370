import * as Yup from 'yup';

export default {
    requestExpectedTreatment: {
        schema: Yup.array(),
        label: 'request_expected_treatment_form_label',
        icon: '',
        name: 'requestExpectedTreatment',
        required: false,
    },
    desiredDate: {
        schema: Yup.string().nullable(true),
        label: 'desired_date_form_label',
        icon: '',
        name: 'desiredDate',
        required: false,
    },
    desiredDateProof: {
        schema: Yup.string(),
        label: 'desired_date_proof_form_label',
        icon: '',
        name: 'desiredDateProof',
        placeholder: '',
        required: false,
    },
    requestCategory: {
        schema: Yup.string().required('required_validation'),
        label: 'category_form_label',
        icon: '',
        name: 'requestCategory',
        type: 'select',
        placeholder: '',
        required: true
    },
    requestSubCategory: {
        schema: Yup.string(),
        label: 'sub_category_form_label',
        icon: '',
        name: 'requestSubCategory',
        type: 'select',
        placeholder: '',
        required: false
    },
    requestOperationType: {
        schema: Yup.string(),
        label: '',
        icon: '',
        name: 'requestOperationType',
        type: 'select',
        placeholder: 'description_operation_type_form_label',
        required: false
    },
    requestCleansingType: {
        schema: Yup.string(),
        label: '',
        icon: '',
        name: 'requestCleansingType',
        type: 'select',
        placeholder: 'description_cleansing_type_form_label',
        required: false
    }
};
