import React from 'react';
import Form from 'react-bootstrap/Form';
import classnames from 'classnames';

export const Select = (props: any) => {
    const { value, options, required, onChange, onBlur, label, name } = props;
    const labelClasses = classnames('sz-label-input position-relative d-block', {
        required
    });
    const classesControl = classnames(value.length > 0 ? 'sz-select_placeholder' : 'bg-white');
    return (
        <div className='mb-3'>
            < Form.Label className={labelClasses}  > {label}</Form.Label>
            <Form.Control required={true}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                className={classesControl}
                as="select"
            >

                {options.map((option: any, index: any) => {
                    return (<option key={index} value={option.value} >{option.text}</option>)
                })}
            </Form.Control >
        </div>
    );
}
