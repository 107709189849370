import React from 'react';
import Form from 'react-bootstrap/Form';
import classnames from 'classnames';

export const TextArea = (props: any) => {
    const { value, required, onChange, onBlur, label, name, rows, className, placeholder, bgLight = true } = props;
    const labelClasses = classnames('sz-label-input position-relative d-block font-family-bold text-inactive mt-2', {
        required
    });
    const classesControl = classnames(value.length > 0 ? 'bg-white' : (bgLight) ? 'bg-light' : '', className);
    return (
        <div className='mb-1'>
            < Form.Label className={labelClasses}  > {label}</Form.Label>
            <Form.Control required={required}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                as="textarea"
                className={classesControl}
                rows={rows}
            >
            </Form.Control >
        </div>
    );
}
