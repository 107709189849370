import L from 'leaflet';

const svgRequestCanceled = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 32 32"><title></title><path stroke="red" stroke-width="4" stroke-linejoin="round" fill="white" d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.747,7.3,12.285,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0Z"/><path fill="red" d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.749,7.3,12.286,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0Zm4.207,13.293a1,1,0,1,1-1.414,1.414l-2.439-2.439a.5.5,0,0,0-.708,0L9.207,14.707a1,1,0,1,1-1.414-1.414l2.439-2.439a.5.5,0,0,0,0-.708L7.793,7.707A1,1,0,1,1,9.207,6.293l2.439,2.439a.5.5,0,0,0,.708,0l2.439-2.439a1,1,0,1,1,1.414,1.414l-2.439,2.439a.5.5,0,0,0,0,.708Z"/></svg>';
const svgRequestClosed = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 32 32"><title></title><path stroke="white" stroke-width="4" stroke-linejoin="round" fill="white" d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.747,7.3,12.285,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0Z"/><path d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.749,7.3,12.286,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0Zm4.207,13.293a1,1,0,1,1-1.414,1.414l-2.439-2.439a.5.5,0,0,0-.708,0L9.207,14.707a1,1,0,1,1-1.414-1.414l2.439-2.439a.5.5,0,0,0,0-.708L7.793,7.707A1,1,0,1,1,9.207,6.293l2.439,2.439a.5.5,0,0,0,.708,0l2.439-2.439a1,1,0,1,1,1.414,1.414l-2.439,2.439a.5.5,0,0,0,0,.708Z"/></svg>';
const svgRequestClosedRejected = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 32 32"><title></title><path stroke="white" stroke-width="4" stroke-linejoin="round" fill="white" d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.747,7.3,12.285,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0Z"/><path d="M14.92,6.52a.25.25,0,0,0-.038-.385A5.168,5.168,0,0,0,12,5.25,5.256,5.256,0,0,0,6.75,10.5a5.168,5.168,0,0,0,.885,2.882.249.249,0,0,0,.183.11.252.252,0,0,0,.2-.072Z"/><path d="M9.08,14.48a.252.252,0,0,0-.072.2.249.249,0,0,0,.11.183A5.168,5.168,0,0,0,12,15.75a5.256,5.256,0,0,0,5.25-5.25,5.168,5.168,0,0,0-.885-2.882.249.249,0,0,0-.183-.11.252.252,0,0,0-.2.072Z"/><path d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.747,7.3,12.285,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0Zm0,17.25a6.75,6.75,0,1,1,6.75-6.75A6.758,6.758,0,0,1,12,17.25Z"/></svg>';
const svgRequestDone = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 32 32"><title></title><path stroke="white" stroke-width="4" stroke-linejoin="round" fill="white" d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.747,7.3,12.285,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0Z"/><path d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.747,7.3,12.285,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0Zm6.707,7.207-8,8a1,1,0,0,1-1.414,0l-3-3a1,1,0,0,1,1.414-1.414l1.939,1.939a.5.5,0,0,0,.708,0l6.939-6.939a1,1,0,1,1,1.414,1.414Z"/></svg>';
const svgRequestIntervention = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 32 32"><title></title><circle cx="12" cy="5" r="5" stroke="white" stroke-width="4" stroke-linejoin="round" fill="white"/><path stroke="white" stroke-width="4" stroke-linejoin="round" fill="white" d="M12,11.5a1.25,1.25,0,0,0-1.25,1.25v10a1.25,1.25,0,0,0,2.5,0v-10A1.25,1.25,0,0,0,12,11.5Z"/><circle cx="12" cy="5" r="5"/><path d="M12,11.5a1.25,1.25,0,0,0-1.25,1.25v10a1.25,1.25,0,0,0,2.5,0v-10A1.25,1.25,0,0,0,12,11.5Z"/></svg>';
const svgRequestOngoing = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 32 32"><title></title><path stroke="white" stroke-width="4" stroke-linejoin="round" fill="white" d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.747,7.3,12.285,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0Z"/><path d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.748,7.3,12.285,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0Zm1.811,7.982L15.189,6.6a.5.5,0,0,0,0-.708l-.043-.042a.506.506,0,0,1-.144-.4.5.5,0,0,1,.221-.367l1.5-1a.5.5,0,0,1,.631.062l1,1a.5.5,0,0,1,.062.631l-1,1.5a.5.5,0,0,1-.367.221L17,7.5a.5.5,0,0,1-.354-.146L16.6,7.311a.5.5,0,0,0-.708,0L14.518,8.689a.5.5,0,0,1-.707-.707Zm-2.872,5.786L9.164,15.543a1.561,1.561,0,0,1-2.207-2.207l1.775-1.775a.5.5,0,0,1,.707,0l1.5,1.5a.5.5,0,0,1,0,.707ZM18.4,14.776a.5.5,0,0,1-.836.225L16.28,13.72a.768.768,0,0,0-1.06,0,.748.748,0,0,0,0,1.06l1.28,1.282a.5.5,0,0,1-.225.836A3,3,0,0,1,12.5,14a2.83,2.83,0,0,1,.038-.458.5.5,0,0,0-.139-.436l-3-3a.5.5,0,0,0-.437-.14A3,3,0,0,1,5.6,6.224.5.5,0,0,1,6.438,6L7.72,7.28a.768.768,0,0,0,1.06,0,.748.748,0,0,0,0-1.06L7.5,4.938A.5.5,0,0,1,7.725,4.1,3,3,0,0,1,11.5,7a2.83,2.83,0,0,1-.038.458.5.5,0,0,0,.139.436l3,3a.5.5,0,0,0,.437.139A3,3,0,0,1,18.4,14.776Z"/></svg>';
const svgRequestPlanned = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 32 32"><title></title><circle cx="12" cy="10" r="9" fill="white" /><path stroke="white" stroke-width="4" stroke-linejoin="round" d="M12,0A9.511,9.511,0,0,0,2.5,9.5c0,6.547,6.538,12.488,8.541,14.153a1.5,1.5,0,0,0,1.917,0c2-1.665,8.542-7.608,8.542-14.154A9.511,9.511,0,0,0,12,0Zm4.366,7.627A.613.613,0,0,1,16.8,8.7l-1.921,1.894a.5.5,0,0,0-.108.556l1.064,2.446a.654.654,0,0,1-.931.819l-2.655-1.5a.5.5,0,0,0-.491,0L9.1,14.412a.654.654,0,0,1-.931-.819l1.063-2.446a.5.5,0,0,0-.107-.556L7.2,8.7a.613.613,0,0,1,.429-1.07H9.808a.5.5,0,0,0,.454-.289l1.15-2.48a.662.662,0,0,1,1.177,0l1.147,2.48a.5.5,0,0,0,.454.289Z"/><path d="M12,0A9.511,9.511,0,0,0,2.5,9.5c0,6.547,6.538,12.488,8.541,14.153a1.5,1.5,0,0,0,1.917,0c2-1.665,8.542-7.608,8.542-14.154A9.511,9.511,0,0,0,12,0Zm4.366,7.627A.613.613,0,0,1,16.8,8.7l-1.921,1.894a.5.5,0,0,0-.108.556l1.064,2.446a.654.654,0,0,1-.931.819l-2.655-1.5a.5.5,0,0,0-.491,0L9.1,14.412a.654.654,0,0,1-.931-.819l1.063-2.446a.5.5,0,0,0-.107-.556L7.2,8.7a.613.613,0,0,1,.429-1.07H9.808a.5.5,0,0,0,.454-.289l1.15-2.48a.662.662,0,0,1,1.177,0l1.147,2.48a.5.5,0,0,0,.454.289Z"/></svg>\n';
const svgRequestToPlan = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 32 32"><title></title><path stroke="white" stroke-width="4" stroke-linejoin="round" fill="white" d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.747,7.3,12.285,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0Z"/><path d="M16.5,9h-4a.75.75,0,0,0,0,1.5h4a.75.75,0,0,0,0-1.5Z"/><path d="M16.5,12h-4a.75.75,0,0,0,0,1.5h4a.75.75,0,0,0,0-1.5Z"/><path d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.748,7.3,12.285,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0ZM8.5,13.5a.5.5,0,0,1-.053.224l-1,2a.5.5,0,0,1-.894,0l-1-2A.5.5,0,0,1,5.5,13.5v-7a1.5,1.5,0,0,1,3,0Zm10,1A1.5,1.5,0,0,1,17,16H12a1.5,1.5,0,0,1-1.5-1.5v-8A1.5,1.5,0,0,1,12,5h5a1.5,1.5,0,0,1,1.5,1.5Z"/></svg>';
const svgRequestToQualifySpecify = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 32 32"><title></title><path stroke="white" stroke-width="4" stroke-linejoin="round" fill="white" d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.747,7.3,12.285,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0Z"/><path d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.749,7.3,12.286,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0ZM11,5.25a1,1,0,0,1,2,0v7a1,1,0,0,1-2,0ZM12,17.5A1.5,1.5,0,1,1,13.5,16,1.5,1.5,0,0,1,12,17.5Z"/></svg>';
export const svgZoomIn = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>zoom-in-1</title><path d="M23.414,20.591,19.2,16.377A10.51,10.51,0,1,0,16.372,19.2l4.214,4.214a2.027,2.027,0,0,0,2.828,0A2,2,0,0,0,23.414,20.591ZM10.5,2.005a8.5,8.5,0,1,1-8.5,8.5A8.51,8.51,0,0,1,10.5,2.005Z"/><path d="M7,11.505H9.5v2.5a1,1,0,1,0,2,0v-2.5H14a1,1,0,0,0,0-2H11.5v-2.5a1,1,0,1,0-2,0v2.5H7a1,1,0,0,0,0,2Z"/></svg>';
export const svgZoomOut = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>zoom-out-1</title><path d="M23.414,20.59,19.2,16.376A10.5,10.5,0,1,0,16.372,19.2l4.214,4.213a2.023,2.023,0,0,0,2.828,0A2,2,0,0,0,23.414,20.59ZM10.5,2A8.5,8.5,0,1,1,2,10.5,8.51,8.51,0,0,1,10.5,2Z"/><path d="M7,11.5h7a1,1,0,0,0,0-2H7a1,1,0,1,0,0,2Z"/></svg>';


const iconRequestCanceled = new L.DivIcon({
    html: svgRequestCanceled,
    iconSize: new L.Point(30, 33),
    className: 'leaflet-marker-icon request-icon__canceled'
});

const iconRequestClosed = new L.DivIcon({
    html: svgRequestClosed,
    iconSize: new L.Point(30, 33),
    className: 'leaflet-marker-icon request-icon__closed'
});

const iconRequestClosedRejected = new L.DivIcon({
    html: svgRequestClosedRejected,
    iconSize: new L.Point(30, 33),
    className: 'leaflet-marker-icon request-icon__closed-rejected'
});

const iconRequestDone = new L.DivIcon({
    html: svgRequestDone,
    iconSize: new L.Point(30, 33),
    className: 'leaflet-marker-icon request-icon__done'
});

const iconRequestIntervention = new L.DivIcon({
    html: svgRequestIntervention,
    iconSize: new L.Point(14, 33),
    className: 'leaflet-marker-icon request-icon__intervention'
});

const iconRequestOngoing = new L.DivIcon({
    html: svgRequestOngoing,
    iconSize: new L.Point(30, 33),
    className: 'leaflet-marker-icon request-icon__ongoing'
});

const iconRequestPlanned = new L.DivIcon({
    html: svgRequestPlanned,
    iconSize: new L.Point(30, 33),
    className: 'leaflet-marker-icon request-icon__planned'
});

const iconRequestToPlan = new L.DivIcon({
    html: svgRequestToPlan,
    iconSize: new L.Point(30, 33),
    className: 'leaflet-marker-icon request-icon__to-plan'
});

const iconRequestToQualify = new L.DivIcon({
    html: svgRequestToQualifySpecify,
    iconSize: new L.Point(30, 33),
    className: 'leaflet-marker-icon request-icon__to-qualify'
});

const iconRequestToSpecify = new L.DivIcon({
    html: svgRequestToQualifySpecify,
    iconSize: new L.Point(30, 33),
    className: 'leaflet-marker-icon request-icon__to-specify'
});

type tIcons = {
    [key: string]: L.DivIcon
}
const icons: tIcons = {
    'canceled': iconRequestCanceled,
    'closed': iconRequestClosed,
    'closed_rejected': iconRequestClosedRejected,
    'done': iconRequestDone,
    'intervention': iconRequestIntervention,
    'ongoing': iconRequestOngoing,
    'planned': iconRequestPlanned,
    'to_plan': iconRequestToPlan,
    'to_qualify': iconRequestToQualify,
    'to_specify': iconRequestToSpecify
};

export default icons;
