import React from "react";
import { connect } from "react-redux";
import './createUserRequest.scss';
import { updateCurrentUserRequest } from "../../actions";
import { withRouter } from "react-router";
import Step4Form from "../../components/forms/createUserRequest/Step4Form";
import { StepManager } from "./StepManager";
import UserRequestsService from "../../services/UserRequestsService";

class Step4Manager extends StepManager {
    state = { isDisabledBtn: true };

    formValuesToUserRequest(formValues: any) {
        const { comment, additionalReference } = formValues;
        let { userRequest } = this.props;
        return {
            ...userRequest, comment, data: { ...userRequest.data, additionalReference }
        }
    }

    public render() {
        const { userRequest, previousStepHandle } = this.props;
        return (
            <Step4Form
                values={userRequest}
                saveDraft={this.saveDraft.bind(this)}
                nextStepHandle={this.nextStepHandle.bind(this)}
                previousStepHandle={previousStepHandle.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state: any) => ({
    userRequest: state.userRequests.currentRequest,
    requestFiles: state.userRequests.currentRequestFiles
});

const mapDispatchToProps = {
    updateCurrentUserRequest: updateCurrentUserRequest
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Step4Manager));
