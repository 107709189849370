
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import userReducer from "./user_reducer";
import loadingReducers from "./loading_reducer";
import stepReducers from "./steps_reducer";
import addressAutoCompleteReducers from "./address_auto_complete";
import userRequestsReducer from "./user_requests_reducer";
import requesterReducer from "./requester_reducer";
import errorReducers from "./error_reducer";
import { errorInterventionReducers } from "./error_reducer";

const rootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    user: userReducer,
    loading: loadingReducers,
    steps: stepReducers,
    addressAutoComplete: addressAutoCompleteReducers,
    userRequests: userRequestsReducer,
    requester: requesterReducer,
    error: errorReducers,
    errorIntervention: errorInterventionReducers
});

export default rootReducer;