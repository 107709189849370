import React from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { SzButton } from '@suezenv/react-theme-components';
import "./index.scss";



const SzPopover = (props: any) => {
    const { buttonLabel,
        placement,
        rootClose = true,
        className,
        id,
        children,
        icon,
        trigger = 'click',
        title = '',
        btnClassName = '',
        btnVariant = 'secondary',
        onEnterHandle = null } = props;
    return (
        <OverlayTrigger trigger={trigger} onEnter={onEnterHandle} rootClose={rootClose} placement={placement}
            overlay={
                <Popover className={className} id={id}>
                    <Popover.Title as="h3">{title}</Popover.Title>
                    <Popover.Content>
                        {children}
                    </Popover.Content>
                </Popover>
            }
        >
            <SzButton icon={icon} className={btnClassName} variant={btnVariant}>{buttonLabel}</SzButton>
        </OverlayTrigger >
    );
}

export default SzPopover;
