import React from 'react';
import {
  Status,
  Risks,
  RequestListPages,
  MdmOptions,
} from '../../../constants';
import { getFormatedForm } from '../../../Models';
import filterListModel from '../../../Models/filterListModels/filterListModel';
import {
  SzBox,
  SzButton,
  SzSelect,
  SzDateRangePicker,
  SzInput,
} from '@suezenv/react-theme-components';
import { Formik, Form } from 'formik';
import { withTranslation } from 'react-i18next';
import SelectedFilter from './selectedFilter';
import classnames from 'classnames';
import Indicator from '../../elements/indicators';
import moment from 'moment';

class FilterList extends React.Component<any> {
  state: {
    status: any;
    risk: any;
    category: any;
    subCategory: any;
    organisation: any;
    town: any;
    operation: any;
    cleansing: any;
    isValid: boolean;
    dateRange: any[];
    selectedFilter: any;
    focusedInput: any;
    additionnalTreatment: any;
    supervisorUserId: any;
    incrementalId: any;
  } = {
    status: null,
    risk: null,
    category: null,
    subCategory: null,
    organisation: null,
    town: null,
    operation: null,
    cleansing: null,
    isValid: false,
    dateRange: [null, null],
    selectedFilter: [],
    focusedInput: null,
    additionnalTreatment: null,
    supervisorUserId: null,
    incrementalId: '',
  };

  selectedFiltersConf = [
    'status',
    'humanRisk',
    'serviceRisk',
    'propertyRisk',
    'category',
    'subCategory',
    'organisation',
    'town',
    'operation',
    'cleansing',
    'additionnalTreatment',
    'supervisorUserId',
    'incrementalId',
  ];

  riskFilter = [
    'humanRisk',
    'serviceRisk',
    'propertyRisk',
  ];

  public componentDidMount() {
    const { type, allFilters, myFilters, pendingFilters, assignedFilters } = this.props;
    let filter: any;
    switch (type) {
      case RequestListPages.ALL:
        filter = allFilters;
        break;
      case RequestListPages.MY:
        filter = myFilters;
        break;
      case RequestListPages.PENDING:
        filter = pendingFilters;
        break;
      case RequestListPages.ASSIGNED:
        filter = assignedFilters;
        break;
    }

    this.refreshSelectedFilters(filter);
    const startDate = filter.dateCreationMin && filter.dateCreationMin.length !== 0 ? new Date(filter.dateCreationMin) : null;
    const endDate = filter.dateCreationMin && filter.dateCreationMax.length !== 0 ? new Date(filter.dateCreationMax) : null;

    this.setState({
      dateRange: [startDate, endDate],
      ...filter
    })
  }

  public formatDate(date: any, functional = false) {
    if (date === null) {
      return []
    } else {
      const toTwoDigit = (value: number) => `0${value}`.slice(-2);
      if (functional) {
        return `${toTwoDigit(date.getDate())}/${toTwoDigit(
          date.getMonth() + 1
        )}/${date.getFullYear()}`;
      }
      return `${date.getFullYear()}-${toTwoDigit(
        date.getMonth() + 1
      )}-${toTwoDigit(date.getDate())}`;
    }
  }

  public triggerFilters = (indicatorFilter = null, indicatorType:string = '' ) => {
    let filter: any = {};
    const {
      refreshIndicatorsHandle,
      data,
      type,
      getFilteredRequests,
      triggerAllFilters,
      triggerMyFilters,
      triggerPendingFilters,
      triggerAssignedFilters,
      setCurrentPage,
    } = this.props;
    const { organisations, categories } = data;
    const {
      status,
      risk,
      category,
      subCategory,
      organisation,
      town,
      operation,
      cleansing,
      dateRange,
      incrementalId
    } = this.state;

    if ('status' === indicatorType) {
      if (null !== indicatorFilter) {
        filter.status = indicatorFilter;
      } else if (null !== status) {
        filter.status = status;
      }
    } else {
      filter.status = status;
    }
    if ('additionalTreatment' === indicatorType && null !== indicatorFilter) {
      filter.additionnalTreatment = indicatorFilter;
    }

    /* Just add the fields we actually want to filter on */
    if (null !== risk) {
      if (risk['value'] === Risks.RISK_HUMAN) {
        filter.humanRisk = true;
      } else if (risk['value'] === Risks.RISK_SERVICE) {
        filter.serviceRisk = true;
      } else if (risk['value'] === Risks.RISK_PROPERTY) {
        filter.propertyRisk = true;
      }
    }

    if (dateRange) {
      filter.dateCreationMin = this.formatDate(dateRange[0]);
      filter.dateCreationMax = this.formatDate(dateRange[1]);
    }

    let categorySubs: any = [];

    if (null !== category && null !== category.value) {
      categorySubs = categories[category.value].subcategories;
      filter.category = category;
    }

    if (null !== subCategory && null !== subCategory.value) {
      filter.subCategory = subCategory;

      if (
        undefined === categorySubs[subCategory.value] &&
        null !== category
      ) {
        delete filter.subCategory;
      }
    }

    let organisationTowns: any = [];

    if (null !== organisation && null !== organisation.value) {
      organisationTowns = organisations[organisation.value].towns;
      filter.organisation = organisation;
    }

    if (null !== town && null !== town.value) {
      filter.town = town;

      // In case town previously selected is unavailable in current organisation
      // But if no organisation was selected, any town can be used in the filter
      if (
        undefined === organisationTowns[town.value] &&
        null !== organisation
      ) {
        delete filter.town;
      }
    }

    if (null !== operation) {
      filter.operation = operation;
    }

    if (null !== cleansing) {
      filter.cleansing = cleansing;
    }

    if (null !== incrementalId) {
      filter.incrementalId = incrementalId;
    }

    refreshIndicatorsHandle(type, filter);
    switch (type) {
      case RequestListPages.ALL:
        triggerAllFilters(filter)
        setCurrentPage({all: 1})
        break;
      case RequestListPages.MY:
        triggerMyFilters(filter)
        setCurrentPage({my: 1})
        break;
      case RequestListPages.PENDING:
        triggerPendingFilters(filter)
        setCurrentPage({pending: 1})
        break;
      case RequestListPages.ASSIGNED:
        triggerAssignedFilters(filter)
        setCurrentPage({assigned: 1})
        break;
    }

    this.refreshSelectedFilters(filter);

    getFilteredRequests(filter);
  };

  public changeHandle(name: string, e: any) {
    this.setState({ [name]: e }, () => {
      const filters: any = {...this.state};
      let organisationTowns: any = [];
      let categorySubs: any = [];
      const {data} = this.props;
      const {organisations, categories} = data;
      if (name === 'organisation') {
        if (null !== filters.organisation) {
          organisationTowns = organisations[filters.organisation.value].towns;
          if (
            filters.town &&
            undefined === organisationTowns[filters.town.value]
          ) {
            this.setState({town: null});
          }
        }
      }

      if (name === 'category') {
        if (null !== filters.category) {
          categorySubs = categories[filters.category.value].subcategories;
          if (
            filters.subCategory &&
            undefined === categorySubs[filters.subCategory.value]
          ) {
            this.setState({subCategory: null});
          }
        }
      }
    });
  }

  incrementalIdChangeHandle = (e: any): any => {
    const re = /^[0-9\b]+$/;
    const value = e.target.value;
    if (value === '' || re.test(value)) {
      this.setState({incrementalId: value});
    }
  };

  deleteSelectedFilterHandler = (propName: string, value: string) => {
    let nextState: any = {};
    const filters: any = { ...this.state };

    if (propName === 'rangeDate') {
      this.setState({ dateRange: [null, null]});
      nextState['dateCreationMin'] = null;
      nextState['dateCreationMax'] = null;
    } else if (propName === 'incrementalId') {
      nextState['incrementalId'] = '';
    } else if (this.riskFilter.includes(propName)) {
      nextState['risk'] = null;
    } else {
      if (Array.isArray(filters[propName])) {
        nextState[propName] = filters[propName].filter(function (filter: any) {
          return filter.value !== value;
        });
      } else {
        nextState[propName] = null;
      }
    }
    this.setState(nextState, () => this.triggerFilters());
  };

  refreshSelectedFilters(filters: any) {
    const { t, data, searchQuery } = this.props;
    const { organisations, categories } = data;
    let elements: any = [];
    let organisationTowns: any = [];
    let categorySubs: any = [];
    if (undefined !== filters) {
      if (filters.organisation) {
        organisationTowns = organisations[filters.organisation.value].towns;

        // In case town previously selected is unavailable in current organisation
        if (filters.town && undefined === organisationTowns[filters.town.value]) {
          filters.town = null;
        }
      }

      if (filters.category) {
        categorySubs = categories[filters.category.value].subcategories;
        if (filters.subCategory && undefined === categorySubs[filters.subCategory.value]) {
          filters.subCategory = null;
        }
      }

      Object.entries(filters).map((filterCategory: any) => {
        const filterName = filterCategory[0];
        let filterCategoryValues = filterCategory[1];
        if (
          null === filterCategoryValues ||
          !this.selectedFiltersConf.includes(filterName)
        ) {
          return null;
        }

        if (!Array.isArray(filterCategoryValues)) {
          if (this.riskFilter.includes(filterName)) {
            if (filters.humanRisk === true) {
              filterCategoryValues = {
                value: Risks.RISK_HUMAN,
                label: t(Risks.RISK_HUMAN),
              }
            } else if (filters.serviceRisk === true) {
              filterCategoryValues = {
                value: Risks.RISK_SERVICE,
                label: t(Risks.RISK_SERVICE),
              }
            } else if (filters.propertyRisk === true) {
              filterCategoryValues = {
                value: Risks.RISK_PROPERTY,
                label: t(Risks.RISK_PROPERTY),
              }
            }
          }

          if (undefined === filterCategoryValues['value']) {
            return null;
          }
          // Single value
          const item = (
            <SelectedFilter
              key={filterName}
              name={filterName}
              deleteHandler={() =>
                this.deleteSelectedFilterHandler(
                  filterName,
                  filterCategoryValues['value']
                )
              }
              label={filterCategoryValues['label']}
            />
          );
          elements.push(item);

          return item;
        } else {
          // Multi values
          if (!filterCategoryValues) {
            return null;
          }

          Object.entries(filterCategoryValues).map((filterCategoryValue: any) => {
            const filter = filterCategoryValue[1];
            const item = (
              <SelectedFilter
                key={filter.value}
                name={filter.value}
                deleteHandler={() =>
                  this.deleteSelectedFilterHandler(filterName, filter.value)
                }
                label={filter.label}
              />
            );
            elements.push(item);

            return item;
          });
        }

        return null;
      });

      // filter range date
      if (filters.dateCreationMin && filters.dateCreationMin.length !== 0) {
        const labelRange = t('filter_range', {
          startDate: moment(filters.dateCreationMin).format('DD/MM/YYYY'),
          endDate: moment(filters.dateCreationMax).format('DD/MM/YYYY'),
        });

        elements.push(
          filters.dateCreationMin &&
            <SelectedFilter
                key="rangeDate"
                name="rangeDate"
                deleteHandler={this.deleteSelectedFilterHandler}
                label={labelRange}
            />
        );
      }

      if (filters.incrementalId) {
        elements.push(
          <SelectedFilter
            key="incrementalId"
            name="incrementalId"
            deleteHandler={() =>
              this.deleteSelectedFilterHandler('incrementalId', '')
            }
            label={filters.incrementalId}
          />
        );
      }
    }

    if (searchQuery) {
      elements.push(
        <SelectedFilter
          key="querySearch"
          name="querySearch"
          deleteHandler={this.props.resetSearchQuery}
          label={searchQuery}
        />
      );
    }

    this.setState({
      selectedFilter: elements,
    });
  }

  getStatusOptions(): any {
    const { t, type } = this.props;
    let statusOptions: any = [];

    Object.entries(Status).map((value: any) => {
      statusOptions.push({
        value: value[1],
        label: t(value[1]),
      });
      return statusOptions;
    });

    if (RequestListPages.MY !== type) {
      // Draft filter shouldn't be active on 'all requests' page.
      statusOptions = statusOptions.filter((status: any) => {
        return 'draft' !== status.value;
      });
    }

    return statusOptions;
  }

  getRisksOptions(): any {
    const { t } = this.props;
    let risksOptions: any = [];

    Object.entries(Risks).map((value: any) => {
      risksOptions.push({
        value: value[1],
        label: t(value[1]),
      });
      return risksOptions;
    });

    return risksOptions;
  }

  getCategoryOptions(): any {
    const { data } = this.props;
    const { categories } = data;

    let categoryOptions: any = [];

    if (categories) {
      Object.entries(categories).map((category: any) => {
        categoryOptions.push({
          value: category[0],
          label: category[1].label,
        });

        return null;
      });
    }

    return categoryOptions;
  }

  getSubCategoryOptions(): any {
    const { data } = this.props;
    const { categories } = data;
    const { category } = this.state;

    let subCategoryOptions: any = [];

    if (categories) {
      Object.entries(categories).map((categoryOption: any) => {
        if (
          (category !== null && category.value === categoryOption[0])
          || category === null
        ) {
          let subCategories = categoryOption[1].subcategories;
          if (subCategories) {
            Object.entries(subCategories).map((subCategory: any) => {
              subCategoryOptions.push({
                value: subCategory[0],
                label: subCategory[1].label,
              });
              return null;
            });
          }
        }
        return null;
      });
    }

    return subCategoryOptions;
  }

  getOrganisationOptions(): any {
    const { data } = this.props;
    const { organisations } = data;

    let organisationsOptions: any = [];

    if (organisations) {
      Object.entries(organisations).map((organisation: any) => {
        organisationsOptions.push({
          value: organisation[0],
          label: organisation[1].label,
        });

        return null;
      });
    }

    return organisationsOptions;
  }

  getTownsOptions(): any {
    const { data } = this.props;
    const { organisations, allTowns } = data;
    const { organisation } = this.state;

    let towns = allTowns;

    // If an organisation was chosen, show only towns from this organisation
    if (null !== organisation) {
      towns = organisations[organisation.value].towns;
    }

    let townsOptions: any = [];

    if (towns) {
      Object.entries(towns).map((town: any) => {
        townsOptions.push({
          value: town[0],
          label: town[1],
        });
        return null;
      });
    }

    return townsOptions;
  }

  getMdmOptions(name: string): any {
    const { t, data } = this.props;
    let lookupKey: string = '';

    switch (name) {
      case MdmOptions.ORGANISATIONS:
        lookupKey = MdmOptions.ORGANISATIONS;
        break;
      case MdmOptions.CLEANSINGS:
        lookupKey = MdmOptions.CLEANSINGS;
        break;
      case MdmOptions.OPERATIONS:
        lookupKey = MdmOptions.OPERATIONS;
        break;
      default:
        return [];
    }

    let options: any = [
      {
        value: '',
        text: t(lookupKey),
      },
    ];

    if (undefined !== data[lookupKey] && null !== data[lookupKey]) {
      Object.keys(data[lookupKey]).map((key: any) => {
        options.push({
          value: key,
          label: data[lookupKey][key],
        });

        return null;
      });
    }

    return options;
  }

  handleIndicatorClick(value: any, indicatorType: string) {
    const { t } = this.props;
    const indicatorFilter: any = [{ value: value, label: t(value) }];
    this.setState({ [indicatorType]: indicatorFilter });
    this.triggerFilters(indicatorFilter, indicatorType);
  }
  renderIndicators() {
    const { type, allIndicators, myIndicators, pendingIndicators, assignedIndicators } = this.props;

    let indicators: any;
    let indicatorType: any;
    // Keeping the current page when searching doesn't make much sense. Return to one.
    if (RequestListPages.ALL === type && allIndicators) {
      indicators = allIndicators.count;
      indicatorType = 'status';
    } else if (RequestListPages.MY === type && myIndicators) {
      indicators = myIndicators.count;
      indicatorType = 'status';
    } else if (RequestListPages.PENDING === type && pendingIndicators) {
      indicators = pendingIndicators.count;
      indicatorType = 'additionalTreatment';
    } else if (RequestListPages.ASSIGNED === type && assignedIndicators) {
      indicators = assignedIndicators.count;
      indicatorType = 'status';
    } else {
      // Return nothing if indicators aren't available yet
      return <></>;
    }

    const indicatorTiles: any = Object.keys(indicators).map((k: any) => {
      return (
        <Indicator
          value={k}
          indicatorType={indicatorType}
          key={k}
          count={indicators[k]}
          onClick={() => this.handleIndicatorClick(k, indicatorType)}
        />
      );
    });

    return (
      <div className="d-flex flex-row justify-content-between">
        {indicatorTiles}
      </div>
    );
  }
  componentDidUpdate(prevProps: any) {
    const { searchQuery, type, allFilters, myFilters, pendingFilters, assignedFilters } = this.props;
    if (prevProps.searchQuery !== searchQuery) {
      let filter: any;
      switch (type) {
        case RequestListPages.ALL:
          filter = allFilters;
          break;
        case RequestListPages.MY:
          filter = myFilters;
          break;
        case RequestListPages.PENDING:
          filter = pendingFilters;
          break;
        case RequestListPages.ASSIGNED:
          filter = assignedFilters;
          break;
      }
      this.refreshSelectedFilters(filter)
    }
  }
  public render() {
    const [schema] = getFormatedForm(filterListModel);
    const {
      isValid,
      selectedFilter,
      dateRange,
    } = this.state;
    const { t, toggleFilters, type } = this.props;
    /*
            Don't use conditional rendering to handle displaying FilterList.
            FilterList should always be mounted, otherwise, hiding / showing the filters will reset it.
            Use a class to handle the actual toggling.
        */
    const filterClassesControl = classnames(
      'position-absolute box-shadow ptn-5 z-request-filter flex-fill align-items-stretch',
      {
        'd-none': !this.props.showFilters,
      }
    );
    const triggerFilters = () => {
      this.triggerFilters();
      toggleFilters();
    };
    return (
      <>
        <div className="col-6">{this.renderIndicators()}</div>
        <div className="col-12 mb-4">
          <Formik
            validationSchema={schema}
            onSubmit={() => {}}
            initialValues={this.state}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ submitForm, setErrors }) => {
              schema.isValid(this.state).then((isValidForm: any) => {
                if (isValidForm !== isValid) {
                  setErrors({});
                  this.setState({ isValid: isValidForm });
                }
              });

              return (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (!isValid) {
                      submitForm();
                    } else {
                      this.triggerFilters();
                    }
                  }}
                >
                  <SzBox className="filter-tag-box" tag="span">
                    {selectedFilter}
                  </SzBox>
                  <SzBox className={filterClassesControl} tag="div">
                    {(type !== RequestListPages.PENDING) &&
                      <div className="float-left w-25 pr-2">
                          <SzSelect
                              id="select-status"
                              className="mb-3 border-secondary"
                              name={filterListModel.status.name}
                              placeholder={t('status')}
                              onChange={this.changeHandle.bind(this, 'status')}
                              options={this.getStatusOptions()}
                              value={this.state['status']}
                              isMulti
                              isClearable={false}
                          />
                      </div>
                    }
                    <div className="float-left w-25 pr-2">
                      <SzSelect
                        className="mb-3 border-secondary"
                        name={filterListModel.risk.name}
                        placeholder={t('risks')}
                        onChange={this.changeHandle.bind(this, 'risk')}
                        options={this.getRisksOptions()}
                        value={this.state['risk']}
                      />
                    </div>
                    <div className="float-left w-25 pr-2">
                      <SzSelect
                        className="mb-3 border-secondary"
                        name={filterListModel.category.name}
                        placeholder={t('category')}
                        onChange={this.changeHandle.bind(this, 'category')}
                        options={this.getCategoryOptions()}
                        value={this.state['category']}
                      />
                    </div>
                    <div className="float-left w-25 pr-2">
                      <SzSelect
                        className="mb-3 border-secondary"
                        name={filterListModel.subCategory.name}
                        placeholder={t('subCategory')}
                        onChange={this.changeHandle.bind(this, 'subCategory')}
                        options={this.getSubCategoryOptions()}
                        value={this.state['subCategory']}
                      />
                    </div>
                    <div className="float-left w-25 pr-2">
                      <SzSelect
                        className="mb-3 border-secondary"
                        name={filterListModel.organisation.name}
                        placeholder={t('organisations')}
                        onChange={this.changeHandle.bind(this, 'organisation')}
                        options={this.getOrganisationOptions()}
                        value={this.state['organisation']}
                      />
                    </div>
                    <div className="float-left w-25 pr-2">
                      <SzSelect
                        className="mb-3 border-secondary"
                        name={filterListModel.town.name}
                        placeholder={t('towns')}
                        onChange={this.changeHandle.bind(this, 'town')}
                        options={this.getTownsOptions()}
                        value={this.state['town']}
                      />
                    </div>
                    <div className="float-left w-25 pr-2">
                      <SzSelect
                        className="mb-3 border-secondary"
                        placeholder={t(MdmOptions.OPERATIONS)}
                        name={filterListModel.operation.name}
                        onChange={this.changeHandle.bind(this, 'operation')}
                        options={this.getMdmOptions(MdmOptions.OPERATIONS)}
                        value={this.state['operation']}
                        isMulti
                      />
                    </div>
                    <div className="float-left w-25 pr-2">
                      <SzSelect
                        className="mb-3 border-secondary"
                        name={filterListModel.cleansing.name}
                        placeholder={t(MdmOptions.CLEANSINGS)}
                        onChange={this.changeHandle.bind(this, 'cleansing')}
                        options={this.getMdmOptions(MdmOptions.CLEANSINGS)}
                        value={this.state['cleansing']}
                        isMulti
                      />
                    </div>
                    <div className="float-left w-50 mr-2 request-range-filter">
                      <SzDateRangePicker
                        selectRange
                        onChange={(dateRange: any) => {
                          this.setState({ dateRange });
                        }}
                        value={dateRange}
                        toggle
                      />
                    </div>
                    <div className="float-left w-25 pr-2">
                      <SzInput
                        name={'incrementalId'}
                        onChange={this.incrementalIdChangeHandle}
                        placeholder={t('incremental_id')}
                        value={this.state['incrementalId']}
                      />
                    </div>
                    <div className="float-left">
                      <SzButton onClick={triggerFilters}>
                        {t('filter')}
                      </SzButton>
                    </div>
                  </SzBox>
                </Form>
              );
            }}
          </Formik>
        </div>
      </>
    );
  }
}

export default withTranslation()(FilterList);
