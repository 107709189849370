import * as Yup from 'yup';
import { phoneRegExp } from '../../constants';

export default {
    contactName: {
        schema: Yup.string().required('required_validation'),
        label: 'contact_name_form_label',
        icon: '',
        name: 'contactName',
        placeholder: '',
        required: true
    },
    contactType: {
        schema: Yup.string().required('required_validation'),
        label: 'contact_type_form_label',
        icon: '',
        name: 'contactType',
        type: 'select',
        placeholder: '',
        required: true
    },
    contactEmail: {
        schema: Yup.string().email('not_valid_email'),
        label: 'contact_email_form_label',
        icon: '',
        name: 'contactEmail',
        type: 'text',
        placeholder: '',
        required: false
    },
    contactPhone: {
        schema: Yup.string().matches(phoneRegExp, 'not_valid_phone'),
        label: 'contact_phone_form_label',
        icon: '',
        name: 'contactPhone',
        type: 'text',
        placeholder: '',
        required: false
    },
    contactShareContactData: {
        label: 'contact_contact_share_contact_data_form_label',
        icon: '',
        name: 'contactShareContactData',
        placeholder: '',
        required: false
    }
};
