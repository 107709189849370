import { Roles } from '../constants';

export class UserService {
    public isAdmin(user: any) {
        return user.roles.includes(Roles.ROLE_ADMIN);
    }

    public isUserAdmin(user: any) {
        return user.roles.includes(Roles.ROLE_USER_ADMIN);
    }

    public isSuez(user: any) {
        return user.roles.includes(Roles.ROLE_SUEZ);
    }

    public isClient(user: any) {
        return !this.isAdmin(user) && user.roles.includes(Roles.ROLE_CLIENT);
    }
}

export default new UserService();
