import React from "react";
import './createUserRequest.scss';
import UserRequestsService from "../../services/UserRequestsService";
import { RequestAttachmentType } from "../../constants";

export abstract class StepManager extends React.Component<any> {

    abstract formValuesToUserRequest(formValues: any): any;

    saveDraft(formValues: any, isAutoSave = false) {
        let { requestFiles, history } = this.props;
        const userRequest = this.formValuesToUserRequest(formValues);
        const files = this.getAttachmentsFiles(formValues);
        if (files.length > 0) {
            requestFiles = (files[0].type === RequestAttachmentType.LOCATION_PLAN) ? {
                ...requestFiles,
                locationPlan: files[0]
            } : { ...requestFiles, other: files }
        }
        const id = userRequest.id

        if (id !== undefined && id) {
            return UserRequestsService.putDraftRequest(userRequest, history, requestFiles, isAutoSave)
        } else {
            return UserRequestsService.postDraftRequest(userRequest, history, requestFiles, isAutoSave)
        }
    }

    nextStepHandle(formValues: any) {
        const { nextStepHandle, updateCurrentUserRequest, addAttachmentRequest } = this.props;
        const userRequest = this.formValuesToUserRequest(formValues);
        updateCurrentUserRequest(userRequest);
        const listAttachmentsFiles = this.getAttachmentsFiles(formValues);
        if (listAttachmentsFiles.length) {
            addAttachmentRequest(listAttachmentsFiles);
        }
        this.saveDraft(formValues, true)
            .then(() => nextStepHandle());
    }

    protected getAttachmentsFiles(formValues: any): any {
        return formValues.attachments ? formValues.attachments : [];
    }
}
