import { SzTypographie, SzBox, SzButton, SzIcon, SzModal } from '@suezenv/react-theme-components';
import React, {useState, useEffect, createRef} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Status,
  RequestAttributes,
  RequestAttachmentType,
  MAP_URI
} from '../../constants';
import SzPopover from '../elements/SzPopover';
import UserService from '../../services/UserService';
import { DateService } from '../../services/dateService';
import moment from 'moment';
import MapComponent from "../elements/mapComponent";
import {LayersControl, Marker, TileLayer} from "react-leaflet";
import icons from "../elements/mapComponent/icons";
import './Block2.scss';
import DesiredDateField from "../forms/fieldUserRequest/DesiredDateField";
import userRequestsService from "../../services/UserRequestsService";

export const Block2 = (props: any) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    attachment: {},
    showDetails: false,
    modalMapActive: false,
  });
  const [firstToPlan, setFirstToPlan] = useState();
  const {
    userRequest,
    treatment,
    risks,
    editRequest,
    cancelRequest,
    user,
    exportingPdf,
    currentRequestStatusList,
    border,
    isEditableDesiredDate,
  } = props;
  const { data } = userRequest;
  const { currentStatus } = userRequest;
  const editAllowOnStatus = [
    Status.STATUS_TO_QUALIFY,
    Status.STATUS_TO_SPECIFY
  ];
  const userIsAuthor = user.id === data.userId;
  const statusAllowsEdit =
      (~editAllowOnStatus.indexOf(currentStatus.status.id) && userIsAuthor) ||
      (~[Status.STATUS_TO_SPECIFY].indexOf(currentStatus.status.id) && !user.roles.includes('ROLE_SUEZ'));
  const statusAllowsDelete = !userRequestsService.isImmutable(currentStatus.status.id) && userIsAuthor;

  const setModalMapActive = (active: boolean) => {
    setState({...state, modalMapActive: active})
    window.dispatchEvent(new Event('resize'));
  }
  useEffect(() => {
    setState({ ...state, showDetails: state.showDetails || exportingPdf });
  }, [exportingPdf]);

  useEffect(() => {
    const firstToPlanValue = currentRequestStatusList.reduce(
      (acc: any, val: any) => {
        // if value hasn't been set yet and current status is to_plan, then it's the first to_plan status, and we want to keep it.
        if (!acc && Status.STATUS_TO_PLAN === val.status.id) {
          return moment(val.createdAt);
        }

        // keep returning null until 1st to_plan is hit, otherwise return the 1st to_plan.
        return acc;
      },
      firstToPlan
    );

    setFirstToPlan(firstToPlanValue);
  }, [currentRequestStatusList]);

  const renderContactDetail = () => {
    const {
      contactType,
      contactTypeOffice,
      contactTypeSubscriber,
      contactTypeOrganisation
    } = data;
    let label: string = '';
    let detailValue: string = '-';

    switch (contactType.id) {
      case RequestAttributes.SUBSCRIBER_TYPE:
        label = t('contact_number_subscriber_form_label');

        // This value can be null
        if (contactTypeSubscriber) {
          detailValue = contactTypeSubscriber;
        }
        break;
      case RequestAttributes.OFFICE_TYPE:
        label = t('contact_office_name_form_label');

        // This value can be null
        if (contactTypeOffice) {
          detailValue = contactTypeOffice;
        }
        break;
      case RequestAttributes.ORGANISATION_TYPE:
        label = t('create_summary_bloc3_contact_type_organisation');
        detailValue = contactTypeOrganisation.label;
        break;
    }

    if (detailValue.length > 0) {
      return (
        <div className="flex-fill">
          <SzTypographie variant="text">{label}</SzTypographie>
          <SzTypographie variant="text">{detailValue}</SzTypographie>
        </div>
      );
    }

    return <></>;
  };

  const renderContact = () => {
    let { contactName, contactType, contactPhone, contactEmail } = data;
    if (!contactName) {
      return <></>;
    }
    contactType = contactType ? contactType.id : '-';
    contactPhone = contactPhone ? contactPhone : '-';
    contactEmail = contactEmail ? contactEmail : '-';

    return (
      <SzBox className="p-3 detail-block2-block">
        <SzBox className="d-flex mb-5" tag="span">
          <SzTypographie variant="h1">
            {t('detail_summary_contact_title')}
          </SzTypographie>
        </SzBox>
        <SzBox
          className="flex-fill d-flex align-items-stretch items-bordered"
          tag="div"
        >
          <div className="flex-fill">
            <SzTypographie variant="text" color="inactive">
              {t('create_summary_bloc3_name')}
            </SzTypographie>
            <SzTypographie variant="text" weight="bold">{contactName}</SzTypographie>
          </div>
          <div className="flex-fill">
            <SzTypographie variant="text" color="inactive">
              {t('create_summary_bloc3_contact_type')}
            </SzTypographie>
            <SzTypographie variant="text" weight="bold">
              {t(`create_summary_bloc3_contact_type_${contactType}`)}
            </SzTypographie>
          </div>
          {renderContactDetail()}
          <div className="flex-fill">
            <SzTypographie variant="text" color="inactive">
              {t('create_summary_bloc3_contact_phone')}
            </SzTypographie>
            <SzTypographie variant="text" weight="bold">{contactPhone}</SzTypographie>
          </div>
          <div className="flex-fill">
            <SzTypographie variant="text" color="inactive">
              {t('create_summary_bloc3_contact_email')}
            </SzTypographie>
            <SzTypographie variant="text" weight="bold">{contactEmail}</SzTypographie>
          </div>
        </SzBox>
      </SzBox>
    );
  };

  const renderDesiredDate = () => {
    let { desiredDate, desiredDateProof } = data;
    const desiredDateFormated = desiredDate ? DateService.formatDate(desiredDate) : '-';

    return (
      <div className="desiredDate">
        <SzTypographie className="pt-2" color="inactive">
          {t('detail_summary_desired_date')}
        </SzTypographie>
        {isEditableDesiredDate ? (
          <DesiredDateField></DesiredDateField>
        ) : (
          <SzTypographie className="white-space-pre-wrap" variant="text" weight="bold">
            {desiredDateFormated}
            {desiredDateFormated && <br />}
            {desiredDateProof}
          </SzTypographie>
        )}
      </div>
    );
  };

  const renderMap = () => {
    const { userRequest } = props;
    const { address, currentStatus } = userRequest;
    const { latitude, longitude } = address;
    const statusId = currentStatus.status.id;

    const zooms = { zoom: 19, minZoom: 11, maxZoom: 19, searchZoom: 15 };

    const refMarker = createRef<Marker>();
    const { BaseLayer } = LayersControl;

    return (
      <SzBox className="p-3 detail-block2-block">
        <SzBox className="d-flex mb-5" tag="span">
          <SzTypographie variant="h1" className="mb-5">
            {t('detail_summary_request_map_title')}
          </SzTypographie>
        </SzBox>
        <MapComponent
          geoShapes={userRequest.shapes}
          zooms={zooms}
          center={[latitude, longitude]}
          disable={true}
          onClick={() =>
            setModalMapActive(true)
          }
        >
          <LayersControl position='topright'>
            <BaseLayer checked name={t('map_plan_view')}>
              <TileLayer
                url={MAP_URI}
                attribution='© <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
              />
            </BaseLayer>
          </LayersControl>
          <Marker
            icon={icons[statusId]}
            key={1}
            position={[latitude, longitude]}
            ref={refMarker}
          />
        </MapComponent>
        <SzModal
          className={"modal-centered modal-map"}
          title={""}
          show={state.modalMapActive}
          size="xl"
          backdrop={true}
          centered={true}
          handleClose={() => {
            setModalMapActive(false)
          }}
        >
          <MapComponent
            geoShapes={userRequest.shapes}
            zooms={zooms}
            center={[latitude, longitude]}
          >
            <LayersControl position='topright'>
              <BaseLayer checked name={t('map_plan_view')}>
                <TileLayer
                  url={MAP_URI}
                  attribution='© <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                />
              </BaseLayer>
            </LayersControl>
            <Marker
              icon={icons[statusId]}
              key={1}
              position={[latitude, longitude]}
              ref={refMarker}
            />
          </MapComponent>
        </SzModal>
      </SzBox>
    );
  }

  const renderInfoRequest = () => {
    const { comment, incrementalId } = userRequest;
    let {
      additionalReference,
      requestCategory,
      requestSubCategory,
      requestCleansingType,
      requestOperationType
    } = data;
    let additionalInfo = '-';
    let items = [additionalReference, comment];

    if (undefined !== additionalReference) {
      items = items.filter(i => null === i || i.length > 0);
      additionalInfo = items.length ? items.join('\n\n') : '-';
    }

    const borderedClassName = border ? 'flex-fill d-flex align-items-stretch items-bordered' : 'flex-fill d-flex align-items-stretch';

    return (
      <SzBox className="p-3 detail-block2-block">
        <SzBox className="d-flex mb-5" tag="span">
          <SzTypographie variant="h1">
            {t('detail_summary_request_info_title')}
          </SzTypographie>
        </SzBox>
        <SzBox
          className={borderedClassName}
          tag="div"
        >
          <div className="flex-fill">
            <SzTypographie variant="text" color="inactive">
              {t('detail_summary_category')}
            </SzTypographie>
            <SzTypographie variant="text" weight="bold">
              {requestCategory.label}
            </SzTypographie>
            <SzTypographie className="pt-2" variant="text" color="inactive">
              {t('detail_summary_sub_category')}
            </SzTypographie>
            <SzTypographie variant="text" weight="bold">
              {requestSubCategory ? requestSubCategory.label : '-'}
            </SzTypographie>
          </div>
          <div className="flex-fill">
            <SzTypographie variant="text" color="inactive">
              {t('detail_summary_operation_type')}
            </SzTypographie>
            {requestOperationType && requestOperationType !== [] ?
              (
                requestOperationType.map((operationType: any, i: any) => {
                  return (
                    <SzTypographie key={i} variant="text" weight="bold">
                      {operationType.label}
                    </SzTypographie>
                  )
                })
              ) : (
                <SzTypographie variant="text" weight="bold">
                  -
                </SzTypographie>
              )
            }
            <SzTypographie className="pt-2" variant="text" color="inactive">
              {t('detail_summary_cleansing_type')}
            </SzTypographie>
            <SzTypographie variant="text" weight="bold">
              {requestCleansingType.label}
            </SzTypographie>
          </div>
          <div className="flex-fill">
            <SzTypographie variant="text" color="inactive">
              {t('detail_summary_risk')}
            </SzTypographie>
            <SzTypographie variant="text" weight="bold">{risks}</SzTypographie>
            {renderDesiredDate()}
          </div>
          <div className="flex-fill">
            <SzTypographie variant="text" color="inactive">
              {t('detail_summary_id')}
            </SzTypographie>
            <SzTypographie variant="text" weight="bold">{incrementalId}</SzTypographie>
            <SzTypographie className="pt-2" variant="text" color="inactive">
              {t('detail_summary_document')}
            </SzTypographie>
            {renderAttachmentViewDetail()}
          </div>
        </SzBox>
        <div className="additionalInfo">
          <SzBox className="d-flex" tag="span">
            <SzTypographie className="pt-2" variant="text" color="inactive">
              {t('detail_summary_comment')}
            </SzTypographie>
          </SzBox>
          <SzBox className="d-flex" tag="span">
            <SzTypographie className="white-space-pre-wrap" variant="text" weight="bold">
              {additionalInfo}
            </SzTypographie>
          </SzBox>
        </div>
      </SzBox>
    );
  };

  const renderTreatment = () => {
    const { mandatoryTreatments, additionalTreatments } = treatment;
    return (
      (UserService.isAdmin(user) || UserService.isSuez(user)) && (
        <SzBox className="p-3 detail-block2-block">
          <SzBox className="d-flex mb-5" tag="span">
            <SzTypographie variant="h1">
              {t('detail_summary_request_treatment_title')}
            </SzTypographie>
          </SzBox>
          <SzBox
            className="flex-fill d-flex align-items-stretch  items-bordered"
            tag="div"
          >
            <div className="flex-fill">
              {mandatoryTreatments.map((treatment: any, i: any) => (
                <SzTypographie key={i} variant="text" weight="bold">
                  {treatment}
                </SzTypographie>
              ))}
            </div>
            <div className="flex-fill">
              {additionalTreatments.map((treatment: any, i: any) => (
                <SzTypographie key={i} variant="text" weight="bold">
                  {treatment}
                </SzTypographie>
              ))}
            </div>
          </SzBox>
        </SzBox>
      )
    );
  };
  const renderCancelPopover = () => {
    return (
      <div className="flex-fill">
        <SzTypographie className="mt-3 mb-4" variant="text" color="inactive">
          {t('cancel_user_request_title')}
        </SzTypographie>
        <div className="d-flex justify-content-center">
          <div className="p-2">
            <SzButton onClick={cancelRequest}>{t('done_cancel_btn')}</SzButton>
          </div>
        </div>
      </div>
    );
  };

  const renderEditionControls = () => {
    return (
      <>
        <div className="mr-5">
          {statusAllowsDelete && (
            <SzPopover
              buttonLabel={t('detail_controls_cancel')}
              placement="bottom"
              id="popover-cancel-request"
              className="popover-cancel-request popover-request"
            >
              {renderCancelPopover()}
            </SzPopover>
          )}
        </div>

        {!!statusAllowsEdit && (
          <div>
            <SzButton onClick={() => editRequest()} variant="secondary">
              {t('detail_controls_edit')}
            </SzButton>
          </div>
        )}
      </>
    );
  };

  const renderAttachmentViewDetail = () => {
    const { requestFiles, downloadAttachmentHandle, userRequest } = props;
    const { locationPlan, other } = requestFiles;
    if (!locationPlan && other.length === 0) {
      return '-';
    }

    let otherFiltered = userRequest.attachments.filter((value: any) => {
      if (RequestAttachmentType.LOCATION_PLAN === value.file.type) {
        return false;
      }

      let createdAt = value.createdAt.date.replace(/\.\d+$/, ''); // we reeeeeaaaaally don't need the milliseconds for this.
      createdAt = moment(createdAt); // use a different date format for every use case bc why not. More formats = more fun.

      return moment(createdAt)
        .utc(true)
        .isBefore(firstToPlan);
    });

    // and now reformat it to match the original structure of the object.
    otherFiltered = otherFiltered.map((item: any) => {
      return {
        ...item,
        file: {
          ...item.file,
          name: item.file.originalFileName // idk why this was renamed. Something important I guess, but not enougth to be documented in any fashion.
        }
      };
    });

    return (
      <>
        {locationPlan && (
          <>
            <div
              onClick={() =>
                downloadAttachmentHandle(locationPlan.file, userRequest)
              }
              className="attachment-detail"
            >
              <SzIcon variant="bold" icon="view-1" />
              <SzTypographie variant="text" weight="bold">
                {locationPlan.file.name}
              </SzTypographie>
            </div>
          </>
        )}
        {otherFiltered.map((attachment: any, index: number) => (
          <div
            key={index}
            onClick={() =>
              downloadAttachmentHandle(attachment.file, userRequest)
            }
            className="attachment-detail"
          >
            <SzIcon variant="bold" icon="view-1" />
            <SzTypographie variant="text" weight="bold">{attachment.file.name}</SzTypographie>
          </div>
        ))}
      </>
    );
  };

  const renderControls = () => {
    return (
      <SzBox
        className="d-flex justify-content-end align-items-center"
        tag="div"
      >
        {/* This button shouldn't be visible unless status is to_qualify or to_specify */}
        {renderEditionControls()}
      </SzBox>
    );
  };
  return (
    <SzBox
      className="d-flex detail-block2 pb-0 pt-0 flex-column infobox"
      tag="div"
    >
      <SzBox
        className="d-flex justify-content-end align-items-center"
        tag="div"
      >
        <div className="d-flex">
          <SzButton
            icon={state.showDetails ? 'subtract-square' : 'navigation-menu-4'}
            className="border-0"
            variant="secondary"
            onClick={() => {
              setState({ ...state, showDetails: !state.showDetails });
            }}
          >
            {state.showDetails
              ? t('hide_request_detail')
              : t('show_request_detail')}
          </SzButton>
        </div>
      </SzBox>
      {state.showDetails && (
        <>
          {renderInfoRequest()}
          {renderMap()}
          {renderTreatment()}
          {renderContact()}
          {renderControls()}
        </>
      )}
    </SzBox>
  );
};
