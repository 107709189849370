import moment from "moment";
import { DelayFormats } from "../constants";

export class DateService {
    public static daysDiff(date1: Date, date2: Date) {
        const hoursDiff = (date1.getTime() - date2.getTime()) / (1000 * 3600);
        if (hoursDiff < 24 && hoursDiff > -24) {
            return {
                'format': DelayFormats.HOURS,
                'value': parseInt((hoursDiff).toFixed(0)),
            }
        }
        return {
            'format': DelayFormats.DAYS,
            'value': parseInt((hoursDiff / 24).toFixed(0)),
        };
    }

    public static formatDate(date: string, options: any = {}) {
        if ('' === date) { // don't try try parse unparsable dates
            return ''
        }

        return DateService.stringToDate(date).toLocaleDateString("fr-FR", options)
    }

    public static fromProcessingDuration(date: string) {
        return moment(date, 'YYYY/MM/DD').format('YYYY-MM-DDTHH:mm:ss')
    }

    public static fromStatusHistory(date: string) {
        return moment(date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss')
    }

    public static fromYMDHMS(date: string) {
        return moment.utc(date).local().format('YYYY-MM-DDTHH:mm:ss')
    }

    // support all browser
    public static stringToDate(date: string) {
        return moment(date).toDate()
    }

    public static dateToString(date: Date) {
        return moment(date).format('YYYY-MM-DDTHH:mm:ss')
    }
}