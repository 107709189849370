import {
  SzBox,
  SzButton,
  SzIcon,
  SzInput,
  SzTypographie,
  SzCheckbox,
  SzDateRangePicker,
  SzSelect,
} from "@suezenv/react-theme-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SzTooltip from "@suezenv/react-theme-components/build/components/SzTooltip/SzTooltip";
import SzPopover from "../elements/SzPopover";
import { TextArea } from "../elements/textArea";
import { Roles, Status, DelayFormats } from "../../constants";
import UserService from "../../services/UserService";
import { DateService } from "../../services/dateService";
import "./Block1.scss";
import UserRequestsService from "../../services/UserRequestsService";

export const Block1 = (props: any) => {
  const { t } = useTranslation();
  const {
    copyRequest,
    currentActions,
    currentRequestStatusList,
    userRequest,
    user,
    refuseRequest,
    planRequest,
    updateRequestAndStatus,
    changeDeadlines,
    updateStatus,
    additionalTreatments,
    assignSupervisor,
    suezUsers,
    isEditableDesiredDate,
  } = props;
  const { copy } = currentActions;
  const { currentStatus, address, data, context } = userRequest;
  const {
    requesterName,
    requesterEmail,
    processingDuration,
    requestCategory,
  } = data;
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowProcessingDate = DateService.stringToDate(processingDuration);
  tomorrowProcessingDate.setDate(tomorrowProcessingDate.getDate() + 1);
  const minDate =
    tomorrowProcessingDate > tomorrow ? tomorrowProcessingDate : tomorrow;

  const [state, setState] = useState({
    refuseComment: "",
    interventionReference: "",
    processingDuration: minDate,
    deadlineComment: "",
  });

  const [isClaim, setIsClaim]: any = useState("undefined");
  const [supervisor, setSupervisor]: any = useState(false);
  const [assignedSupervisor, setAssignedSupervisor]: any = useState<string>(
    t("supervisor_none")
  );

  useEffect(() => {
    setAssignedSupervisor(data.supervisorName ?? t("supervisor_none"));
    if (data.supervisorUserId) {
      setSupervisor({
        value: data.supervisorUserId ?? null,
        label: data.supervisorName ?? null,
      });
    }
  }, [data]);

  if (data.isClaim !== undefined && "undefined" === isClaim) {
    setIsClaim(data.isClaim === "1");
  }

  const [withAdditionalTreatment, setWithAdditionalTreatment]: any =
    useState(false);
  const [additionalTreatment, setAdditionalTreatment]: any = useState(null);

  const withAdditionalTreatmentChangeHandle = (e: any) => {
    setWithAdditionalTreatment("additionalTreatment" === e.target.name);
  };

  const additionalTreatmentChangeHandle = (e: any) => {
    setAdditionalTreatment(e);
  };

  const assignSupervisorHandle = (supervisor: any) => {
    assignSupervisor(supervisor.value);
    setAssignedSupervisor(supervisor.label);
  };

  const getAdditionalTreatments = () => {
    if (undefined !== additionalTreatments) {
      // input format: { tech_value: functional_value}
      // output format: { value: tech_value, label: functional_value }
      return Object.entries(additionalTreatments).map((treatment) => {
        return {
          value: treatment[0],
          label: String(treatment[1]),
        };
      });
    }

    return [];
  };

  const computeAdditionalTreatmentStatus = () => {
    const computeAdditionalTreatment = () => {
      if (withAdditionalTreatment) {
        return undefined !== additionalTreatment
          ? additionalTreatment.value
          : null;
      }

      return null;
    };

    return {
      withAdditionnalTreatment: withAdditionalTreatment ? 1 : 0,
      additionnalTreatment: computeAdditionalTreatment(),
    };
  };

  const getSuezUsersOptions = () => {
    let userOptions: any = [];
    if (suezUsers) {
      Object.entries(suezUsers).map((user: any) => {
        userOptions.push({
          value: user[1].ldapUidNumber,
          label: user[1].firstName + " " + user[1].lastName,
        });

        return null;
      });
    }

    return userOptions;
  };

  const addressInfo = address.address;
  const category = requestCategory.label;
  const statusId = currentStatus.status.id;
  const canAddDelay = [
    Status.STATUS_TO_PLAN,
    Status.STATUS_PLANNED,
    Status.STATUS_ONGOING,
  ].includes(statusId) && !isEditableDesiredDate;
  const canAssignSupervisor = [
    Status.STATUS_TO_QUALIFY,
    Status.STATUS_TO_SPECIFY,
    Status.STATUS_TO_PLAN,
    Status.STATUS_PLANNED,
    Status.STATUS_ONGOING,
    Status.STATUS_DONE,
  ].includes(statusId);

  const changeHandle = (e: any, withAdditionalTreatment: boolean = false) => {
    if (withAdditionalTreatment) {
      if (e.target.value.length > 7) {
        e.target.value = e.target.value.slice(0, 7); //withLimitChar
      }
      setState({
        ...state,
        [e.target.name]: e.target.value.replace(/[^\w\s]/gi, ""), //withoutSepcialChar
      });
      return;
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const isClaimChangeHandle = (e: any) => {
    setIsClaim("isClaim" === e.target.name);
  };

  const changeDatePickerHandle = (date: any) => {
    setState({ ...state, processingDuration: date });
  };

  const renderRefusePopover = (status: string) => {
    return (
      <div className="flex-fill">
        <SzTypographie className="mt-3 mb-4" variant="text">
          {t("refuse_user_request_title")}
        </SzTypographie>
        <SzTypographie className="mb-4" variant="text">
          {t("refuse_user_request_sub_title")}
        </SzTypographie>
        <TextArea
          onChange={changeHandle}
          label={t("refuse_raison_label")}
          name="refuseComment"
          type="textArea"
          required
          value={state.refuseComment}
          rows="3"
        />
        <div className="d-flex justify-content-center ">
          <div className="p-2">
            <SzButton
              isDisabled={!state.refuseComment}
              onClick={() => refuseRequest(state.refuseComment, status)}
            >
              {t("done_refuse_btn")}
            </SzButton>
          </div>
        </div>
      </div>
    );
  };

  const renderValidatePopover = () => {
    return (
      <div className="flex-fill">
        <SzTypographie className="my-4" variant="text">{`${t(
          "request_is_claim"
        )} :`}</SzTypographie>
        <div className="d-flex justify-content-around">
          <SzCheckbox
            className="mb-3"
            id="isClaim"
            name="isClaim"
            checked={isClaim}
            onChange={isClaimChangeHandle}
            type="radio"
            label={t("yes_btn")}
          />
          <SzCheckbox
            className="mb-3"
            id="isNotClaim"
            name="isNotClaim"
            checked={!isClaim}
            onChange={isClaimChangeHandle}
            type="radio"
            label={t("no_btn")}
          />
        </div>
        <SzTypographie>{t("supervisor_text")}</SzTypographie>
        <SzSelect
          className="mb-3 border-secondary"
          name={"supervisor"}
          placeholder={supervisor.label ?? t("supervisor_text")}
          onChange={setSupervisor}
          options={getSuezUsersOptions()}
          value={supervisor.id}
        />
        <SzTypographie className="my-4" variant="text">
          {t("validate_user_request_question")}
        </SzTypographie>
        <div className="d-flex justify-content-center">
          <div className="p-2">
            <SzButton
              isDisabled={!supervisor}
              onClick={() =>
                updateStatus(Status.STATUS_TO_PLAN, isClaim, supervisor)
              }
            >
              {t("done_validate_btn")}
            </SzButton>
          </div>
        </div>
      </div>
    );
  };

  const renderDeadlinesPopover = () => {
    return (
      <div className="flex-fill">
        <SzTypographie className="my-4" variant="text">
          {t("add_deadlines_question")}
        </SzTypographie>
        <SzTypographie className="mb-1 required sz-label-input" variant="text">
          {t("select_date_label")}
        </SzTypographie>
        <SzDateRangePicker
          onChange={changeDatePickerHandle}
          value={state.processingDuration}
          minDate={minDate}
          toggle
        />
        <div className="mt-3">
          <TextArea
            onChange={changeHandle}
            label={t("comment_label")}
            name="deadlineComment"
            type="textArea"
            required
            value={state.deadlineComment}
            rows="3"
          />
        </div>
        <div className="d-flex justify-content-center ">
          <div className="p-2">
            <SzButton
              isDisabled={!state.deadlineComment}
              onClick={() =>
                changeDeadlines(state.processingDuration, state.deadlineComment)
              }
            >
              {" "}
              {t("done_btn")}
            </SzButton>
          </div>
        </div>
      </div>
    );
  };

  const renderChangeStatusPopOver = (
    validationMessage: string,
    status: string
  ) => {
    return (
      <div className="flex-fill">
        <SzTypographie className="my-4" variant="text">{`${t(
          "request_is_claim"
        )} :`}</SzTypographie>
        <div className="d-flex justify-content-around">
          <SzCheckbox
            className="mb-3"
            id="isClaim"
            name="isClaim"
            checked={isClaim}
            onChange={isClaimChangeHandle}
            type="radio"
            label={t("yes_btn")}
          />
          <SzCheckbox
            className="mb-3"
            id="isNotClaim"
            name="isNotClaim"
            checked={!isClaim}
            onChange={isClaimChangeHandle}
            type="radio"
            label={t("no_btn")}
          />
        </div>
        <SzTypographie className="mt-4 mb-4" variant="text">
          {validationMessage}
        </SzTypographie>
        <div className="d-flex justify-content-center">
          <div className="p-2">
            <SzButton onClick={() => updateStatus(status, isClaim, supervisor)}>
              {t("change_status_confirmation_btn")}
            </SzButton>
          </div>
        </div>
      </div>
    );
  };

  const renderPlanPopOver = () => {
    return (
      <div className="flex-fill">
        <SzInput
          onChange={(e: any) => changeHandle(e, true)}
          placeholder=""
          label={t("intervention_reference_label")}
          name="interventionReference"
          type="text"
          value={state.interventionReference}
        />
        <SzTypographie className="my-4" variant="text">{`${t(
          "request_is_claim"
        )} :`}</SzTypographie>
        <div className="d-flex justify-content-around">
          <SzCheckbox
            className="mb-3"
            id="isClaim"
            name="isClaim"
            checked={isClaim}
            onChange={isClaimChangeHandle}
            type="radio"
            label={t("yes_btn")}
          />
          <SzCheckbox
            className="mb-3"
            id="isNotClaim"
            name="isNotClaim"
            checked={!isClaim}
            onChange={isClaimChangeHandle}
            type="radio"
            label={t("no_btn")}
          />
        </div>
        <SzTypographie className="mt-4 mb-4" variant="text">
          {t("planned_popover_message")}
        </SzTypographie>
        <div className="d-flex justify-content-center ">
          <div className="p-2">
            <SzButton
              onClick={() => planRequest(state.interventionReference, isClaim)}
            >
              {t("change_status_confirmation_btn")}
            </SzButton>
          </div>
        </div>
      </div>
    );
  };

  const renderChangeStatusWithAdditionalTreatmentPopOver = (
    validationMessage: string,
    status: string
  ) => {
    return (
      <div className="flex-fill flex-column">
        <SzTypographie className="mt-4 mb-4" variant="text">
          {validationMessage}
        </SzTypographie>
        <div className="d-flex justify-content-around">
          <SzCheckbox
            className="mb-3"
            id="additionalTreatment"
            name="additionalTreatment"
            checked={withAdditionalTreatment}
            onChange={withAdditionalTreatmentChangeHandle}
            type="radio"
            label={t("yes_btn")}
          />
          <SzCheckbox
            className="mb-3"
            name="noAdditionalTreatment"
            id="noAdditionalTreatment"
            checked={!withAdditionalTreatment}
            onChange={withAdditionalTreatmentChangeHandle}
            type="radio"
            label={t("no_btn")}
          />
        </div>
        {withAdditionalTreatment && (
          <div className="d-flex justify-content-around">
            <SzSelect
              className="mb-3 border-secondary w-100"
              name="additionalTreatment"
              placeholder={t("closed_popover_choose_treatment")}
              onChange={additionalTreatmentChangeHandle}
              options={getAdditionalTreatments()}
              value={additionalTreatment}
            />
          </div>
        )}
        <SzTypographie className="my-4" variant="text">{`${t(
          "request_is_claim"
        )} :`}</SzTypographie>
        <div className="d-flex justify-content-around">
          <SzCheckbox
            className="mb-3"
            id="isClaim"
            name="isClaim"
            checked={isClaim}
            onChange={isClaimChangeHandle}
            type="radio"
            label={t("yes_btn")}
          />
          <SzCheckbox
            className="mb-3"
            id="isNotClaim"
            name="isNotClaim"
            checked={!isClaim}
            onChange={isClaimChangeHandle}
            type="radio"
            label={t("no_btn")}
          />
        </div>
        <div className="d-flex justify-content-center ">
          <div className="p-2">
            <SzButton
              onClick={() => {
                updateRequestAndStatus(
                  status,
                  computeAdditionalTreatmentStatus()
                );
              }}
              isDisabled={withAdditionalTreatment && !additionalTreatment}
            >
              {t("change_status_confirmation_btn")}
            </SzButton>
          </div>
        </div>
      </div>
    );
  };

  const renderPopover = (status: string) => {
    let popoverContent: any,
      label: string = "",
      id: string = "";

    switch (status) {
      case "to_specify":
        label = t("refuse_btn");
        id = "popover-refuse-request";
        popoverContent = renderRefusePopover(Status.STATUS_TO_SPECIFY);
        break;
      case "to_plan":
        label = t("done_btn");
        id = "popover-done-request";
        popoverContent = renderValidatePopover();
        break;
      case "closed_rejected":
        label = t("closed_rejected_btn");
        id = "popover-rejected-request";
        popoverContent = renderRefusePopover(Status.STATUS_CLOSED_REJECTED);
        break;
      case "planned":
        label = t("planned_btn");
        id = "planned_request";
        popoverContent = renderPlanPopOver();
        break;
      case "ongoing":
        label = t("ongoing_btn");
        id = "ongoing_request";
        popoverContent = renderChangeStatusPopOver(
          t("ongoing_popover_message"),
          status
        );
        break;
      case "done":
        label = t("done_realized_btn");
        id = "done_realized_request";
        popoverContent = renderChangeStatusWithAdditionalTreatmentPopOver(
          t("done_realized_popover_message"),
          status
        );
        break;
      case "replan":
        label = t("to_plan");
        id = "replan";
        popoverContent = renderChangeStatusPopOver(
          t("planned_popover_message"),
          Status.STATUS_TO_PLAN
        );
        break;
      case "closed":
        label = t("closed_btn");
        id = "closed";
        popoverContent = renderChangeStatusPopOver(
          t("closed_popover_message"),
          Status.STATUS_CLOSED
        );
        break;
      default:
        return null;
    }

    return (
      <div className="flex-fill mr-3" key={id}>
        <SzPopover
          buttonLabel={label}
          placement="bottom"
          id={id}
          outline
          className="popover-request popover-status-request"
        >
          {popoverContent}
        </SzPopover>
      </div>
    );
  };

  const renderButton = (status: string) => {
    let label: string = "";

    if (status === "closed") {
      label = t("closed_btn");
    } else {
      return null;
    }

    return (
      <div className="flex-fill mr-3" key={status}>
        <SzButton
          variant="secondary"
          onClick={() => updateStatus(status, isClaim, supervisor)}
        >
          {label}
        </SzButton>
      </div>
    );
  };

  const renderAssignSupervisor = () => {
    return (
      user.roles.includes(Roles.ROLE_SUEZ) &&
      canAssignSupervisor && (
        <div className="flex-fill">
          <SzPopover
            placement="bottom"
            id={"assign-supervisor"}
            outline
            className="popover-request popover-supervisor"
            icon="single-neutral-actions-setting"
            btnVariant="tertiary"
          >
            <SzTypographie>
              {t("supervisor_saved")} {assignedSupervisor}
            </SzTypographie>
            <SzTypographie>{t("supervisor_text")}</SzTypographie>
            <SzSelect
              className="mb-3 border-secondary"
              name={"supervisor"}
              placeholder={supervisor.label ?? t("supervisor_text")}
              onChange={setSupervisor}
              options={getSuezUsersOptions()}
              value={supervisor.id}
            />
            <div className="d-flex justify-content-center">
              <SzButton
                variant="secondary"
                onClick={() => assignSupervisorHandle(supervisor)}
                isDisabled={supervisor === false}
              >
                {t("supervisor_btn")}
              </SzButton>
            </div>
          </SzPopover>
        </div>
      )
    );
  };

  const renderStatusControls = () => {
    const statusChangeRequirements: any = {
      to_qualify: {
        id: "to_qualify",
        roles: [Roles.ROLE_CLIENT],
        rendering_mode: "button",
      },
      to_specify: {
        roles: [Roles.ROLE_SUEZ],
        rendering_mode: "popover",
      },
      to_plan: {
        roles: [Roles.ROLE_SUEZ],
        rendering_mode: "popover",
      },
      planned: {
        roles: [Roles.ROLE_SUEZ],
        rendering_mode: "popover",
      },
      ongoing: {
        roles: [Roles.ROLE_SUEZ],
        rendering_mode: "popover",
      },
      done: {
        roles: [Roles.ROLE_SUEZ],
        rendering_mode: "popover",
      },
      closed_rejected: {
        roles: [Roles.ROLE_ADMIN],
        rendering_mode: "popover",
      },
      closed: {
        roles: [Roles.ROLE_ADMIN],
        rendering_mode: "popover",
      },
    };

    const { nextPossibleStatuses, data, currentStatus } = userRequest;

    if (undefined !== nextPossibleStatuses) {
      return Object.entries(nextPossibleStatuses).map((status: any) => {
        const statusId = status[1].id;
        const statusConf = statusChangeRequirements[statusId];

        if (undefined === statusConf) {
          return null;
        }

        // If user role is allowed in status conf roles
        const rolesIntersec = user.roles.filter((value: any) =>
          statusConf.roles.includes(value)
        );
        if (0 === rolesIntersec.length) {
          return null;
        }

        // If user has role CLIENT, he can only edit his own requests
        if (UserService.isClient(user) && user.id !== data.userId) {
          return null;
        }

        // exception case here
        if (
          Status.STATUS_TO_PLAN === statusId &&
          Status.STATUS_CLOSED_REJECTED === currentStatus.status.id
        ) {
          return renderPopover("replan");
        }

        switch (statusConf.rendering_mode) {
          case "popover":
            return renderPopover(statusId);
          case "button":
            return renderButton(statusId);
        }

        return null;
      });
    }
  };

  let { addressAdditionalInformation } = userRequest.data;

  if (
    undefined === addressAdditionalInformation ||
    null === addressAdditionalInformation
  ) {
    addressAdditionalInformation = "";
  }

  if (addressAdditionalInformation.length > 50) {
    addressAdditionalInformation = (
      <abbr title={addressAdditionalInformation}>
        {addressAdditionalInformation.substring(0, 50) + "..."}
      </abbr>
    );
  }

  const renderDelayValue = (): JSX.Element => {
    const {delayObject, dateToShow} = UserRequestsService.processDelayDetails(userRequest, currentRequestStatusList);
    const delayTranslateRef =
      delayObject.format === DelayFormats.HOURS
        ? "view_request_block_1_remaining_delay_value_hours"
        : "view_request_block_1_remaining_delay_value";
    return (
      <SzTypographie variant="text" weight="bold">
        {processingDuration
          ? t(delayTranslateRef, {
              count: delayObject.value,
              date: DateService.formatDate(dateToShow),
            })
          : "-"}
      </SzTypographie>
    );
  };

  const renderAdress = () => {
    if (!context) {
      return;
    }
    let addressData = ["", "", "", ""];
    context.map((item: any) => {
      switch (item.type) {
        case "organisation":
          addressData[0] = item.label;
          break;
        case "town":
          addressData[1] = item.label;
          break;
        case "sector":
          addressData[2] = item.label;
          break;
        case "district":
          addressData[3] = item.label;
          break;
        default:
          break;
      }
      return item;
    });
    const filterAddressData = addressData.filter(function (el: string) {
      return !!el;
    });
    return (
      <SzBox className="d-flex">
        <SzIcon className="maps-icon pr-2" icon="maps-pin-1" variant="line" />
        <SzTypographie>{filterAddressData.join(" - ")}</SzTypographie>
      </SzBox>
    );
  };

  const addressInfoLine = `${addressInfo}${
    addressAdditionalInformation.length
      ? " " + addressAdditionalInformation
      : ""
  }`;

  const renderTitle = () => {
    return (
      <SzTypographie className="mb-2 request-view-title" variant="large">
        {`${t("view_request_title", {
          request_id: userRequest.incrementalId,
        })}`}
        {data.isClaim && <span> | {t("request_claim")}</span>}
      </SzTypographie>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col block1-header">
          <Link to="/">
            <SzTypographie
              className="request-view-go-back"
              variant="text"
              weight="bold"
            >
              {`← ${t("view_request_go_back")}`}
            </SzTypographie>
          </Link>
          {renderTitle()}
          {renderAdress()}
        </div>
        <div className="col-1">
          {copy && (
            <SzTooltip
              className="duplicateTooltip title-tooltip"
              content={t("request_copy")}
            >
              <SzButton
                className="export w-100"
                onClick={() => copyRequest()}
                icon="common-file-double-2"
                variant="tertiary"
              />
            </SzTooltip>
          )}
        </div>
        <div className="col-auto" />
      </div>
      <SzBox
        className="d-flex detail-block1 infobox p-2 mt-4 items-bordered"
        tag="div"
      >
        <div className="flex-fill d-flex align-items-stretch ">
          <div
            className="align-self-start mr-3 info-container"
            title={requesterEmail}
          >
            <span className="sz-icon-line information-circle" />
          </div>
          <div>
            <SzTypographie variant="text" color="inactive">
              {t("view_request_block_1_requester")}
            </SzTypographie>
            <SzTypographie variant="text" weight="bold">
              {requesterName}
            </SzTypographie>
          </div>
        </div>
        <div className="flex-fill">
          <SzTypographie variant="text" color="inactive">
            {t("view_request_block_1_address")}
          </SzTypographie>
          <SzTypographie variant="text" weight="bold">
            {addressInfoLine}
          </SzTypographie>
        </div>
        <div className="flex-fill">
          <SzTypographie variant="text" color="inactive">
            {t("view_request_block_1_category")}
          </SzTypographie>
          <SzTypographie variant="text" weight="bold">
            {category}
          </SzTypographie>
        </div>
        <div className="flex-fill">
          <SzTypographie variant="text" color="inactive">
            {t("view_request_block_1_remaining_delay")}
          </SzTypographie>
          {renderDelayValue()}
        </div>
        <div className="flex-fill d-flex align-items-stretch">
          <div className="align-self-start">
            <div className={`status-sticker status-sticker-${statusId}`} />
          </div>
          <div>
            <SzTypographie variant="text" color="inactive">
              {t("view_request_block_1_status")}
            </SzTypographie>
            <SzTypographie variant="text" weight="bold">
              {t(statusId)}
            </SzTypographie>
          </div>
        </div>
        <div className="flex-fill d-flex no-border">
          {renderAssignSupervisor()}
          {renderStatusControls()}
          {UserService.isAdmin(user) && canAddDelay && (
            <div className="flex-fill mr-3 deadlines-action">
              <SzPopover
                buttonLabel=""
                placement="bottom"
                id="popover-deadlines"
                icon={"time-clock-hand-1"}
                className="popover-deadlines popover-request"
              >
                {renderDeadlinesPopover()}
              </SzPopover>
            </div>
          )}
        </div>
      </SzBox>
    </>
  );
};
