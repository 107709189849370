import React from 'react';
import UserRequestsList from '../../manager/userRequestsList'
import { SzButton, SzTab } from '@suezenv/react-theme-components';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './index.scss';
import {AppUrls, Roles} from '../../constants';
import UserRequestsService from '../../services/UserRequestsService';
import { MapGeoCodeService } from '../../services/MapGeoCodeService';
import {connect} from "react-redux";
import {setListTab} from "../../actions";

class UserRequests extends React.Component<any> {
    state = {
        tab: '',
        mobile: false,
    };

    componentDidMount() {
        this.setState({
            tab: this.props.tab,
            mobile: window.innerWidth < 935
        });
        window.addEventListener('resize', () => {
            this.setState({
                mobile: window.innerWidth < 935
            });
        }, false);
    }

    setRedirectHandle() {
        UserRequestsService.resetCurrentUserRequest();
        MapGeoCodeService.autoCompleteAddress('');
        const { history } = this.props;
        history.push(AppUrls.CREATE_REQUEST);
    }
    renderAddRequestBtn () {
        const { t } = this.props
        const createButtonClass = this.state.mobile ? '': 'float-right';
        return (
            <div className={createButtonClass}>
                <SzButton
                    icon="add-square"
                    alignIcon="left"
                    className="add-user-request"
                    onClick={() => this.setRedirectHandle()} >
                    {t('add_user_request')}
                </SzButton>
            </div>
        )
    }

    selectTabHandle(tab: string) {
        this.props.setListTab(tab);
    }

    public render() {
        const { t, user, tab } = this.props
        const isAdmin = user.roles.includes(Roles.ROLE_ADMIN);
        const isSuez = user.roles.includes(Roles.ROLE_SUEZ);
        if (isAdmin) {
            return (
              <div className="container-fluid mt-5">
                  <div className="row">
                      <div className="col-12">
                          {this.renderAddRequestBtn()}
                          <SzTab
                            id="user-requests"
                            activeKey={tab}
                            className="user-requests-tab"
                            selectTabHandle={this.selectTabHandle.bind(this)}
                          >
                              <SzTab.SzTabContent eventKey="tab-0" title={t('ALL_USER_REQUESTS')}>
                                  <div className="row mt-5">
                                      <div className="col">
                                          <UserRequestsList type="all"/>
                                      </div>
                                  </div>
                              </SzTab.SzTabContent>
                              <SzTab.SzTabContent eventKey="tab-1" title={t('MY_USER_REQUESTS')}>
                                  <div className="row mt-5">
                                      <div className="col">
                                          <UserRequestsList type="my"/>
                                      </div>
                                  </div>
                              </SzTab.SzTabContent>
                              <SzTab.SzTabContent eventKey="tab-2" title={t('PENDING_USER_REQUESTS')}>
                                  <div className="row mt-5">
                                      <div className="col">
                                          <UserRequestsList type="pending"/>
                                      </div>
                                  </div>
                              </SzTab.SzTabContent>
                          </SzTab>
                      </div>
                  </div>
              </div>
            )
        }
        if (isSuez) {
            return (
              <div className="container-fluid mt-5">
                  <div className="row">
                      <div className="col-12">
                          {this.renderAddRequestBtn()}
                          <SzTab
                            id="user-requests"
                            activeKey={tab}
                            className="user-requests-tab"
                            selectTabHandle={this.selectTabHandle.bind(this)}
                          >
                              <SzTab.SzTabContent eventKey="tab-0" title={t('ALL_USER_REQUESTS')}>
                                  <div className="row mt-5">
                                      <div className="col">
                                          <UserRequestsList type="all"/>
                                      </div>
                                  </div>
                              </SzTab.SzTabContent>
                              <SzTab.SzTabContent eventKey="tab-1" title={t('MY_USER_REQUESTS')}>
                                  <div className="row mt-5">
                                      <div className="col">
                                          <UserRequestsList type="my"/>
                                      </div>
                                  </div>
                              </SzTab.SzTabContent>
                              <SzTab.SzTabContent eventKey="tab-2" title={t('ASSIGNED_USER_REQUESTS')}>
                                  <div className="row mt-5">
                                      <div className="col">
                                          <UserRequestsList type="assigned"/>
                                      </div>
                                  </div>
                              </SzTab.SzTabContent>
                              <SzTab.SzTabContent eventKey="tab-3" title={t('PENDING_USER_REQUESTS')}>
                                  <div className="row mt-5">
                                      <div className="col">
                                          <UserRequestsList type="pending"/>
                                      </div>
                                  </div>
                              </SzTab.SzTabContent>
                          </SzTab>
                      </div>
                  </div>
              </div>
            )
        }
        return (
            <>
                <div className="container-fluid mt-5">
                    <div className="row ">
                        <div className="col-12">
                            {this.renderAddRequestBtn()}
                            <SzTab
                              id="user-requests"
                              activeKey={tab}
                              className="user-requests-tab"
                              selectTabHandle={this.selectTabHandle.bind(this)}
                            >
                                <SzTab.SzTabContent eventKey="tab-0" title={t('ALL_USER_REQUESTS')}>
                                    <div className="row mt-5">
                                        <div className="col">
                                            <UserRequestsList type="all"/>
                                        </div>
                                    </div>
                                </SzTab.SzTabContent>
                                <SzTab.SzTabContent eventKey="tab-1" title={t('MY_USER_REQUESTS')}>
                                    <div className="row mt-5">
                                        <div className="col">
                                            <UserRequestsList type="my"/>
                                        </div>
                                    </div>
                                </SzTab.SzTabContent>
                            </SzTab>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = (state: any) => ({
    tab: state.userRequests.tab,
    user: state.user.info,
});

const mapDispatchToProps = {
    setListTab: setListTab
}
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation()(UserRequests))
);
