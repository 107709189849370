import { Constants } from "../constants";

export const setAllRequests = (payload: any) => ({
    type: Constants.SET_ALL_USER_REQUESTS,
    payload: payload
});
export const setAssignedRequests = (payload: any) => ({
    type: Constants.SET_ASSIGNED_USER_REQUESTS,
    payload: payload
});
export const setMyRequests = (payload: any) => ({
    type: Constants.SET_MY_USER_REQUESTS,
    payload: payload
});
export const setPendingRequests = (payload: any) => ({
    type: Constants.SET_PENDING_USER_REQUESTS,
    payload: payload
});
export const updateCurrentUserRequest = (payload: any) => ({
    type: Constants.UPDATE_CURRENT_USER_REQUEST,
    payload: payload
});
export const resetCurrentUserRequest = () => ({
    type: Constants.RESET_CURRENT_USER_REQUEST,
});
export const setCurrentInterventions = (payload: any) => ({
    type: Constants.UPDATE_CURRENT_INTERVENTIONS,
    payload: payload
});
export const toggleListToast = (payload = { show: false, isDraft: false }) => (
    {
        type: Constants.TOGGLE_LIST_TOAST,
        payload: payload
    });
export const setRequest = (payload: any) => ({
    type: Constants.SET_REQUEST,
    payload: payload
});
export const setRequestActions = (payload: any) => ({
    type: Constants.SET_REQUEST_ACTIONS,
    payload: payload
});
export const setRequestHistory = (payload: any) => ({
    type: Constants.SET_REQUEST_HISTORY,
    payload: payload
});
export const setCategoriesList = (payload: any) => ({
    type: Constants.SET_CATEGORIES_LIST,
    payload: payload
});
export const setMDMData = (payload: any) => ({
    type: Constants.SET_MDM_DATA,
    payload: payload
});
export const setGeoShapes = (payload: any) => ({
    type: Constants.SET_GEOSHAPES,
    payload: payload
});
export const setRequestSatusList = (payload: any) => ({
    type: Constants.SET_REQUEST_STATUS_LIST,
    payload: payload
});
export const computeDelay = (payload: any) => ({
    type: Constants.COMPUTE_DELAY,
    payload: payload
});
export const resetCurrentAttachmentRequest = () => ({
    type: Constants.RESET_CURRENT_ATTACHMENT_REQUEST,
});
export const addAttachmentRequest = (payload: any) => ({
    type: Constants.ADD_ATTACHMENT_REQUEST,
    payload: payload
});
export const setMyIndicators = (payload: any) => ({
    type: Constants.SET_MY_INDICATORS,
    payload: payload
})
export const setPendingIndicators = (payload: any) => ({
    type: Constants.SET_PENDING_INDICATORS,
    payload: payload
})
export const setAllIndicators = (payload: any) => ({
    type: Constants.SET_ALL_INDICATORS,
    payload: payload
})
export const setAssignedIndicators = (payload: any) => ({
    type: Constants.SET_ASSIGNED_INDICATORS,
    payload: payload
})
export const triggerAllFilters = (payload:any) => ({
    type: Constants.TRIGGER_ALL_FILTERS,
    payload: payload
})
export const triggerAssignedFilters = (payload:any) => ({
    type: Constants.TRIGGER_ASSIGNED_FILTERS,
    payload: payload
})
export const triggerMyFilters = (payload:any) => ({
    type: Constants.TRIGGER_MY_FILTERS,
    payload: payload
})
export const triggerPendingFilters = (payload:any) => ({
    type: Constants.TRIGGER_PENDING_FILTERS,
    payload: payload
})
export const setCurrentPage = (payload:any) => ({
    type: Constants.SET_CURRENT_PAGE,
    payload: payload
})
export const setListTab = (payload: any) => ({
    type: Constants.SET_LIST_TAB,
    payload: payload
})
export const setSortBy = (payload:any) => ({
    type: Constants.SET_SORT_BY,
    payload: payload
})