import { store } from '../store';
import { loadingSpinner, setDataForm } from '../actions';

export const SUBSCRIBER_CONST = 'subscriber';
export const OFFICE_CONST = 'office';
export const ORGANISATION_CONST = 'organisation';

class RequesterService {
    public setDataForm(mdmData: any) {
        store.dispatch(loadingSpinner(true));
        let data: any = {};
        Object.entries(mdmData.contactTypes).map((value) => {
            let contactTypeObject: any = {
                label: value[1]
            }
            switch (value[0]) {

                case SUBSCRIBER_CONST:
                    {
                        contactTypeObject.field = {
                            type: "text",
                            name: "contactTypeSubscriber",
                            label: "contact_number_subscriber_form_label",
                        }
                        break;
                    }
                case OFFICE_CONST:
                    {
                        contactTypeObject.field = {
                            type: "text",
                            name: "contactTypeOffice",
                            label: "contact_office_name_form_label",
                            required: false
                        }
                        break;
                    }
                case ORGANISATION_CONST:
                    {
                        contactTypeObject.field = {
                            type: "select",
                            options: Object.entries(mdmData.organisations).map((value: any) => ({ value: value[0], text: value[1] })
                            ),
                            name: "contactTypeOrganisation",
                            label: "organisations",
                            required: false
                        }
                        break;
                    }


            }
            data[value[0]] = contactTypeObject;

            return contactTypeObject;
        });
        store.dispatch(setDataForm(data));
        store.dispatch(loadingSpinner(false));
    }
}

export default new RequesterService();