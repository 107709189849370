import React from 'react';
import { connect } from 'react-redux';
import userRequestsService from '../../services/UserRequestsService';
import { withTranslation } from 'react-i18next';
import Toast from 'react-bootstrap/Toast';
import {
  toggleListToast,
  triggerAllFilters,
  triggerMyFilters,
  triggerPendingFilters,
  triggerAssignedFilters,
  setCurrentPage,
  setSortBy
} from '../../actions';
import {
  AppUrls,
  DelayFormats,
  RequestListPages,
  Status,
} from '../../constants';
import {
  SzButton,
  SzInput,
  SzPagination,
  SzTypographie,
  SzTable,
} from '@suezenv/react-theme-components';
import FilterList from '../../components/forms/filterList/FilterList';
import { DateService } from '../../services/dateService';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import UserRequestsService from "../../services/UserRequestsService";

interface IUserRequestList extends RouteComponentProps<any> {
  type: string;
}

class UserRequestsListManager extends React.Component<any> {
  state = {
    showToast: true,
    searchQuery: '',
    appliedSearchQuery: '',
    showFilters: false,
    filter: { my: {}, all: {}, pending: {}, assigned: {} },
    sort: { direction: 'desc', field: 'submissionDate' },
    columns: [],
  };
  DELAYS_TOAST = 10000;
  columns: any = [];
  sortKeyValues: any = {
    status: 'status.label',
    id: 'incrementalId',
    category: 'requestCategory.label',
    subCategory: 'requestSubCategory.label',
    address: 'address.address',
    requesterName: 'requesterName',
    updatedAt: 'updatedAt',
    submissionDate: 'submissionDate',
    processingDuration: 'processingDuration',
    additionalTreatment: 'additionnalTreatment.label',
  };

  componentDidMount() {
    const { type, mdmData, allFilters, myFilters, pendingFilters, assignedFilters } = this.props;
    let filters: any;

    switch (type) {
      case RequestListPages.ALL:
        filters = allFilters;
        break;
      case RequestListPages.MY:
        filters = myFilters;
        break;
      case RequestListPages.PENDING:
        filters = pendingFilters;
        break;
      case RequestListPages.ASSIGNED:
        filters = assignedFilters;
        break;
    }
    // Wait for user request data to be well refreshed
    setTimeout(() => {
      this.setRequestData(type);
    }, 1500);
    this.setQuery();
    this.setIndicators(type, filters);

    // Read categories to test if object has been initialized already
    if (undefined === mdmData.category) {
      userRequestsService.getMdmData();
    }
    this.setColumn(type);
  }

  setColumn(type: string, dataField: string = '', sortDirection: string = '') {
    const commonColumns = [
      this.columnJson(
        'id',
        'id',
        'id',
        dataField,
        sortDirection
      ),
      this.columnJson(
        'subCategory',
        'subCategory',
        'subCategory',
        dataField,
        sortDirection
      ),
      this.columnJson(
        'address',
        'address',
        'address',
        dataField,
        sortDirection
      ),
      this.columnJson(
        'requester-name',
        'requesterName',
        'requesterName',
        dataField,
        sortDirection
      ),
      this.columnJson(
        'created-at',
        'submissionDate',
        'createdAt',
        dataField,
        sortDirection
      ),
      this.columnJson(
        'updated-at',
        'updatedAt',
        'updatedAt',
        dataField,
        sortDirection
      ),
      this.columnJson(
        'processing-duration',
        'processingDuration',
        'processingDuration',
        dataField,
        sortDirection
      ),
    ];
    const statusColumn = [
      this.columnJson(
        'status',
        'status',
        'status',
        dataField,
        sortDirection
      )
    ];
    const actionColumn = [
      this.columnJson(
        'additional-treatment',
        'additionalTreatment',
        'action',
        dataField,
        sortDirection
      )
    ];
    let columns: any;
    if (RequestListPages.PENDING === type) {
      columns = [
        ...actionColumn,
        ...commonColumns
      ];
    } else {
      columns = [
        ...statusColumn,
        ...commonColumns
      ];}
    this.setState({
      columns: columns,
    });
  }

  columnJson(
    classes: string,
    dataField: string,
    text: string,
    sortDataFiled: string,
    sortDirection: string
  ) {
    const { t } = this.props;
    return {
      classes: classes,
      dataField: dataField,
      text: t(text),
      title: function callback(cell:any, row:any) {
        return row[dataField+'Full'];
      },
      headerClasses:
        sortDataFiled === dataField
          ? sortDirection
          : 'sz-icon-line move-expand-vertical',
      headerEvents: {
        onClick: this.sortByColumn.bind(this),
      },
    };
  }

  sortByColumn(e: any, column: any) {
    const { userRequests, type, sortBy, allFilters, myFilter, pendingFilter, assignedFilter, setSortBy } = this.props;
    const { direction } = sortBy[type];
    const sortDirection = direction && direction === 'desc' ? 'asc' : 'desc';
    this.setState({
      sort: {
        direction: sortDirection,
        field: this.sortKeyValues[column.dataField],
      },
    });
    const classSortDirection =
      sortDirection === 'desc'
        ? 'sz-icon-line arrow-down-1'
        : 'sz-icon-line arrow-up-1';

    this.setColumn(type, column.dataField, classSortDirection);
    const pagination = this.getPaginationData(userRequests[type]);
    const currentPage = parseInt(pagination['x-pagination-current-page']);

    switch (type) {
      case RequestListPages.ALL:
        this.getFilteredRequests(
          allFilters,
          currentPage,
          this.sortKeyValues[column.dataField],
          sortDirection,
          RequestListPages.ITEMS_PER_PAGE
        );
        setSortBy({
          all: {
            direction: sortDirection,
            field: this.sortKeyValues[column.dataField]
          }
        });
        break;
      case RequestListPages.MY:
        this.getFilteredRequests(
          myFilter,
          currentPage,
          this.sortKeyValues[column.dataField],
          sortDirection,
          RequestListPages.ITEMS_PER_PAGE
        );
        this.props.setSortBy({
          my: {
            direction: sortDirection,
            field: this.sortKeyValues[column.dataField]
          }
        });
        break;
      case RequestListPages.PENDING:
        this.getFilteredRequests(
          pendingFilter,
          currentPage,
          this.sortKeyValues[column.dataField],
          sortDirection,
          RequestListPages.ITEMS_PER_PAGE
        );
        setSortBy({
          pending: {
            direction: sortDirection,
            field: this.sortKeyValues[column.dataField]
          }
        });
        break;
      case RequestListPages.ASSIGNED:
        this.getFilteredRequests(
          assignedFilter,
          currentPage,
          this.sortKeyValues[column.dataField],
          sortDirection,
          RequestListPages.ITEMS_PER_PAGE
        );
        setSortBy({
          assigned: {
            direction: sortDirection,
            field: this.sortKeyValues[column.dataField]
          }
        });
        break;
    }

    return true;
  }

  rowClassNameFormat(row: any) {
    const { type } = this.props;

    if (RequestListPages.PENDING === type) {
      return `request-row-${row.additionalTreatmentId.replace('_', '-')}`;
    }
    return `request-row-${row.statusId.replace('_', '-')}`;
  }

  getRowEvent(): any {
    const { history } = this.props;
    return {
      onClick: (e: any, row: any) => {
        if (row.statusId === Status.STATUS_DRAFT) {
          userRequestsService.editRequest(row.requestId, history);
        } else {
          const url = AppUrls.VIEW_REQUEST.replace(':requestId', row.requestId);
          history.push(url);
        }
      },
    };
  }

  setRequestData(type: string) {
    const { allFilters, myFilters, pendingFilters, assignedFilters, pages, sortBy, user } = this.props;
    switch (type) {
      case RequestListPages.ALL:
        return userRequestsService.getFilteredRequestVisibleToUser(
          pages.all,
          allFilters,
          sortBy.all.field,
          sortBy.all.direction,
          RequestListPages.ITEMS_PER_PAGE
        );
      case RequestListPages.MY:
        return userRequestsService.getFilteredMyUserRequest(
          pages.my,
          myFilters,
          sortBy.my.field,
          sortBy.my.direction,
          RequestListPages.ITEMS_PER_PAGE
        );
      case RequestListPages.PENDING:
        return userRequestsService.getFilteredPendingUserRequest(
          pages.pending,
          pendingFilters,
          sortBy.pending.field,
          sortBy.pending.direction,
          RequestListPages.ITEMS_PER_PAGE
        );
      case RequestListPages.ASSIGNED:
        let assignedFiltersWithUser = {
          ...assignedFilters,
          supervisorUserId: user.id
        }
        return userRequestsService.getFilteredAssignedUserRequest(
          pages.assigned,
          assignedFiltersWithUser,
          sortBy.assigned.field,
          sortBy.assigned.direction,
          RequestListPages.ITEMS_PER_PAGE
        );
    }
  }

  setQuery() {
    const { allFilters, myFilters, pendingFilters, assignedFilters, type } = this.props;
    let query: any;
    switch (type) {
      case RequestListPages.ALL:
        query = allFilters.query;
        break;
      case RequestListPages.MY:
        query = myFilters.query;
        break;
      case RequestListPages.PENDING:
        query = pendingFilters.query;
        break;
      case RequestListPages.ASSIGNED:
        query = assignedFilters.query;
        break;
    }
    if (query !== undefined && query.length > 0) {
      this.setState({
        searchQuery: query,
        appliedSearchQuery: query,
      });
    }
  }

  setIndicators(type: string, filter = {}) {
    const { user } = this.props;
    switch (type) {
      case RequestListPages.ALL:
        return userRequestsService.getAllIndicators({
          ...filter,
          query: this.state.searchQuery,
        });
      case RequestListPages.MY:
        return userRequestsService.getMyIndicators({
          ...filter,
          query: this.state.searchQuery,
        });
      case RequestListPages.PENDING:
        return userRequestsService.getPendingIndicators({
          ...filter,
          query: this.state.searchQuery,
        });
      case RequestListPages.ASSIGNED:
        return userRequestsService.getAssignedIndicators({
          ...filter,
          query: this.state.searchQuery,
          supervisorUserId: user.id
        });
    }
  }

  truncateString = (text: string, length: number) => {
    if (!text || text.length <= length) {
      return text
    }
    return text.slice(0, length) + '...'
  }


  formatRequests(userRequests: any): any {
    const { t } = this.props;
    let formattedRequests: any = [];
    const currentDate = new Date();
    if (userRequests.items) {
      userRequests.items.map((item: any) => {
        const { number, street, city } = item.address;
        const { desiredDate } = item.data;
        const rawTreatments = userRequestsService.getRawTreatments(this.props?.mdmData?.categories, item?.data);

        let cleanAddress = `${street}, ${city}`;

        if (number) {
          cleanAddress = `${number} ${cleanAddress}`;
        }

        const {delayObject, dateToShow} = UserRequestsService.processDelayDetails(item, null);
        const delayTranslateRef =
          delayObject.format === DelayFormats.HOURS
            ? 'processingDurationValue_hours'
            : 'processingDurationValue';
        const request = {
          address: this.truncateString(cleanAddress, 25),
          addressFull: cleanAddress.length > 25 ? cleanAddress : '',
          additionalTreatment: item.data.additionnalTreatment.label,
          additionalTreatmentId: item.data.additionnalTreatment.id,
          subCategory: this.truncateString(item.data.requestSubCategory.label, 16),
          subCategoryFull: item.data.requestSubCategory.label && item.data.requestSubCategory.label.length > 16 ? item.data.requestSubCategory.label : '',
          createdAt: DateService.formatDate(item.createdAt),
          updatedAt: DateService.formatDate(item.updatedAt),
          id: item.incrementalId,
          processingDuration: item.data.processingDuration
            ? t(delayTranslateRef, {
                count: delayObject.value,
                date: DateService.formatDate(dateToShow),
              })
            : '',
          requesterName: this.truncateString(item.data.requesterName, 16),
          requesterNameFull: item.data.requesterName.length > 16 ? item.data.requesterName : '',
          requestId: item.id,
          status: t(item.currentStatus.status.id),
          statusId: item.currentStatus.status.id,
          submissionDate: DateService.formatDate(item.data.submissionDate),
        };
        formattedRequests.push(request);
        return formattedRequests;
      });
    }
    return formattedRequests;
  }

  getPaginationData(userRequests: any): any {
    return userRequests.pagination ? userRequests.pagination : {};
  }

  onPaginationHandle(number: number): any {
    const { type, allFilters, myFilters, pendingFilters, assignedFilters, sortBy, setCurrentPage } = this.props;
    switch (type) {
      case RequestListPages.ALL:
        this.getFilteredRequests(
          allFilters,
          number,
          sortBy.all.field,
          sortBy.all.direction,
          RequestListPages.ITEMS_PER_PAGE
        );
        setCurrentPage({all: number});
        break;
      case RequestListPages.MY:
        this.getFilteredRequests(
          myFilters,
          number,
          sortBy.my.field,
          sortBy.my.direction,
          RequestListPages.ITEMS_PER_PAGE
        );
        setCurrentPage({my: number});
        break;
      case RequestListPages.PENDING:
        this.getFilteredRequests(
          pendingFilters,
          number,
          sortBy.pending.field,
          sortBy.pending.direction,
          RequestListPages.ITEMS_PER_PAGE
        );
        setCurrentPage({pending: number});
        break;
      case RequestListPages.ASSIGNED:
        this.getFilteredRequests(
          assignedFilters,
          number,
          sortBy.assigned.field,
          sortBy.assigned.direction,
          RequestListPages.ITEMS_PER_PAGE
        );
        setCurrentPage({assigned: number});
        break;
    }
  }

  onChangeHandle = (e: any): any => {
    const value = e.target.value;
    this.setState({ searchQuery: value });
  };

  toggleFilters = () => {
    const { showFilters } = this.state;
    this.setState({ showFilters: !showFilters });
  };

  onSubmitHandle = (e: any): any => {
    e.preventDefault();
  };

  onSearchHandle = (): any => {
    const { type, allFilters, myFilters, pendingFilters, assignedFilters, setCurrentPage } = this.props;
    const { searchQuery } = this.state;
    let filter = {
      query: '',
    };

    if (searchQuery.length > 0) {
      filter.query = searchQuery;
    }
    this.setState({
      appliedSearchQuery: searchQuery,
    });
    // Keeping the current page when searching doesn't make much sense. Return to one.
    switch (type) {
      case RequestListPages.ALL:
        userRequestsService.getFilteredRequestVisibleToUser(1, {
          ...filter,
          ...allFilters,
          query: searchQuery,
        });
        this.refreshIndicatorsHandle(type, allFilters);
        break;
      case RequestListPages.MY:
        userRequestsService.getFilteredMyUserRequest(1, {
          ...filter,
          ...myFilters,
          query: searchQuery,
        });
        this.refreshIndicatorsHandle(type, myFilters);
        setCurrentPage({my: 1});
        break;
      case RequestListPages.PENDING:
        userRequestsService.getFilteredPendingUserRequest(1, {
          ...filter,
          ...pendingFilters,
          query: searchQuery,
        });
        this.refreshIndicatorsHandle(type, pendingFilters);
        setCurrentPage({pending: 1});
        break;
      case RequestListPages.ASSIGNED:
        userRequestsService.getFilteredPendingUserRequest(1, {
          ...filter,
          ...assignedFilters,
          query: searchQuery,
        });
        this.refreshIndicatorsHandle(type, assignedFilters);
        setCurrentPage({assigned: 1});
        break;
    }
  };

  renderPagination() {
    const { userRequests, type } = this.props;
    const pagination = this.getPaginationData(userRequests[type]);
    const currentPage = parseInt(pagination['x-pagination-current-page']);

    return (
      <SzPagination
        totalItemsCount={+pagination['x-pagination-total-count'] || 0}
        activePage={currentPage}
        onChange={this.onPaginationHandle.bind(this)}
        itemsCountPerPage={
          +pagination['x-pagination-per-page'] ||
          RequestListPages.ITEMS_PER_PAGE
        }
        pageRangeDisplayed={5} // RequestListPages.PAGE_RANGE
      />
    );
  }

  refreshIndicatorsHandle = (type: string, filter: any = {}) => {
    this.setIndicators(type, filter);
  };

  getFilteredRequests(
    filter: any,
    page = 1,
    sortField = 'submissionDate',
    direction = 'desc',
    itemsPerPage?: number
  ) {
    const { type, user } = this.props;
    const { searchQuery } = this.state;
    this.setState({ filter: filter });
    if (searchQuery.length > 0) {
      this.setState({
        appliedSearchQuery: searchQuery,
      });
    }
    switch (type) {
      case RequestListPages.ALL:
        this.setState({ filter: { ...filter, all: filter } });
        userRequestsService.getFilteredRequestVisibleToUser(
          page,
          {
            ...filter,
            query: searchQuery,
          },
          sortField,
          direction,
          itemsPerPage
        );
        break;
      case RequestListPages.MY:
        this.setState({ filter: { ...filter, my: filter } });
        userRequestsService.getFilteredMyUserRequest(
          page,
          {
            ...filter,
            query: searchQuery,
          },
          sortField,
          direction,
          itemsPerPage
        );
        break;
      case RequestListPages.PENDING:
        this.setState({ filter: { ...filter, pending: filter } });
        userRequestsService.getFilteredPendingUserRequest(
          page,
          {
            ...filter,
            query: searchQuery,
          },
          sortField,
          direction,
          itemsPerPage
        );
        break;
      case RequestListPages.ASSIGNED:
        this.setState({ filter: { ...filter, assigned: filter } });
        userRequestsService.getFilteredAssignedUserRequest(
          page,
          {
            ...filter,
            query: searchQuery,
            supervisorUserId: user.id
          },
          sortField,
          direction,
          itemsPerPage
        );
        break;
    }
  }

  downloadList = () => {
    const { userRequests, type, allFilters, myFilters, pendingFilters, assignedFilters, user } = this.props;
    const { searchQuery } = this.state;
    let filter: any;
    switch (type) {
      case RequestListPages.ALL:
        filter = allFilters;
        break;
      case RequestListPages.MY:
        filter = myFilters;
        break;
      case RequestListPages.PENDING:
        filter = pendingFilters;
        break;
      case RequestListPages.ASSIGNED:
        filter = assignedFilters;
        break;
    }
    const paginationData = this.getPaginationData(userRequests[type]);
    const currentPage = paginationData['x-pagination-current-page'];

    const windowUrl = window.URL;
    const link = document.createElement('a');
    link.setAttribute('download', 'demandes.csv');

    switch (type) {
      case RequestListPages.ALL:
        userRequestsService
          .downloadFilteredRequestVisibleToUser(
            {
              ...filter,
              query: searchQuery,
            },
            currentPage
          )
          .then((response: any) => {
            link.href = windowUrl.createObjectURL(new Blob([response.data]));
            document.body.appendChild(link);
            link.click();
          });
        break;
      case RequestListPages.MY:
        userRequestsService
          .downloadFilteredMyUserRequest(
            {
              filter,
              query: searchQuery,
            },
            currentPage
          )
          .then((response: any) => {
            link.href = windowUrl.createObjectURL(new Blob([response.data]));
            document.body.appendChild(link);
            link.click();
          });
        break;
      case RequestListPages.PENDING:
        userRequestsService
          .downloadFilteredPendingUserRequest(
            {
              filter,
              query: searchQuery,
            },
            currentPage
          )
          .then((response: any) => {
            link.href = windowUrl.createObjectURL(new Blob([response.data]));
            document.body.appendChild(link);
            link.click();
          });
        break;
      case RequestListPages.ASSIGNED:
        userRequestsService
          .downloadFilteredAssignedUserRequest(
            {
              filter,
              query: searchQuery,
              supervisorUserId: user.id
            },
            currentPage
          )
          .then((response: any) => {
            link.href = windowUrl.createObjectURL(new Blob([response.data]));
            document.body.appendChild(link);
            link.click();
          });
        break;
    }
  };

  renderDownload() {
    const { t } = this.props;

    return (
      <SzButton
        className="list-export sz-btn-tertiary float-right"
        icon={'data-file-bars-info'}
        variant="secondary"
        onClick={this.downloadList}
      >
        {t('export_data')}
      </SzButton>
    );
  }

  resetSearchQuery = () => {
    this.setState({ searchQuery: '' }, () => {
      this.onSearchHandle();
    });
  };

  render() {
    const {
      userRequests,
      t,
      type,
      toggleListToast,
      mdmData,
      allIndicators,
      myIndicators,
      pendingIndicators,
      assignedIndicators,
      allFilters,
      myFilters,
      pendingFilters,
      assignedFilters,
    } = this.props;
    const { showFilters, searchQuery, appliedSearchQuery } = this.state;
    const paginationData = this.getPaginationData(userRequests[type]);
    const data = this.formatRequests(userRequests[type]);
    const defaultSorted:any = [
      {
        dataField: 'name',
        order: 'desc',
      },
    ];
    return (
      <>
        <div className="row">
          <div className="col-5 offset-4">
            <Toast
              onClose={() => toggleListToast()}
              show={userRequests.showToast.show}
              delay={this.DELAYS_TOAST}
              autohide
            >
              <Toast.Header>
                <strong className="mr-auto">
                  <span className="sz-icon-line information-circle mr-2" />
                  {t('information')}
                </strong>
              </Toast.Header>
              <Toast.Body>
                {userRequests.showToast.isDraft
                  ? t('the_draft_has_been_saved')
                  : t('the_request_has_been_saved')}
              </Toast.Body>
            </Toast>
          </div>
        </div>
        <div className="row position-relative">
          <div className="col-6">
            <form onSubmit={this.onSubmitHandle}>
              <div className="form-row">
                <div className="col-10">
                  <SzInput
                    onChange={this.onChangeHandle}
                    placeholder={t('search')}
                    value={searchQuery}
                  />
                </div>
                <div className="col-1">
                  <SzButton
                    className="filter-search-submit pl-4"
                    icon="search"
                    onClick={this.onSearchHandle}
                    type="submit"
                  >
                    <span className="text-hide">{t('ok')}</span>
                  </SzButton>
                </div>
                <div className="col-1 position-relative">
                  {showFilters && (
                    <div className="box-shadow w-100 h-100 position-absolute ptn-5" />
                  )}
                  <SzButton
                    className="request-filter mx-2 z-base pl-4"
                    icon="filter-1"
                    onClick={this.toggleFilters}
                    variant="secondary"
                  >
                    <span className="text-hide">{t('filter')}</span>
                  </SzButton>
                </div>
              </div>
            </form>
          </div>
          <FilterList
            searchQuery={appliedSearchQuery}
            resetSearchQuery={this.resetSearchQuery}
            toggleFilters={this.toggleFilters.bind(this)}
            data={mdmData}
            type={type}
            showFilters={showFilters}
            refreshIndicatorsHandle={this.refreshIndicatorsHandle}
            getFilteredRequests={this.getFilteredRequests.bind(this)}
            allIndicators={allIndicators}
            myIndicators={myIndicators}
            pendingIndicators={pendingIndicators}
            assignedIndicators={assignedIndicators}
            allFilters={allFilters}
            myFilters={myFilters}
            pendingFilters={pendingFilters}
            assignedFilters={assignedFilters}
            triggerAllFilters={triggerAllFilters}
            triggerMyFilters={triggerMyFilters}
            triggerPendingFilters={triggerPendingFilters}
            triggerAssignedFilters={triggerAssignedFilters}
            setCurrentPage={setCurrentPage}
          />
        </div>
        <div className="row ">
          <div className="col-8">
            <SzTypographie variant="text" className="list-result">
              {t('number_request_on_all', {
                number_show: data.length,
                number_all: paginationData['x-pagination-total-count'],
              })}
            </SzTypographie>
          </div>
          <div className="col-4">{this.renderDownload()}</div>
        </div>
        <div className="row mt-3">
          {this.state.columns.length && (
            <div className="col list-table">
              <SzTable
                rowClasses={this.rowClassNameFormat.bind(this)}
                columns={this.state.columns}
                rowEvents={this.getRowEvent()}
                data={data}
                keyField={'requestId'}
                defaultSorted={defaultSorted}
              />
              <div className="row">
                <div className="col offset-5">{this.renderPagination()}</div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  userRequests: state.userRequests,
  mdmData: state.userRequests.mdmData,
  myIndicators: state.userRequests.myIndicators,
  allIndicators: state.userRequests.allIndicators,
  pendingIndicators: state.userRequests.pendingIndicators,
  assignedIndicators: state.userRequests.assignedIndicators,
  allFilters: state.userRequests.allFilters,
  myFilters: state.userRequests.myFilters,
  pendingFilters: state.userRequests.pendingFilters,
  assignedFilters: state.userRequests.assignedFilters,
  pages: state.userRequests.pages,
  sortBy: state.userRequests.sortBy,
  user: state.user.info,
});

const mapDispatchToProps = {
  toggleListToast: toggleListToast,
  triggerAllFilters: triggerAllFilters,
  triggerMyFilters: triggerMyFilters,
  triggerPendingFilters: triggerPendingFilters,
  triggerAssignedFilters: triggerAssignedFilters,
  setCurrentPage: setCurrentPage,
  setSortBy: setSortBy,
};
export default withRouter<IUserRequestList, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(UserRequestsListManager))
);
