import React from 'react';
import { MatomoProvider, useMatomo } from '@datapunt/matomo-tracker-react';
import { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { matmotInstance } from './matomoConfig';

type Props = {
	children: JSX.Element | JSX.Element[]
}

const MatomoLayout = ({ children } : Props) => {
	return (
		<MatomoProvider value={matmotInstance}>
			<MatomoEventSender />
			{children}
		</MatomoProvider>
	);
}

function MatomoEventSender() {
	/** @typescript-eslint/no-unused-vars */
	const { trackPageView, trackEvent } = useMatomo();
	const location = useLocation()

	useEffect(() => {
		trackPageView({});
	}, [location, trackPageView]);

	return <Fragment />
}

export default MatomoLayout;