import React from 'react';
import { useTranslation } from 'react-i18next';
import { SzButton, SzTooltip } from '@suezenv/react-theme-components';
import './pdfContainer.scss';

export default (props: any) => {
  const bodyRef = React.createRef<any>();
  const createPdf = () => props.createPdf(bodyRef.current);
  const { t } = useTranslation();

  return (
    <section className="pdf-container">
      <section className="pdf-toolbar z-pdf-export">
        <div className="row justify-content-end">
          <div className="col p-0">
            <SzTooltip className="title-tooltip" content={t('export_data')}>
              <SzButton
                className="export w-100"
                onClick={createPdf}
                icon="office-file-pdf-1"
                variant="tertiary"
              />
            </SzTooltip>
          </div>
        </div>
      </section>
      <section ref={bodyRef} className="pdf-body">
        {props.children}
      </section>
    </section>
  );
};
