import React from 'react';
import { SzButton, SzIcon } from '@suezenv/react-theme-components';
import './filterList.scss';

const SelectedFilter = (props: any) => {
  return (
    <SzButton
      className="d-md-inline-flex filter-tag mr-2"
      onClick={() => props.deleteHandler(props.name)}
      variant="secondary"
    >
      {props.label}
      <SzIcon icon="remove" variant="line" className="ml-2" />
    </SzButton>
  );
};

export default SelectedFilter;
