import React, {ReactNode, useState} from "react";
import {NavLink} from "react-router-dom";
import {SzSideMenu, SzAlert, SzIcon, SzModal, SzTypographie} from "@suezenv/react-theme-components";
import {useTranslation} from 'react-i18next';
import {AppUrls} from "../../constants";
import Header from "../elements/header";
import Loading from "../elements/loading";
import LoadingPage from "../../pages/loadingPage";
import "./navigation.scss";
import {config} from "../../config";
import UserService from "../../services/UserService";
import NavUrgentRequest from "../elements/NavUrgentRequest";
import NotificationSettings from "../forms/notification/notificationSettings";
import FileIcon from "../../assets/icon/common-file-award.svg";

interface INavigationProps {
    children: ReactNode;
    loading: Boolean;
    logged: Boolean;
    isError: boolean;
    isErrorIntervention: boolean;
    user: any;
}

const Navigation: React.FunctionComponent<INavigationProps> = (props) => {
    const {children, user} = props;
    const [state, setState] = useState({
        isNavOpen: false,
        showUrgentRequest: false,
        modalNotifActive: false,
        modalRgpdActive: false,
    })
    const {t} = useTranslation();

    const closeMenuHandler = () => {
        setState({...state, showUrgentRequest: false})
    }
    const setModalNotifActive = (active: boolean) => {
        setState({...state, modalNotifActive: active})
    }

    const setModalRgpdActive = (active: boolean) => {
        setState({...state, modalRgpdActive: active})
    }

    const toggleModalRgpd = () => {
        setState({...state, modalRgpdActive: !state.modalRgpdActive})
    }

    const downloadUserManual = () => {
        window.open("/files/PCE-Manuel-utilisateur.pdf");
    }

    const toggleMenuHandler = () => {
        setState({
            ...state,
            showUrgentRequest: !state.showUrgentRequest,
            isNavOpen: state.isNavOpen
        })
    }

    const menuTop = [
        {
            icon: "task-finger-show",
            link: AppUrls.REQUESTS,
            title: t("MENU_REQUESTS"),
        },
        {
            icon: "maps-pin-1",
            link: AppUrls.CARTOGRAPHY,
            title: t("MENU_CARTOGRAPHY"),
        }
    ];
    const menuBottom = [
        {
            icon: <SzIcon icon="phone-actions-voice-mail" variant="line"/>,
            title: t('urgent_request'),
            active: state.showUrgentRequest,
            handleClick: toggleMenuHandler
        },
        {
            icon: <img
              src={FileIcon}
              width="30px"
              className="file-icon"
              alt="User manual"
            />,
            title: t('user_manual'),
            active: false,
            handleClick: downloadUserManual
        },
        {
            icon: <SzIcon icon="information-circle" variant="line"/>,
            title: t('rgpd_menu'),
            active: state.modalRgpdActive,
            handleClick: toggleModalRgpd
        }
    ];

    const SzSideMenuClassName = 'flex-shrink-0' + (!state.isNavOpen ? ' closed' : '');

    return (
        props.logged || props.isError ? (<>
            <Header isError={props.isError}
                    handleOnClick={() => setState({...state, isNavOpen: !state.isNavOpen})}
                    setModalNotifActive={setModalNotifActive}
                    user={user}
            />
            <div className="d-flex flex-row flex-fill main-wrapper">
                {!props.isError &&
                <SzSideMenu className={SzSideMenuClassName} isOpen={state.isNavOpen}>
                    <ul className="sz-aside__list list-unstyled m-0">
                        {menuTop.map((item, key) => (
                            <li key={key} className="d-flex align-items-center">
                                <NavLink activeClassName="active"
                                         className="sz-aside__item d-flex align-items-center font-weight-bold text-uppercase text-decoration-none py-2"
                                         exact={true}
                                         to={item.link}
                                         isActive={(match, location) => {
                                             const pathNameList = location.pathname.split('/');
                                             const linkList = item.link.split('/');
                                             return linkList[1] === pathNameList[1];
                                         }}
                                >
                                    <span className={`sz-icon-line ${item.icon}`}/>
                                    <span className={"sz-aside__item--title"}>
                                        {item.title}
                                    </span>
                                </NavLink>
                            </li>
                        ))}
                    </ul>

                    {UserService.isUserAdmin(user.info) &&
                    <ul className="sz-aside__list list-unstyled m-0">
                        <li className="d-flex align-items-center">
                            <a className={'sz-aside__item d-flex align-items-center font-weight-bold text-uppercase text-decoration-none py-2'}
                               href={config.userAdminUrl}
                               target={'_blank'}
                               rel="noopener noreferrer"
                            >
                                <span className={'sz-icon-line cog'}/>
                                <span className={'sz-aside__item--title'}>
                                    {t('MENU_USER_ADMIN')}
                                </span>
                            </a>
                        </li>
                    </ul>
                    }

                    <ul className="sz-aside__list list-unstyled m-0 position-absolute-bottom">
                        {menuBottom.map(({title, active, icon, handleClick}, index) => (
                            <NavLink activeClassName="active"
                                     className="sz-aside__item d-flex align-items-center font-weight-bold text-uppercase text-decoration-none py-2 hover-black"
                                     key={index}
                                     isActive={() => {
                                         return active;
                                     }}
                                     to='#'
                                     onClick={handleClick}
                            >
                                {icon && icon}
                                <span className='sz-aside__item--title'>{title}</span>
                            </NavLink>
                        ))}
                    </ul>

                </SzSideMenu>
                }
                <main className="flex-grow-1 d-flex flex-column">
                    {props.loading && (<Loading/>)}
                    <div className="flex-fill position-relative no-scroll h-100">
                        <div className="container-fluid overflow-auto h-100">
                            {state.showUrgentRequest &&
                            <NavUrgentRequest closeMenuHandler={closeMenuHandler}/>
                            }
                            {props.isErrorIntervention &&
                            <SzAlert variant="danger">{t('ERROR_INTERVENTION')}</SzAlert>
                            }
                            {children}
                        </div>
                    </div>
                    <SzModal
                        className={"modal-centered"}
                        title={t("notification_setting")}
                        show={state.modalNotifActive}
                        size="xl"
                        backdrop={true}
                        centered={true}
                        handleClose={() => {
                            setModalNotifActive(false)
                        }}
                    >
                        <NotificationSettings userSettings={[]}/>
                    </SzModal>
                    <SzModal
                        className={"modal-centered"}
                        title={t("rgpd_title")}
                        show={state.modalRgpdActive}
                        size="xl"
                        backdrop={true}
                        centered={true}
                        handleClose={() => {
                            setModalRgpdActive(false)
                        }}
                    >
                        <SzTypographie>{t("rgpd_text_line1")}</SzTypographie>
                        <SzTypographie>{t("rgpd_text_line2")}</SzTypographie>
                        <SzTypographie>{t("rgpd_text_line3")}</SzTypographie>
                        <SzTypographie>{t("rgpd_text_line4")}</SzTypographie>
                    </SzModal>
                </main>
            </div>
        </>) : (
            <LoadingPage/>
        )
    );
};

export default Navigation;
