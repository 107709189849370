import React from 'react';
import { useTranslation } from 'react-i18next';
import "./indicators.scss";
import classnames from 'classnames';
import { SzTypographie } from "@suezenv/react-theme-components";

const Indicator = (props: any) => {
    const { t } = useTranslation()
    const { value, count, indicatorType } = props
    let label: any;

    switch (indicatorType) {
        case 'status':
            label = t(value).toLowerCase();
            if (-1 !== ['planned', 'done'].indexOf(value) && count > 1) {
                label = label +'s';
            }
            break;
        case 'additionalTreatment':
            label = t(value);
            break;
    }

    // button should not be clickable if count = 0
    const onClick = 0 === count ? () => {} : props.onClick

    const classesControl = classnames("box-shadow d-flex", {
        'disabled': 0 === count,
        'status-indicator': 'status' === indicatorType,
        'additionalTreatment-indicator': 'additionalTreatment' === indicatorType,
    });

    return (
        <div className={classesControl} onClick={() => onClick(value, indicatorType) }>
            <SzTypographie variant="text" className="m-0">
                <span className={`${indicatorType}-sticker ${indicatorType}-sticker-${value.replace('_', '-')}`}/>
                <strong>{count}</strong> {label}
            </SzTypographie>
        </div>
    )
};

export default Indicator;
