import { Constants, notificationPerimeters } from "../constants";


const userReducerDefaultState = {
    logged: false,
    info: {
        "msg": "",
        "id": null,
        "email": "",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    notification: {
        id: "",
        events: [],
        perimeter: notificationPerimeters.all,
        user_id: null,
    }
};

const userReducer = (state = userReducerDefaultState, action: any) => {
    switch (action.type) {
        case Constants.LOG_IN:
            return { ...state, logged: true, info: action.payload, notification: {...state.notification, user_id: action.payload.id}};
        case Constants.LOG_OUT:
            return { ...state, logged: false };
        case Constants.SET_NOTIFICATION_CONFIGURATION:
            return { ...state, notification: action.payload};
        default:
            return state;
    }
};


export default userReducer;