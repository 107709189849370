export enum Constants {
    ADD_ATTACHMENT_REQUEST = 'ADD_ATTACHMENT_REQUEST',
    AUTO_COMPLETE_ADDRESS = 'AUTO_COMPLETE_ADDRESS',
    COMPUTE_DELAY = 'COMPUTE_DELAY',
    COORDINATES_TO_ADDRESS = 'COORDINATES_TO_ADDRESS',
    DATA_LOADING = "DATA_LOADING",
    ERROR = 'ERROR',
    ERROR_INTERVENTION = "ERROR_INTERVENTION",
    GET_ANALYSE = "GET_ANALYSE",
    GET_STEPS = "GET_STEPS",
    LOG_IN = "login",
    LOG_OUT = "logout",
    NOTIFICATION_PERIMETER_LABEL = "perimeter",
    REQUEST_NATURE_CLAIM = 'claim',
    RESET_CURRENT_ATTACHMENT_REQUEST = 'RESET_CURRENT_ATTACHMENT_REQUEST',
    RESET_CURRENT_USER_REQUEST = 'RESET_CURRENT_USER_REQUEST',
    SEARCH = "search",
    SET_ALL_INDICATORS = 'SET_ALL_INDICATORS',
    SET_ALL_USER_REQUESTS = 'SET_ALL_USER_REQUESTS',
    SET_ASSIGNED_INDICATORS = 'SET_ASSIGNED_INDICATORS',
    SET_ASSIGNED_USER_REQUESTS = 'SET_ASSIGNED_USER_REQUESTS',
    SET_CATEGORIES_LIST = 'SET_CATEGORIES_LIST',
    SET_DATA_FORM = 'SET_DATA_FORM',
    SET_GEOSHAPES = 'SET_GEOSHAPES',
    SET_LIST_TAB = 'SET_LIST_TAB',
    SET_MDM_DATA = 'SET_MDM_DATA',
    SET_MY_INDICATORS = 'SET_MY_INDICATORS',
    SET_MY_USER_REQUESTS = 'SET_MY_USER_REQUESTS',
    SET_PENDING_INDICATORS = 'SET_PENDING_INDICATORS',
    SET_PENDING_USER_REQUESTS = 'SET_PENDING_USER_REQUESTS',
    SET_REQUEST = 'SET_REQUEST',
    SET_REQUEST_ACTIONS = 'SET_REQUEST_ACTIONS',
    SET_REQUEST_HISTORY = 'SET_REQUEST_HISTORY',
    SET_REQUEST_STATUS_LIST = 'SET_REQUEST_STATUS_LIST',
    SET_NOTIFICATION_CONFIGURATION = 'SET_NOTIFICATION_CONFIGURATION',
    SUGGEST = "suggest",
    TOGGLE_LIST_TOAST = 'TOGGLE_LIST_TOAST',
    TRIGGER_ALL_FILTERS = 'TRIGGER_ALL_FILTERS',
    TRIGGER_ASSIGNED_FILTERS = 'TRIGGER_ASSIGNED_FILTERS',
    TRIGGER_MY_FILTERS = 'TRIGGER_MY_FILTERS',
    TRIGGER_PENDING_FILTERS = 'TRIGGER_PENDING_FILTERS',
    SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
    SET_SORT_BY = 'SET_SORT_BY',
    UPDATE_CURRENT_INTERVENTIONS = 'UPDATE_CURRENT_INTERVENTIONS',
    UPDATE_CURRENT_USER_REQUEST = 'UPDATE_CURRENT_USER_REQUEST',
}

export enum RequestAttachmentType {
    LOCATION_PLAN = 'LOCATION_PLAN',
    OTHER_TYPE = 'OTHER'
}

export enum ERROR {
    SET = "ERROR.SET",
}

export enum Status {
    STATUS_DRAFT = 'draft',
    STATUS_TO_QUALIFY = 'to_qualify',
    STATUS_TO_SPECIFY = 'to_specify',
    STATUS_TO_PLAN = 'to_plan',
    STATUS_PLANNED = 'planned',
    STATUS_ONGOING = 'ongoing',
    STATUS_DONE = 'done',
    STATUS_CLOSED_REJECTED = 'closed_rejected',
    STATUS_CLOSED = 'closed',
    STATUS_CANCELED = 'canceled'
}

export enum Risks {
    RISK_HUMAN = 'risk_human',
    RISK_PROPERTY = 'risk_property',
    RISK_SERVICE = 'risk_service',
}

export enum Roles {
    ROLE_SUEZ = 'ROLE_SUEZ',
    ROLE_CLIENT = 'ROLE_CLIENT',
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_USER_ADMIN = 'ROLE_ADM-ATLAS-ADMINS'
}

export enum RequestListPages {
    ALL = 'all',
    MY = 'my',
    PENDING = 'pending',
    ASSIGNED = 'assigned',
    ITEMS_PER_PAGE = 20,
    PAGE_RANGE = 5
}

export enum MdmOptions {
    ORGANISATIONS = 'organisations',
    TOWN = 'towns',
    CLEANSINGS = 'cleansingTypes',
    OPERATIONS = 'operationTypes',
}

export enum RequestAttributes {
    CONTACT_TYPE_SUBSCRIBER = 'contactTypeSubscriber',
    CONTACT_TYPE_OFFICE = 'contactTypeOffice',
    CONTACT_TYPE_ORGANISATION = 'contactTypeOrganisation',
    SUBSCRIBER_TYPE = 'subscriber',
    OFFICE_TYPE = 'office',
    ORGANISATION_TYPE = 'organisation'
}

export enum HttpCode {
    OK = 200,
    CREATED = 201,
    NO_CONTENT = 204,
    NOT_FOUND = 404,
    ACCESS_DENIED = 401,
    OUPS = 500,
    UNSUPPORTED_MEDIA = 415
}

export enum AppUrls {
    CARTOGRAPHY = '/cartographie',
    CREATE_REQUEST = '/demandes/nouvelle-demande',
    HOME = '/',
    LOGOUT = '/logout',
    REQUESTS = '/demandes',
    VIEW_REQUEST = '/demandes/:requestId',
    ITV = '/intervention/G2-39-:interventionId'
}

export enum ErrorRoutes {
    NOT_FOUND = '/error/unknown',
    ACCESS_DENIED = '/error/access-denied',
    OUPS = '/error/oups',
    SSO_ERROR = '/error/sso',
    EXPIRED = '/error/expired'
}

export enum InterventionsIds {
    PLANNED = "3",
    DONE_4 = "4",
    DONE_5 = "5",
    ONGOING = "7"
}

export enum DelayFormats {
    "HOURS",
    "DAYS"
}

export enum DefaultSelectedItems {
    operationTypes = "other",
    cleansingTypes = "unknown"
}

export enum notificationPerimeters {
    'mine' = 'owner',
    'all' = 'all',
    'assign' = 'assign'
}

export enum notificationsEvents {
    'newComment' = "COMMENT_POSTED_EVENT",
    'newAttachment' = "DOCUMENT_ADDED_EVENT",
    'statusChange' = "STATUS_CHANGED_EVENT"
}

export const months = ["Toute l'année", "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
const start_year = 2019;
const end_year = (new Date()).getFullYear();
export const years = (new Array(end_year - start_year + 1)).fill(undefined).map((_, i) => (i + start_year) + '');
export const phoneRegExp = /^((0|\+33)[1-9][0-9]{8}){0,1}$/;
export const NOT_SET_VALUE = '__NOT_SET__';

export const MAP_URI = 'https://{s}.tile.osm.org/{z}/{x}/{y}.png';
export const MAP_AZURE_URI = 'https://atlas.microsoft.com/map/tile?subscription-key={subscriptionKey}&api-version=2.0&tilesetId={tilesetId}&zoom={z}&x={x}&y={y}&tileSize=256&language={language}&view={view}';
export const MAP_AZURE_KEY = 'drrPYMATrq0wlcx9qGmeieQtYRwyAjspN7mktcUtFUo';
export const requestAcceptedMimeTypes = ['application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/pdf', 'image/png', 'image/jpeg', 'image/gif', 'text/csv']