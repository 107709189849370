import { Constants } from "../constants";


const requesterReducerDefaultState = {
    dataForm: null
};

const requesterReducer = (state = requesterReducerDefaultState, action: any) => {
    switch (action.type) {
        case Constants.SET_DATA_FORM:
            return { ...state, dataForm: action.payload };
        default:
            return state;
    }
};


export default requesterReducer;