import { SzBox, SzButton, SzIcon, SzTypographie } from '@suezenv/react-theme-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { SzTimeLine } from '../elements/SzTimeLine';
import "./Block3.scss";
import { Status } from '../../constants';
import classnames from 'classnames';
import { DateService } from '../../services/dateService';
import VdMService from '../../services/VdmService';
import commentService from '../../services/commentService';

const NUMBER_CHAR_TO_SHOW_TIME_LINE = 10;
const TIME_LINE_STATUS = 'status';
const TIME_LINE_COMMENT = 'comment';
const TIME_LINE_DURATION = 'duration';
const TIME_LINE_INTERVENTION = 'intervention';
const ASTEO_FIRST_NAME = 'ASTEO';


export const Block3 = (props: any) => {
    const [state, setState] = useState({ showDetails: false });
    const [visibleComments, setVisibleComments]: any = useState([]);

    const { t } = useTranslation();
    const { currentRequestStatusList, currentUserRequest, currentRequestHistory, interventions, exportingPdf, downloadAttachmentHandle } = props;
    const sortBydate = (DateA: any, DateB: any) => {
        return DateService.stringToDate(DateA.createdAt).getTime() > DateService.stringToDate(DateB.createdAt).getTime() ? 1 : -1;
    };
    const sortById = (item1: any, item2: any) => {
        return item1.id >= item2.id ? 1 : -1;
    }
    useEffect(() => {
        setState({ ...state, showDetails: state.showDetails || exportingPdf })
    }, [exportingPdf])
    const truncateString = (text: string) => {
        if (!text || text.length <= NUMBER_CHAR_TO_SHOW_TIME_LINE) {
            return text
        }
        return text.slice(0, NUMBER_CHAR_TO_SHOW_TIME_LINE) + '...'
    };

    const getStatusItems = (statusList: any) => {
        let items: any = [];
        statusList = removeSuezComments(statusList)
        statusList.sort(sortBydate);
        statusList.map((statusItem: any) => {
            const isFirstItem = items.length === 0;
            const { id, status, createdAt, comment, applicationUser } = statusItem;
            if (status.id !== Status.STATUS_DRAFT) {
                const previousItem = items.slice(-1);
                const previousStatus = (previousItem[0]) ? previousItem[0].status : '';
                const isComment = (status.id === previousStatus) && comment;
                const itemClassName = isComment ? 'timeline-comment' : `timeline-${status.id.replace('_', '-')}`;
                if ((status.id !== previousStatus) || isComment) {
                    const commentTextTimeline = t('timeline_comment_text', { comment: truncateString(comment) });
                    const textTimeline = (isFirstItem) ? t('start_item_text') : (isComment) ? commentTextTimeline : t(status.id);
                    const { firstName, lastName } = applicationUser;
                    const detailComment = t('detail_timeline_comment', { name: `${firstName ? firstName : ''} ${lastName ? lastName : ''} ` });
                    let detailStatus = t('detail_timeline_status', { status: t(status.id) });

                    // close auto
                    if (status.id === Status.STATUS_CLOSED && applicationUser.firstName === ASTEO_FIRST_NAME && applicationUser.lastName === '') {
                        detailStatus = t('auto_close_by', { firstName: applicationUser.firstName });
                    } else if (status.id === Status.STATUS_CLOSED) {
                        detailStatus = `${detailStatus} ${t('close_by', {
                            firstName: applicationUser.firstName,
                            lastName: applicationUser.lastName
                        })}`;
                    }
                    const detail = isComment ? detailComment : detailStatus;

                    /*
                    ES6 spread merge different from Chrome & FF,
                    indexes result of [...itemsStatus, ...itemsHistory] is different from expected because of createdAt field
                     */
                    const firstItemCreatedAt = moment(createdAt)
                        .add(1, 's')
                        .format('YYYY-MM-DDTHH:mm:ssZ')
                        .toString();

                    items.push({
                        className: (isFirstItem) ? 'timeline-start' : itemClassName,
                        status: status.id,
                        id: id,
                        detail: detail,
                        text: textTimeline,
                        createdAt: isFirstItem ? firstItemCreatedAt : createdAt,
                        rawComment: comment,
                        type: isComment ? TIME_LINE_COMMENT : TIME_LINE_STATUS,
                        showInTimeline: !isComment
                    });
                }
            }
            return items;
        });

        return items;
    };

    const getRequestHistory = (historyList: any) => {
        let items: any = [];
        historyList.map((item: any) => {
            const { applicationUser, newValue } = item;
            const { firstName, lastName } = applicationUser;
            let historyTimelineItem = {};
            if (item.fieldName === 'processingDuration') {
                const textDuration1 = t(`timeline_processing_duration_text_1`);
                historyTimelineItem = {
                    className: `timeline-processing-duration`,
                    text: textDuration1,
                    type: TIME_LINE_DURATION,
                    status: null,
                    createdAt: item.createdAt,
                    showInTimeline: false,
                    detail: t('detail_timeline_duration', {
                        name: `${firstName ? firstName : ''} ${lastName ? lastName : ''}`,
                        date: DateService.formatDate(newValue)
                    })
                };
                items.push(historyTimelineItem);
            }
            return historyTimelineItem;
        });
        return items;
    };

    const getRequestAttachmentHistory = () => {
        const { currentUserRequest } = props;

        let items: any = []

        if (undefined === currentUserRequest.attachments) {
            return items
        }

        currentUserRequest.attachments.map((item: any) => {
            const text = t('attachment_added', { name: item.file.originalFileName })
            const itemTimeLine = {
                className: 'timeline-attachment',
                status: null,
                detail: text,
                text: text,
                file: {
                    ...item.file,
                    name: item.file.originalFileName
                },
                createdAt: DateService.fromYMDHMS(item.createdAt.date),
                type: TIME_LINE_COMMENT,
                showInTimeline: false
            }
            items.push(itemTimeLine);

            return itemTimeLine;
        })

        return items
    }

    // clean comment after change processing duration
    const cleanCommentDelay = (items: any) => {
        let cleanItems: any = [];
        let firstAddDelayShown = false;

        items.forEach((item: any, index: number) => {
            // Always push first item
            if (index === 0) {
                cleanItems.push(item);
                return true;
            }

            // The first processing duration history entry is automatic, don't show it.
            if ('timeline-processing-duration' === item.className && !firstAddDelayShown) {
                firstAddDelayShown = true

                return false
            }

            const previousItem = items[index - 1];
            const previousCleanItem = cleanItems.slice(-1)[0];
            const lastCleanItemIsDuration = previousCleanItem && previousCleanItem.type === TIME_LINE_DURATION;
            const lastItemIsDuration = previousItem && previousItem.type === TIME_LINE_DURATION;

            if (lastCleanItemIsDuration && lastItemIsDuration && item.type === TIME_LINE_COMMENT) {
                return false;
            }

            cleanItems.push(item);
        });
        return cleanItems;
    }

    const getRequestInterventions = (interventions: any) => {
        let items: any = [];

        // nothing to show === nothing to show
        interventions.map((item: any) => {
            const { datedebutprevu, datemodification, libelletypeinterventionrealise, referenceg2 } = item;
            const usableStartDate = datedebutprevu ? datedebutprevu : datemodification;
            const statusId = VdMService.getStatus(item.statut)
            const statusLabel = t(statusId)

            let interventionTimelineItem = {
                className: `timeline-${statusId.replace('_', '-')}`,
                text: libelletypeinterventionrealise,
                type: TIME_LINE_INTERVENTION,
                status: statusId,
                createdAt: usableStartDate,
                showInTimeline: false,
                detail: t('detail_timeline_intervention', {
                    id: referenceg2,
                    statusLabel: statusLabel,
                    date: DateService.formatDate(usableStartDate)
                })
            };
            items.push(interventionTimelineItem);
            return interventionTimelineItem;
        });

        return items;
    }
    const formattedItems = (statusList: any, historyList: any, interventions: any) => {
        let items: any = [];
        if (statusList.length === 0) {
            return items;
        }
        const itemsStatus = getStatusItems(statusList);
        const attachmentHistory = getRequestAttachmentHistory();
        const itemsHistory = getRequestHistory(historyList);
        const itemsIntervention = getRequestInterventions(interventions);

        items = [...attachmentHistory, ...itemsStatus, ...itemsHistory, ...itemsIntervention];
        items.sort(sortBydate);

        // check  if last item is close status
        const lastItem = items[items.length - 1];
        if (lastItem.status === Status.STATUS_CLOSED) {

            lastItem.className = 'timeline-end';
            lastItem.showInTimeline = true;
        } else {
            const { submissionDate } = currentUserRequest.data;
            items.push({
                className: 'timeline-end',
                text: '',
                status: null,
                createdAt: submissionDate ? DateService.fromYMDHMS(submissionDate) : '',
                showInTimeline: true,
                detail: null,
                type: TIME_LINE_STATUS
            });
        }

        items = cleanCommentDelay(items);
        items = cleanStartTimeLine(items);
        return removeDelayChange(items);
    };
    const getFirstTimeLine = (items: any): any => {
        for (let i = 0; i < items.length; i += 1) {
            if (items[i].status === Status.STATUS_TO_QUALIFY) {
                return i;
            }
        }
        return 0;
    }
    const cleanStartTimeLine = (items: any) => {
        const firstItemIndex = getFirstTimeLine(items);

        if (firstItemIndex > 0) {
            const validItems: any = [
                items[firstItemIndex], // request created
                ...items.slice(firstItemIndex + 1) // whatever is left
            ]

            return validItems;
        }
        return items;
    }


    const removeDelayChange = (items: any) => {
        return items.filter((item: any, index: any) => {
            if (item.type === 'duration') {
                const previousStatus = getPreviousStatus(items, index)
                if (previousStatus && (previousStatus.status === 'to_specify' || previousStatus.status === 'to_qualify')) {
                    return false
                }
            }
            return true
        })
    }
    const removeSuezComments = (items: any) => {
        return items.filter((item: any, index: any) => !commentService.isSuez(item.commentRole))
    }
    const getPreviousStatus = (items: any, index: any) => {
        const edited = items.slice(0, index)
        for (let i = edited.length; i >= 0; i--) {
            const item = items[i]
            if (item.type === 'status') {
                return item
            }
        }
    }

    const toggleCommentVisibility = (index: any) => {
        let updatedList = [...visibleComments]

        if (visibleComments.includes(index)) {
            // if the index is already in the visible list, remove it.
            updatedList = visibleComments.filter((commentIndex: any) => {
                return commentIndex !== index
            })
        } else {
            // otherwise, add it.
            updatedList.push(index)
        }

        // persist
        setVisibleComments(updatedList)
    }

    const renderLine = (item: any, index: number) => {
        const itemClassName = classnames('d-flex timeline-item', item.className);
        const { createdAt, detail, rawComment } = item;
        // Validates that the item should be visible (if it has a detial entry, then it should)
        if (detail) {
            let icon = null;
            let commentDetail = null;

            // comment need specific rendering: they can be hidden / shown
            if ('timeline-comment' === item.className) {
                const iconVariant = visibleComments.includes(index) ? 'view-off' : 'view-1';
                icon = <SzIcon className="ml-2 toggle" icon={iconVariant} variant="bold"
                               onClick={() => toggleCommentVisibility(index)}/>
                commentDetail = visibleComments.includes(index) ? (<div className='pl-3 comment'>
                    {rawComment}
                </div>) : null;
            }

            // comment need specific rendering: they can be hidden / shown
            if ('timeline-attachment' === item.className) {
                icon = (<SzIcon className="ml-2 dl-attachment" icon='attachment' variant="bold" onClick={() => downloadAttachmentHandle(item.file, currentUserRequest)} />)
            }

            return (
                <>
                    <div key={index} className={itemClassName}>
                        <div className='status-sticker timeline-badge'/>
                        <SzTypographie variant="text">{DateService.formatDate(createdAt, {
                            hour: '2-digit',
                            minute: '2-digit'
                        })}</SzTypographie>
                        <SzTypographie className="ml-3" variant="text">{detail}{icon}</SzTypographie>
                    </ div>
                    {commentDetail}
                </>
            );
        }
    }

    const renderDetail = () => {
        return (
            <><SzBox className="d-flex justify-content-end align-items-center" tag="div">
                <div className="d-flex">
                    <SzButton
                        icon={state.showDetails ? 'subtract-square' : 'navigation-menu-4'}
                        variant="tertiary"
                        onClick={() => {
                            setState({ ...state, showDetails: !state.showDetails })
                        }}>
                        {state.showDetails ? t("hide_time_line_detail") : t("show_time_line_detail")}
                    </SzButton>
                </div>
            </SzBox>
                {state.showDetails && items && (
                    <SzBox className="d-flex flex-column entries" tag="div">
                        {
                            items.map((item: any, index: number) => renderLine(item, index))
                        }
                    </SzBox>
                )}

            </>);
    };
    const [items, setItems] = useState([])
    useEffect(() => {
        const items = formattedItems(currentRequestStatusList.sort(sortById), currentRequestHistory, interventions);
        setItems(items)
    }, [currentRequestStatusList, currentRequestHistory, interventions])
    return (
        <>
            {
                items && (
                    <SzBox className="d-flex detail-block3 pb-1 flex-column infobox" tag="div">
                        <SzBox className="d-flex" tag="span">
                            <SzTypographie variant="text" weight="bold">{t("time_line_title")}</SzTypographie>
                        </SzBox>
                        <SzTimeLine items={items}/>
                        {renderDetail()}
                    </SzBox>
                )}
        </>
    );
};
