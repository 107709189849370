import React from 'react';
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import UserRequestsService from '../../services/UserRequestsService';
import {Block1, Block2, Block3, Block4, Block5, Block6} from '../../components/viewDetailComponents';
import {AbstractDetailManager} from './AbstractDetailManager';
import {Roles, Status} from '../../constants';
import PdfContainer from '../../components/pdfContainer';
import PdfService from '../../services/pdfService'
import {DateService} from '../../services/dateService';
import moment from "moment";

class ViewRequestDetailManager extends AbstractDetailManager {
    state = {
        attachment: null,
        loadingAttachment: false,
        exportingPdf: false,
        border: true,
        suezUsers: [],
    };

    public componentDidMount() {
        const { categories, user } = this.props;
        this.initDetails();
        if (!categories) {
            UserRequestsService.getMdmData();
        }
        if (user.roles.includes(Roles.ROLE_SUEZ)) {
            UserRequestsService.getHeimdallUsers(
              Roles.ROLE_SUEZ
            ).then((response: any) => {
                if (response) {
                    this.setState({
                        suezUsers: response.data.data
                    })
                }
            });
        }
        window.addEventListener('resize', () => {
            this.setState({
                border: window.innerWidth > 768
            });
        }, false);
    }

    public initDetails() {
        const { requestId } = this.props;

        UserRequestsService.getRequest(requestId);
        UserRequestsService.getStatusList(requestId);
    }

    public copyUserRequest() {
        const { userRequest, history } = this.props;
        UserRequestsService.copyRequest(userRequest.id, history);
    }

    public editUserRequest() {
        const { userRequest, history } = this.props;
        UserRequestsService.editRequest(userRequest.id, history);
    }

    cancelRequest = () => {
        const { history, userRequest } = this.props;
        UserRequestsService.deleteUserRequest(userRequest.id, history)
    };

    refuseRequest = (comment: any, status: string = '') => {
        const { userRequest } = this.props;
        UserRequestsService.postStatus(userRequest.id, status, comment).then(() => {
            this.initDetails()
        });
    };

    assignSupervisor = (supervisor: any) => {
        const { userRequest } = this.props;
        UserRequestsService.postSupervisor(userRequest.id, supervisor, false).then(() => {
            this.initDetails()
        });
    };

    planRequest = (interventionReference: string, isClaim: boolean) => {
        const { currentUserRequest } = this.props;
        currentUserRequest.data.isClaim = isClaim;
        UserRequestsService.postStatus(currentUserRequest.id, Status.STATUS_PLANNED).then(() => {
            currentUserRequest.data.interventionReference = interventionReference;
            UserRequestsService.putUserRequest(currentUserRequest).then(() => {
                this.initDetails()
            });
        });
    };

    updateRequestAndStatus = (status: Status, dataOverride: any ) => {
        const { currentUserRequest } = this.props;

        Object.entries(dataOverride).map((newData:any) => {
            currentUserRequest.data[newData[0]] = newData[1];
            return newData;
        })
        if (status === Status.STATUS_DONE) {
            currentUserRequest.data['realisationDate'] = moment().format('YYYY-MM-DDTHH:mm:ss');
        }

        UserRequestsService.postStatus(currentUserRequest.id, status).then(() => {
            UserRequestsService.putUserRequest(currentUserRequest).then(() => {
                this.initDetails();
            });
        });
    };

    updateStatus = (status: string, isClaim = false, supervisor: any) => {
        const { currentUserRequest } = this.props;
        currentUserRequest.data.isClaim = isClaim;
        UserRequestsService.postStatus(currentUserRequest.id, status).then(() => {
            if (supervisor) {
                this.assignSupervisor(supervisor.value)
            } else {
                this.initDetails();
            }
        });
    };

    changeDeadlines = (processingDuration: string, comment: string) => {
        const { currentUserRequest } = this.props;
        const { id } = currentUserRequest;
        const date = DateService.stringToDate(processingDuration);
        const currentDate = new Date();
        date.setHours(currentDate.getHours());
        date.setMinutes(currentDate.getMinutes());
        date.setSeconds(date.getSeconds());
        date.setMilliseconds(currentDate.getMilliseconds());
        currentUserRequest.data.processingDuration = DateService.dateToString(date);
        UserRequestsService.putUserRequest(currentUserRequest).then(() => {
            return UserRequestsService.postComment(id, comment, []);
        }).then(() => {
            this.initDetails()
        });
    };

    uploadAttachmentHandle(attachment: any) {
        const { currentUserRequest } = this.props;
        const { id } = currentUserRequest;
        this.setState({ loadingAttachment: true });
        UserRequestsService.postAttachments(id, attachment).then(() => {
            this.setState({ loadingAttachment: false });
            UserRequestsService.getRequest(id)
        });
    }

    saveCommentHandle = (comment: string, roles: string[]) => {
        const { userRequest } = this.props;
        return UserRequestsService.postComment(userRequest.id, comment, roles).then(() => {
            UserRequestsService.getStatusList(userRequest.id)
        });
    };

    createPdf = (html: any, name: string) => {
        this.setState({ exportingPdf: true }, () => {
            setTimeout(() => {
                PdfService.createPdf(html, name)
                this.setState({ exportingPdf: false })
            }, 1000)
        })
    };

    public render() {
        const { userRequest, currentActions, categories, organisations, user, currentUserRequest, currentRequestStatusList, currentRequestHistory, currentInterventions, additionalTreatments, t } = this.props;

        const { loadingAttachment, exportingPdf, border } = this.state;

        return (
            <>
                {userRequest !== undefined && (
                    <div className="container-fluid mt-5 position-relative">
                        <PdfContainer
                            createPdf={(html: any) => this.createPdf(html, t("EXPORTED_FILE_NAME", {
                                number: userRequest.incrementalId
                            }))}
                        >
                            <div className="row">
                                <div className='col'>
                                    <Block1
                                        userRequest={userRequest}
                                        currentActions={currentActions}
                                        currentRequestStatusList={currentRequestStatusList}
                                        user={user}
                                        requestNatures={this.props.requestNatures}
                                        refuseRequest={this.refuseRequest.bind(this)}
                                        planRequest={this.planRequest.bind(this)}
                                        updateRequestAndStatus={this.updateRequestAndStatus.bind(this)}
                                        changeDeadlines={this.changeDeadlines.bind(this)}
                                        updateStatus={this.updateStatus.bind(this)}
                                        copyRequest={this.copyUserRequest.bind(this)}
                                        assignSupervisor={this.assignSupervisor.bind(this)}
                                        suezUsers={this.state.suezUsers}
                                        additionalTreatments={additionalTreatments}
                                        rawTreatments={UserRequestsService.getRawTreatments(categories, userRequest.data)}
                                        isEditableDesiredDate={this.isEditableDesiredDate()}
                                    />
                                    <Block6
                                      userRequest={userRequest}
                                    />
                                    <Block2
                                        exportingPdf={exportingPdf}
                                        userRequest={userRequest}
                                        risks={this.getRisks()}
                                        treatment={this.getTreatments()}
                                        categories={categories}
                                        organisations={organisations}
                                        editRequest={this.editUserRequest.bind(this)}
                                        cancelRequest={this.cancelRequest.bind(this)}
                                        user={user}
                                        requestFiles={this.props.requestFiles}
                                        currentRequestStatusList={currentRequestStatusList}
                                        downloadAttachmentHandle={this.downloadAttachmentHandle}
                                        border={border}
                                        isEditableDesiredDate={this.isEditableDesiredDate()}
                                    />
                                    <Block3
                                        exportingPdf={exportingPdf}
                                        currentRequestStatusList={currentRequestStatusList}
                                        currentUserRequest={currentUserRequest}
                                        currentRequestHistory={currentRequestHistory}
                                        interventions={currentInterventions}
                                        downloadAttachmentHandle={this.downloadAttachmentHandle}
                                    />
                                    <Block4
                                        interventions={currentInterventions}
                                        user={user}
                                        request={userRequest}
                                    />
                                    <Block5
                                        user={user}
                                        saveCommentHandle={this.saveCommentHandle.bind(this)}
                                        userRequest={userRequest}
                                        currentRequestStatusList={currentRequestStatusList}
                                        loadingAttachment={loadingAttachment}
                                        uploadAttachmentHandle={this.uploadAttachmentHandle.bind(this)}
                                    />
                                </div>
                            </div>
                        </PdfContainer>
                    </div>
                )
                }
            </>
        )
    }

    private isEditableDesiredDate(): boolean {
        const { userRequest } = this.props;
        const { data } = userRequest;
        const { requestCategory, requestSubCategory, requestExpectedTreatment, desiredDate } = data;
        let isEditableDesiredDate = false;
        try {
            isEditableDesiredDate = (!!desiredDate || this.props
                .categories[requestCategory.id]
                .subcategories[requestSubCategory.id]
                .treatments[requestExpectedTreatment[0].id]
                .metadata.processingTime === '= Échéance souhaitée'
              ) && !UserRequestsService.isImmutable(userRequest.currentStatus.status.id)
            ;
        } catch (e) {
        }

        return isEditableDesiredDate;
    }
}

const mapStateToProps = (state: any) => ({
    userRequest: state.userRequests.currentRequest.originalRequest,
    currentUserRequest: state.userRequests.currentRequest,
    currentActions: state.userRequests.currentActions,
    requestFiles: state.userRequests.currentRequestFiles,
    currentRequestHistory: state.userRequests.currentRequestHistory,
    currentInterventions: state.userRequests.currentInterventions,
    currentRequestStatusList: state.userRequests.currentRequestStatusList,
    categories: state.userRequests.mdmData.categories,
    additionalTreatments: state.userRequests.mdmData.additionnalTreatments,
    organisations: state.userRequests.mdmData.organisations,
    requestNatures: state.userRequests.mdmData.requestNatures,
    user: state.user.info,
    suezUsers: state.suezUsers,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(ViewRequestDetailManager));
