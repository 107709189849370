import React from 'react';
import { getFormatedForm } from '../../../Models';
import {
  SzTypographie,
  SzButton,
  SzCheckbox,
  SzAlert,
  SzBox,
  SzSelect,
  SzInput,
  SzDateRangePicker, SzModal
} from '@suezenv/react-theme-components';
import { Formik, Form } from 'formik';
import step3Model from '../../../Models/createUserRequestModels/step3Model';
import './createForm.scss';
import { withTranslation } from 'react-i18next';
import {
  Status,
  RequestAttachmentType,
  requestAcceptedMimeTypes,
  DefaultSelectedItems
} from '../../../constants';
import { SzDropZone } from '../../elements/SzDropZone';
import * as Yup from 'yup';
import { DateService } from '../../../services/dateService';
import UserRequestsService from '../../../services/UserRequestsService';
import moment from "moment";

const MAX_UPLOAD_FILES = 5;

class Step3Form extends React.Component<any> {
  state: any = {
    isValid: false,
    isNextStepDisabled: false,
    desiredDate: null,
    desiredDateProof: '',
    isDesiredDateRequired: false,
    requestOperationType: [],
    requestCleansingType: '',
    requestHumanRisk: false,
    requestPropertyRisk: false,
    requestServiceRisk: false,
    requestCategory: '',
    requestSubCategory: '',
    requestExpectedTreatment: [],
    attachments: [],
    isModalShown: false,
    helpMessage: "help_modal_desired_date_required"
  };

  step3DesiredDateProofLimit = 200;

  componentDidUpdate(props: any) {
    const { operationTypes, cleansingTypes } = props.mdmData;
    const isEmptyDefaultValues =
      this.state.requestOperationType !== [] && !this.state.requestCleansingType;
    if (
      this.props !== props &&
      operationTypes &&
      cleansingTypes &&
      isEmptyDefaultValues
    ) {
      const operationType = Object.entries(operationTypes).filter(
        (item: any) => item[0] === DefaultSelectedItems.operationTypes
      )[0];
      const requestOperationType = [{
        value: operationType[0],
        label: operationType[1]
      }];
      const cleansingType = Object.entries(cleansingTypes).filter(
        (item: any) => item[0] === DefaultSelectedItems.cleansingTypes
      )[0];
      const requestCleansingType = {
        value: cleansingType[0],
        label: cleansingType[1]
      };
      this.setState({ requestOperationType, requestCleansingType });
    }
  }

  dropHandle(files: any) {
    const attachments = files.map((file: any) => ({
      file,
      type: RequestAttachmentType.OTHER_TYPE
    }));
    this.setState({ attachments });
  }

  deleteHandle(files: any) {
    this.dropHandle(files);
  }

  relatedRisks = [
    {
      label: 'risk_human',
      stateBinding: 'requestHumanRisk'
    },
    {
      label: 'risk_property',
      stateBinding: 'requestPropertyRisk'
    },
    {
      label: 'risk_service',
      stateBinding: 'requestServiceRisk'
    }
  ];

  componentDidMount() {
    const { values, mdmData } = this.props;
    const { categories } = mdmData;
    const { data, id } = values;
    if (id) {
      let {
        requestOperationType,
        requestCleansingType,
        requestHumanRisk,
        requestPropertyRisk,
        requestServiceRisk,
        requestCategory,
        requestSubCategory,
        requestExpectedTreatment
      } = data;
      requestHumanRisk = !!requestHumanRisk;
      requestPropertyRisk = !!requestPropertyRisk;
      requestServiceRisk = !!requestServiceRisk;
      const category = categories ? categories[requestCategory] : '';
      const desiredDate = (data.desiredDate && data.desiredDate > moment('1970-01-02')) ? data.desiredDate : null;
      const desiredDateProof = data.desiredDateProof ? data.desiredDateProof : '';
      let isDesiredDateRequired = false;
      let requestExpectedTreatmentItems: any = [];

      requestCategory = requestCategory
        ? {
            value: requestCategory,
            label: category.label
          }
        : '';

      if (requestSubCategory && category.subcategories) {
        requestSubCategory = {
          value: requestSubCategory,
          label: category.subcategories[requestSubCategory].label
        };
        Object.entries(category.subcategories[requestSubCategory.value].treatments).map((treatment: any) => {
          requestExpectedTreatmentItems[treatment[0]] = false;
        })
        requestExpectedTreatment.map((value: any) => {
          requestExpectedTreatmentItems[value] = true;
          if (category.subcategories[requestSubCategory.value].treatments[value].metadata.processingTime === "= Échéance souhaitée") {
            isDesiredDateRequired = true;
          }
          return value;
        });
      } else {
        requestSubCategory = '';
      }

      const { operationTypes, cleansingTypes } = mdmData;

      let requestOperationTypeItems: any = [];
      requestOperationType?.map((value: any) => {
        requestOperationTypeItems.push(
            {
              value: value,
              label: operationTypes[value]
            }
        )
      });
      requestOperationType = requestOperationTypeItems;
      requestCleansingType = requestCleansingType
        ? {
            value: requestCleansingType,
            label: cleansingTypes[requestCleansingType]
          }
        : this.state.requestCleansingType;

      this.setState({
        desiredDate,
        desiredDateProof,
        isDesiredDateRequired,
        requestOperationType,
        requestCleansingType,
        requestHumanRisk,
        requestPropertyRisk,
        requestServiceRisk,
        requestCategory,
        requestSubCategory,
        requestExpectedTreatment: requestExpectedTreatmentItems
      });
    }
  }

  getStep3Model(state: any) {
    if (state.isDesiredDateRequired) {
      step3Model.desiredDate.required = true;
      step3Model.desiredDate.schema = Yup.string().required(
          'required_validation'
      );
      step3Model.desiredDateProof.required = false;
      step3Model.desiredDateProof.schema = Yup.string();
    } else if (state.isDesiredDateRequired === false && state.desiredDate) {
      step3Model.desiredDateProof.required = true;
      step3Model.desiredDateProof.schema = Yup.string().required(
          'required_validation'
      );
    } else {
      step3Model.desiredDate.required = false;
      step3Model.desiredDateProof.required = false;
      step3Model.desiredDate.schema = Yup.string().nullable(true);
      step3Model.desiredDateProof.schema = Yup.string();
    }

    if (this.getSubCategoriesOptions().length === 0) {
      step3Model.requestSubCategory.schema = Yup.string();
      return step3Model;
    }

    if (state.requestExpectedTreatment.length > 0) {
      step3Model.requestExpectedTreatment.schema = Yup.array().of(Yup.boolean()).test({
        name: 'one-true',
        message: 'required_validation',
        test: (value) => value.includes(true)
      })
    } else {
      step3Model.requestExpectedTreatment.schema = Yup.array()
    }

    step3Model.requestSubCategory.schema = Yup.string().required(
      'required_validation'
    );

    return step3Model;
  }
  desiredDateProofChangeHandle(e: any) {
    let update = {
      ...this.state,
      [e.target.name]: e.target.value,
    };

    if (update.desiredDateProof.length > this.step3DesiredDateProofLimit) {
      update.desiredDateProof = update.desiredDateProof.substring(
          0,
          this.step3DesiredDateProofLimit
      );
    }

    this.setState(update);
  }

  changeDatePickerHandle = (date: any) => {
    // cast to empty if set to null to pass the validation
    date = date ? date : null;
    const {isDesiredDateRequired} = this.state;
    let update = {
      ...this.state,
      desiredDate: date,
    };
    if (isDesiredDateRequired) {
      update.desiredDateProof = '';
    }

    this.setState(update, () => {
      this.computeProcessingDuration();
    });
  };

  tempNextStepDisabled(isNextStepDisabled: boolean) {
    this.setState({ isNextStepDisabled: isNextStepDisabled });
    setTimeout(() => {
      this.setState({ isNextStepDisabled: !isNextStepDisabled });
    }, 2000);
  }

  selectChangeHandle(name: any, e: any) {
    this.setState({ [name]: e });
  }

  categoryChangeHandle(name: any, e: any) {
    this.selectChangeHandle(name, e);
    const { mdmData } = this.props;
    const { categories } = mdmData;
    let isDesiredDateRequired = true;
    let requestExpectedTreatment: any = [];
    let treatments = [];
    let isModalShown = false;
    let helpMessage = "";
    let selectedSubCategory = null;

    const hasSubCategory = categories[e.value] && categories[e.value].subcategories.length !== 0;
    if (name === 'requestCategory') {
      treatments = !hasSubCategory ? categories[e.value].treatments : [];
      const requestSubCategory = '';
      this.setState({ requestSubCategory });
    }
    const { requestCategory } = this.state;
    if (name === 'requestSubCategory' && requestCategory) {
      const selectedCategory = categories[requestCategory.value];
      selectedSubCategory = selectedCategory.subcategories[e.value];
      treatments = selectedSubCategory ? selectedSubCategory.treatments : [];
    }

    Object.entries(treatments).map((value: any) => {
      requestExpectedTreatment[value[0]] = value[1].metadata.mandatory && value[1].metadata.isProcessingActive;
      isDesiredDateRequired = value[1].metadata.processingTime === "= Échéance souhaitée"
    });

    // On envoie la modal traitements supplémentaires obligatoires et date de fin souhaitée si:
    // Il y a une sous-catégorie de selectionnée
    // Et qu'il n'y a pas de traitements obligatoire
    // Et que la date de fin souhaité est requise
    // On envoie la modal date de fin souhaitée si:
    // Il y a une sous-catégorie de selectionnée
    // Et que la date de fin souhaité est requise
    if (selectedSubCategory !== null && !requestExpectedTreatment.includes(true) && isDesiredDateRequired) {
      isModalShown = true;
      helpMessage = "help_modal_treatments_and_desired_date_required";
    } else if(selectedSubCategory !== null && isDesiredDateRequired) {
      isModalShown = true;
      helpMessage = "help_modal_desired_date_required";
    }

    this.setState({ requestExpectedTreatment, isDesiredDateRequired, isModalShown, helpMessage }, () => {
      this.computeProcessingDuration();
    });
  }

  getOperationTypeOptions() {
    const { operationTypes } = this.props.mdmData;

    return operationTypes
      ? Object.entries(operationTypes).map((value: any) => ({
          value: value[0],
          label: value[1]
        }))
      : [];
  }
  getCleansingTypeOptions() {
    const { cleansingTypes } = this.props.mdmData;

    return cleansingTypes
      ? Object.entries(cleansingTypes).map((value: any) => ({
          value: value[0],
          label: value[1]
        }))
      : [];
  }

  getCategoriesOptions(): any {
    const { mdmData, user } = this.props;
    const { categories } = mdmData;
    let categoriesOptions: any = [];
    if (categories) {
      Object.entries(categories).map((value: any) => {
        const categoryRoles = value[1].roles;
        const rolesIntersect = user.roles.filter((value: any) =>
          categoryRoles.includes(value)
        );
        if (0 < categoryRoles.length && 0 === rolesIntersect.length) {
          return null;
        }

        categoriesOptions.push({
          value: parseInt(value[0]),
          label: value[1].label
        });

        return categoriesOptions;
      });
    }
    return categoriesOptions;
  }

  getSubCategoriesOptions(): any {
    const { mdmData } = this.props;
    const { categories } = mdmData;
    const { requestCategory } = this.state;
    let subCategoriesOptions: any = [];

    if (categories && categories[requestCategory.value]) {
      Object.entries(categories[requestCategory.value].subcategories).map(
        (value: any) => {
          if (value[1].active === true) {
            subCategoriesOptions.push({
              value: value[0],
              label: value[1].label
            });
          }

          return subCategoriesOptions;
        }
      );
    }
    return subCategoriesOptions;
  }

  getTreatments(mandatory = true) {
    const { mdmData } = this.props;
    const { categories } = mdmData;
    const { requestCategory, requestSubCategory } = this.state;
    if (requestCategory && categories && categories[requestCategory.value]) {
      const subCategories = categories[requestCategory.value].subcategories;
      const treatments =
        subCategories &&
        requestSubCategory &&
        subCategories[requestSubCategory.value]
          ? subCategories[requestSubCategory.value].treatments
          : categories[requestCategory.value].treatments;
      return Object.entries(treatments).map((value: any): any => {
        const isMandatoryTreatment = value[1].metadata.mandatory
          ? value[1].metadata.mandatory
          : false;
        const isActiveTreatment = value[1].metadata.isProcessingActive
          ? value[1].metadata.isProcessingActive
          : false;

        const id = value[0];
        if (mandatory === isMandatoryTreatment && isActiveTreatment) {
          const { requestExpectedTreatment }: any = this.state;
          return (
            <SzCheckbox
              id={value[0]}
              key={value[0]}
              label={value[1].label}
              onChange={() => {
                requestExpectedTreatment[id] = !requestExpectedTreatment[id];
                this.setState({ requestExpectedTreatment }, () => {
                  this.computeProcessingDuration();
                });
              }}
              checked={!!requestExpectedTreatment[id]}
              type="checkbox"
              name="requestExpectedTreatment"
              disabled={mandatory}
            />
          );
        }
        return ''
      });
    }
  }

  getCleanValues() {
    const {
      desiredDate,
      desiredDateProof,
      requestCleansingType,
      requestCategory,
      requestSubCategory,
      requestHumanRisk,
      requestPropertyRisk,
      requestServiceRisk,
      attachments
    } = this.state;
    let requestExpectedTreatment: any = [];
    let requestOperationType: any = [];
    Object.entries(this.state.requestExpectedTreatment).map((value: any) => {
      if (value[1] === true) {
        requestExpectedTreatment.push(value[0]);
      }
      return value[0];
    });

    Object.entries(this.state.requestOperationType).map((value: any) => {
      requestOperationType.push(value[1].value);
    });

    return {
      desiredDate,
      desiredDateProof,
      requestOperationType,
      requestCleansingType: requestCleansingType.value,
      requestHumanRisk,
      requestPropertyRisk,
      requestServiceRisk,
      requestCategory: requestCategory.value,
      requestSubCategory: requestSubCategory ? requestSubCategory.value : '',
      requestExpectedTreatment,
      attachments
    };
  }

  getBtn(property: any, targetValue: any) {
    let currentValue, btnText, outline;

    const { t } = this.props;
    const onClickCall = () => {
      let newState = {};
      switch (property) {
        case 'requestHumanRisk':
          newState = { requestHumanRisk: targetValue };
          break;
        case 'requestPropertyRisk':
          newState = { requestPropertyRisk: targetValue };
          break;
        case 'requestServiceRisk':
          newState = { requestServiceRisk: targetValue };
          break;
      }

      this.setState(newState, () => {
        this.computeProcessingDuration();
      });
    };

    switch (property) {
      case 'requestHumanRisk':
        currentValue = this.state.requestHumanRisk;
        break;
      case 'requestPropertyRisk':
        currentValue = this.state.requestPropertyRisk;
        break;
      case 'requestServiceRisk':
        currentValue = this.state.requestServiceRisk;
        break;
    }

    // If target value === currentValue, button is selected and should NOT be outlined
    outline = !(targetValue === currentValue);
    btnText = true === targetValue ? t('yes_btn') : t('no_btn');
    const variantButton = outline ? 'secondary' : 'primary';
    return (
      <SzButton variant={variantButton} onClick={onClickCall} className="w-100 h-auto">
        {btnText}
      </SzButton>
    );
  }

  renderHelpModal(helpMessage: string) {
    const {t} = this.props;
    let isModalShown = true;

    return (
        <SzModal
            className="help-modal"
            size={'xl'}
            handleClose={() => {
              this.setState({ isModalShown: false })
            }}
            centered={true}
            show={isModalShown}
            title={t(helpMessage)}
        >
          <div className="row justify-content-end">
            <div className="col-auto">
              <SzButton
                  variant="secondary"
                  onClick={() => {
                    this.setState({ isModalShown: false })
                  }}
              >
                {t('understood')}
              </SzButton>
            </div>
          </div>
        </SzModal>
    )
  }

  renderProcessingDuration() {
    const { t, values } = this.props;
    const {isDesiredDateRequired, desiredDate} = this.state;
    const { data } = values;

    return (
      <SzBox className="row box" tag="div">
        <SzBox className=" col d-flex pb-2 flex-column" tag="div">
          <div className="flex-fill">
            <SzTypographie color='inactive' weight="bold" className="mb-3">
              {t('create_summary_bloc2_title')}
            </SzTypographie>
            <SzTypographie variant="text">
              {t('create_summary_bloc2_text', {
                date: (!isDesiredDateRequired || desiredDate) && data.processingDuration
                  ? DateService.formatDate(data.processingDuration)
                  : '-'
              })}
            </SzTypographie>
          </div>
        </SzBox>
      </SzBox>
    );
  }
  renderDesiredDate(desiredDate: any, desiredDateProof: any) {
    const today = new Date();
    const { t } = this.props;
    return (
        <div className="mt-5 d-sm-flex d-block align-items-stretch">
          <div className="w-50 ml-3 mr-3">
            <SzTypographie
                className={"d-block sz-label-input" + desiredDate.required ?? "required"}
                variant="text"
                weight="bold"
                color="inactive"
            >
              {t('desired_date_form_label')}
            </SzTypographie>
            <div className="row">
              <div className="col-9 col-sm-10 col-md-9 col-lg-10">
                <SzDateRangePicker
                    onChange={this.changeDatePickerHandle}
                    value={this.state.desiredDate}
                    minDate={today}
                    toggle={true}
                />
              </div>
              {desiredDateProof.required &&
              <div className="col-2 text-right">
                <SzButton
                    icon={'bin-2-alternate'}
                    onClick={() => {
                      this.changeDatePickerHandle(null);
                    }}
                    className="clear-date-btn"
                    type="reset"
                    variant="secondary"
                />
              </div>
              }
            </div>
            <div className="mb-4" />
            {desiredDateProof.required && (
              <SzInput
                  onChange={this.desiredDateProofChangeHandle.bind(this)}
                  label={t(desiredDateProof.label)}
                  name={desiredDateProof.name}
                  icon={desiredDateProof.icon}
                  required={desiredDateProof.required}
                  placeholder={desiredDateProof.placeholder}
                  value={this.state.desiredDateProof}
              />
            )}
          </div>
        </div>
    );
  }

  computeProcessingDuration() {
    const { isValid } = this.state;

    if (!isValid) {
      UserRequestsService.resetDelay();
      return;
    }

    const {
      desiredDate,
      requestHumanRisk,
      requestExpectedTreatment,
      requestCategory,
      requestSubCategory,
      requestPropertyRisk,
      requestServiceRisk
    } = this.getCleanValues();
    const hasSubCategory = requestCategory && requestSubCategory;
    const categoryToCompute = hasSubCategory
      ? requestSubCategory
      : requestCategory;
    UserRequestsService.computeDelay(
      categoryToCompute,
      desiredDate,
      requestExpectedTreatment,
      requestHumanRisk,
      requestPropertyRisk,
      requestServiceRisk
    );
  }

  public render() {
    const [schema] = getFormatedForm(this.getStep3Model(this.state));
    const {
      t,
      nextStepHandle,
      saveDraft,
      previousStepHandle,
      values,
      otherAttachment,
      user
    } = this.props;
    const defaultFiles = otherAttachment.map(
      (attachment: any) => attachment.file
    );
    const { isNextStepDisabled, isValid, isModalShown, helpMessage } = this.state;
    const isDraft =
      values.id && values.currentStatus.status.id === Status.STATUS_DRAFT;
    const isAutoSavedDraft = values.id && !values.currentStatus.status.id;
    const showDraft = isDraft || !values.id || isAutoSavedDraft;

    return (
      <>
        <Formik
          validationSchema={schema}
          onSubmit={() => {}}
          initialValues={this.state}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ submitForm, errors, setErrors }) => {
            const {
              desiredDate,
              desiredDateProof,
              requestOperationType,
              requestCleansingType,
              requestCategory,
              requestSubCategory
            } = step3Model;
            schema.isValid(this.state).then((isValidForm: any) => {
              if (isValidForm !== isValid) {
                setErrors({});
                this.setState({ isValid: isValidForm }, () => {
                  this.computeProcessingDuration();
                });
              }
            });
            const nextStep = () => {
              if (!isValid) {
                submitForm();
              } else {
                this.tempNextStepDisabled(true);
                nextStepHandle(this.getCleanValues());
              }
            };
            return (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  if (!isValid) {
                    submitForm();
                  } else {
                    saveDraft(this.getCleanValues());
                  }
                }}
              >
                { isModalShown &&
                    this.renderHelpModal(helpMessage)
                }
                <div className="row box">
                  <div className="col">
                    <div className="mt-5 d-sm-flex d-block align-items-stretch">
                      <div className="flex-fill ml-3 mr-3">
                        <SzTypographie color='inactive' weight="bold" className="mb-3">
                          {t(requestCategory.label)}
                        </SzTypographie>
                        <SzSelect
                          className="mb-3 border-secondary"
                          name={requestCategory.name}
                          placeholder={t('sub_category_form_label')}
                          onChange={this.categoryChangeHandle.bind(
                            this,
                            'requestCategory'
                          )}
                          options={this.getCategoriesOptions()}
                          value={this.state.requestCategory}
                        />
                        {errors['requestCategory'] && (
                          <SzAlert variant="danger">
                            <>{t(errors['requestCategory'])}</>
                          </SzAlert>
                        )}
                      </div>

                      <div className="flex-fill ml-3 mr-3">
                        {this.getSubCategoriesOptions().length > 1 && (
                          <>
                            {' '}
                            <SzTypographie color='inactive' weight="bold" className="mb-3">
                              {t(requestSubCategory.label)}
                            </SzTypographie>
                            <SzSelect
                              className="mb-3 border-secondary"
                              name={requestSubCategory.name}
                              placeholder={t('default_category_value')}
                              onChange={this.categoryChangeHandle.bind(
                                this,
                                'requestSubCategory'
                              )}
                              options={this.getSubCategoriesOptions()}
                              value={this.state.requestSubCategory}
                            />
                            {errors['requestSubCategory'] && (
                              <SzAlert variant="danger">
                                <>{t(errors['requestSubCategory'])}</>
                              </SzAlert>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="mt-5 d-sm-flex d-block align-items-stretch treatment-block">
                      <div className="m-3 flex-fill mandatory-treatment-block">
                        <SzTypographie color='inactive' weight="bold" className="mb-3">
                          {t('mandatory_treatment_form_label')}
                        </SzTypographie>
                        <div className="flex-fill ml-2">
                          {this.getTreatments()}
                        </div>
                      </div>
                      <div className="m-3 flex-fill optional-treatment-block">
                        <SzTypographie color='inactive' weight="bold" className="mb-3">
                          {t('optional_treatment_form_label')}
                        </SzTypographie>
                        <div className="flex-fill ml-2">
                          {this.getTreatments(false)}
                        </div>
                      </div>
                    </div>
                    {this.renderDesiredDate(desiredDate, desiredDateProof)}
                  </div>
                </div>
                <div className="row box">
                  <div className="col">
                    <div className="mt-5 d-md-flex d-block align-items-stretch">
                      <div className="flex-fill mr-3 mb-5">
                        <SzTypographie color='inactive' weight="bold" className="mb-3">
                          {t('step3_select_type')}
                        </SzTypographie>
                        <SzSelect
                          onChange={this.selectChangeHandle.bind(
                            this,
                            'requestOperationType'
                          )}
                          placeholder={t(requestOperationType.placeholder)}
                          options={this.getOperationTypeOptions()}
                          value={this.state.requestOperationType}
                          name={"requestOperationType"}
                          className="mb-3 border-secondary sz-select_placeholder"
                          isClearable
                          isMulti
                        />
                        <SzSelect
                          onChange={this.selectChangeHandle.bind(
                            this,
                            'requestCleansingType'
                          )}
                          placeholder={t(requestCleansingType.placeholder)}
                          options={this.getCleansingTypeOptions()}
                          value={this.state.requestCleansingType}
                          name={requestCleansingType.name}
                          className="sz-select_placeholder"
                        />
                      </div>
                      <div className="flex-fill flex-column risks-block ml-3 mr-3">
                        <SzTypographie color='inactive' weight="bold" className="mb-3">
                          {t('step3_related_risks')}
                        </SzTypographie>
                        {/* No flex below to ensure same with everywheres*/}
                        {this.relatedRisks.map((risk, index) => (
                          <div
                            className="row mb-2"
                            id={`btn-risk-${index}`}
                            key={index}
                          >
                            <div className="col-6">
                              <SzTypographie variant="text">
                                {t(risk.label)}
                              </SzTypographie>
                            </div>
                            <div className="col-3 col-sm-2 text-center">
                              {this.getBtn(risk.stateBinding, true)}
                            </div>
                            <div className="col-3 col-sm-2 text-center">
                              {this.getBtn(risk.stateBinding, false)}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {this.renderProcessingDuration()}
                <div className="row box">
                  <div className="col">
                    <SzBox className="row mt-5" tag="div">
                      <SzBox className="p-2 col-md-4" tag="div">
                        <SzTypographie color='inactive' weight="bold" className="mb-3">
                          {t('drop_files_title')}
                        </SzTypographie>
                        <SzDropZone
                          className="multi-Drop-zone"
                          defaultFiles={defaultFiles}
                          maxFiles={MAX_UPLOAD_FILES}
                          dropHandle={this.dropHandle.bind(this)}
                          deleteHandle={this.deleteHandle.bind(this)}
                          multiple={true}
                          placeHolder={t('drop_files')}
                          accept={requestAcceptedMimeTypes}
                        />
                      </SzBox>
                    </SzBox>
                  </div>
                </div>
                <div className="row mt-5 step-actions">
                  <div className="col-7 col-sm-5 col-md-3">
                    <SzButton
                      className="previous-btn w-100"
                      icon="arrow-left"
                      onClick={previousStepHandle}
                      variant="secondary"
                    >
                      {t('step_previous_btn', { number: 2 })}
                    </SzButton>
                  </div>
                  <div
                    className={showDraft ? 'col-5 col-sm-5 offset-sm-2 col-md-2 offset-md-4' : 'col-5 col-sm-5 offset-sm-2 col-md-2 offset-md-7'}
                  >
                    <SzButton
                      className="next-btn w-100"
                      isDisabled={!isValid || isNextStepDisabled}
                      onClick={nextStep}
                    >
                      {t('next_btn')}
                    </SzButton>
                  </div>
                  {showDraft && (
                    <>
                    <div className="col-sm-5 col-md-3 d-none d-md-block">
                      <SzButton
                        className="draft-btn w-100"
                        isDisabled={!isValid}
                        type="submit"
                        variant="secondary"
                      >
                        {t('draft_btn')}
                      </SzButton>
                    </div>
                      <div className="col-12 pt-3 d-block d-md-none">
                        <SzButton
                          className="draft-btn w-100"
                          isDisabled={!isValid}
                          type="submit"
                          variant="secondary"
                        >
                          {t('draft_btn_full')}
                        </SzButton>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(Step3Form);
