import React from "react";
import { connect } from "react-redux";
import { SzStepper } from "@suezenv/react-theme-components";
import './createUserRequest.scss';
import { Translation, withTranslation } from 'react-i18next';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Step1Manager from "../../manager/createUserRequest/Step1Manager";
import Step2Manager from "../../manager/createUserRequest/Step2Manager";
import Step3Manager from "../../manager/createUserRequest/Step3Manager";
import Step4Manager from "../../manager/createUserRequest/Step4Manager";
import Step5Manager from "../../manager/createUserRequest/Step5Manager";
import DeleteRequestBtn from "../../components/elements/deleteRequestBtn";

class CreateUserRequestPage extends React.Component<any> {
    state = {
        step: 0,
        mobile: false,
    }
    header = [
        <Translation>{t => t("step_localisation")}</Translation>,
        <Translation>{t => t("step_Contact")}</Translation>,
        <Translation>{t => t("step_description_request")}</Translation>,
        <Translation>{t => t("step_additional_information")}</Translation>,
        <Translation>{t => t("step_Summary_request")}</Translation>
    ]
    headerLabel = [
        "step_localisation",
        "step_Contact",
        "step_description_request",
        "step_additional_information",
        "step_Summary_request",
    ]

    componentDidMount() {
        const { t } = this.props;
        document.title = t('start_item_text');
        this.setState({
            mobile: window.innerWidth < 768
        });
        window.addEventListener('resize', () => {
            this.setState({
                mobile: window.innerWidth < 768
            });
        }, false);
    }

    componentWillUnmount() {
        const { t } = this.props
        document.title = t('app_title')
    }

    goToStepHandle(step: number) {
        const index = step - 1;
        this.setState({ step: index });
    }

    public render() {
        const { userRequest, t } = this.props;
        const showBtn = (undefined !== userRequest.id && null !== userRequest.id);
        const { step, mobile } = this.state;
        const progressPercent = (100 / this.header.length) * (step + 1);

        return (
            <div className="container-fluid create-user-container mt-5">
                <div className="row">
                    <div className='col'>
                        {showBtn &&
                        <div className="d-flex justify-content-end">
                            <DeleteRequestBtn userRequestId={userRequest.id}/>
                        </div>
                        }
                        <SzStepper
                            header={this.header}
                            activeStep={step}
                            variant={'empty'}
                            displayProgress={true}
                        >
                            {!mobile &&
                                <SzStepper.SzHeader/>
                            }
                            {mobile &&
                              <>
                                <div className="p-4 bg-white">
                                    <label className="sz-line-height-normal">
                                        {t('step_progress', {
                                            number: step + 1,
                                            label: t(this.headerLabel[step])
                                        })}
                                    </label>
                                    <ProgressBar
                                      now={progressPercent}
                                      className="stepper-bar mt-3"
                                    />
                                </div>
                              </>
                            }
                            <div className="step1">
                                <SzStepper.SzStep index={0}>
                                    <Step1Manager
                                        currentStep={this.state.step}
                                        nextStepHandle={() => this.goToStepHandle(2)}
                                    />
                                </SzStepper.SzStep>
                            </div>
                            <div className="step2">
                                <SzStepper.SzStep index={1}>
                                    <Step2Manager
                                        currentStep={this.state.step}
                                        nextStepHandle={() => this.goToStepHandle(3)}
                                        previousStepHandle={() => this.goToStepHandle(1)}
                                    />
                                </SzStepper.SzStep>
                            </div>
                            <div className="step3">
                                <SzStepper.SzStep index={2}>
                                    <Step3Manager
                                        currentStep={this.state.step}
                                        nextStepHandle={() => this.goToStepHandle(4)}
                                        previousStepHandle={() => this.goToStepHandle(2)}
                                    />
                                </SzStepper.SzStep>
                            </div>
                            <div className="step4">
                                <SzStepper.SzStep index={3}>
                                    <Step4Manager
                                        currentStep={this.state.step}
                                        nextStepHandle={() => this.goToStepHandle(5)}
                                        previousStepHandle={() => this.goToStepHandle(3)}
                                    />
                                </SzStepper.SzStep>
                            </div>
                            <div className="step5">
                                <SzStepper.SzStep index={4}>
                                    <Step5Manager
                                        currentStep={this.state.step}
                                        previousStepHandle={() => this.goToStepHandle(4)}
                                    />
                                </SzStepper.SzStep>
                            </div>
                        </SzStepper>
                    </div>
                </div>

            </div>
        );
    }

}

const mapStateToProps = (state: any) => ({
    userRequest: state.userRequests.currentRequest,
})

const mapDispatchToProps = {}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(CreateUserRequestPage));