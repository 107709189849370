import { RequestAttributes } from '../../constants';
import React from "react";
import { SzTypographie, SzBox, SzList } from '@suezenv/react-theme-components';
import { withTranslation } from 'react-i18next';
import { AbstractDetailManager } from "./AbstractDetailManager";
import { DateService } from '../../services/dateService';
import UserService from '../../services/UserService';

class Step5DetailManager extends AbstractDetailManager {
    getOperation() {
        const { userRequest, mdmData } = this.props;
        let operations = mdmData.operationTypes;

        return (
            <SzList>
                {userRequest.data.requestOperationType && userRequest.data.requestOperationType !== [] && operations ?
                    (
                        userRequest.data.requestOperationType.map((value: any, i: any) => {
                            return <SzList.Item key={i} >{operations[value]}</SzList.Item>
                        })
                    ) : (
                        <SzList.Item>-</SzList.Item>
                    )
                }
            </SzList>
        );
    }

    getCleansing() {
        const { userRequest, mdmData } = this.props;
        let cleansings = mdmData.cleansingTypes;
        let operationLabel = '-'
        if (userRequest.data.requestCleansingType !== "" && cleansings) {
            Object.entries(cleansings).map(function (cleansing: any) {
                if (userRequest.data.requestCleansingType === cleansing[0]) {
                    operationLabel = cleansing[1]
                }
                return cleansing;
            });
        }

        return operationLabel
    }

    getCategoryLabel() {
        const { categories, userRequest } = this.props

        let categoriesLoaded = null !== categories
        let categoryChosen = (
            userRequest.data.requestCategory !== undefined
            && userRequest.data.requestCategory !== null
        )

        if (categoriesLoaded && categoryChosen && categories[userRequest.data.requestCategory]) {
            return categories[userRequest.data.requestCategory].label
        }

        return '-'
    }
    renderBlock1() {
        const { userRequest, t, user, mobile } = this.props
        const { number, street, city } = userRequest.address;
        const stringAddress =  number ? `${number} ${street}, ${city}`: `${street}, ${city}`;
        const { additionalReference } = userRequest.data;
        let { addressAdditionalInformation } = userRequest.data

        // Was it ABSOLUTELY necessary to have both undefined and null?? Thanks for nothing JS.
        if (undefined === addressAdditionalInformation || null === addressAdditionalInformation) {
            addressAdditionalInformation = ''
        }

        if (addressAdditionalInformation.length > 50) {
            addressAdditionalInformation = <abbr title={addressAdditionalInformation}>{addressAdditionalInformation.substring(0, 50) + '...'}</abbr>
        }

        let additionalInfo = '-';

        // This test is only needed for the 5th step, by then additionalReference _will_ be defined.
        // This test shouldn't run before step 5, or TypeScript will throw a nice little error "additionalReference is not defined."
        if (undefined !== additionalReference) {
            const { comment } = userRequest;
            let items = [additionalReference, comment];

            // Removes elements that are either null or empty strings.
            items = items.filter(i => null === i || i.length > 0)
            additionalInfo = items.length ? items.join('\n\n') : '-';
        }

        const { mandatoryTreatments, additionalTreatments } = this.getTreatments();
        const borderedClassName = mobile ?
          'flex-fill d-md-flex d-block align-items-stretch p-2' :
          'flex-fill d-md-flex d-block align-items-stretch p-2 items-bordered';

        return (
            <SzBox className="row box" tag="div">
                <SzBox className="col d-flex pb-2 flex-column infobox" tag="div">
                    <SzBox className="d-flex p-2" tag="div">
                        <SzTypographie variant="h1">{t("create_summary_bloc1_title")}</SzTypographie>
                    </SzBox>
                    <SzBox className={borderedClassName} tag="div">
                        <div className="flex-fill address_detail">
                            <SzTypographie variant="text" color="inactive">{t("create_summary_bloc1_address")}</SzTypographie>
                            <SzTypographie variant="text" weight="bold">
                                {stringAddress}
                                {addressAdditionalInformation.length ? ' ' + addressAdditionalInformation : null}
                            </SzTypographie>
                        </div>
                        <div className="flex-fill category_detail">
                            <SzTypographie variant="text" color="inactive">{t("create_summary_bloc1_category")}</SzTypographie>
                            <SzTypographie variant="text" weight="bold">{this.getCategoryLabel()}</SzTypographie>
                        </div>
                        <div className="flex-fill operation_type_detail">
                            <SzTypographie variant="text" color="inactive">{t("operationTypes")}</SzTypographie>
                            {this.getOperation()}
                        </div>
                        <div className="flex-fill operation_cleasning_detail">
                            <SzTypographie variant="text" color="inactive">{t("cleansingTypes")}</SzTypographie>
                            <SzTypographie variant="text" weight="bold">{this.getCleansing()}</SzTypographie>
                        </div>
                    </SzBox>
                    {
                        (UserService.isAdmin(user) || UserService.isSuez(user)) && <SzBox className="flex-fill d-md-flex d-block align-items-stretch innerBox treatment_detail" tag="div">
                            <div className="flex-fill mandatory_treatment_detail">
                                <SzTypographie variant="text" color="inactive">{t("create_summary_bloc1_mandatory_treatments")}</SzTypographie>
                                {
                                    mandatoryTreatments.map((treatment: any, i: any) => <SzTypographie key={i} variant="text" weight="bold">{treatment}</SzTypographie>)
                                }
                            </div>
                            <div className="flex-fill additonal_treatment_detail">
                                <SzTypographie variant="text" color="inactive">{t("create_summary_bloc1_additonal_treatments")}</SzTypographie>
                                {
                                    additionalTreatments.map((treatment: any, i: any) => <SzTypographie key={i} variant="text" weight="bold">{treatment}</SzTypographie>)
                                }
                            </div>
                        </SzBox>
                    }
                    <SzBox className="flex-fill d-md-flex d-block align-items-stretch p-2" tag="div">
                        <div className="flex-fill additonal_informations_detail">
                            <SzTypographie variant="text" color="inactive">{t("create_summary_bloc1_additonal_informations")}</SzTypographie>
                            <SzTypographie className="white-space-pre-wrap" variant="text" weight="bold">{additionalInfo}</SzTypographie>
                        </div>
                        <div className="flex-fill documents_detail">
                            <SzTypographie variant="text" color="inactive">{t("detail_summary_document")}</SzTypographie>
                            {this.renderAttachment("attachment-step5")}
                        </div>
                        <div className="flex-fill risks_detail">
                            <SzTypographie variant="text" color="inactive">{t("create_summary_bloc1_risks")}</SzTypographie>
                            <SzTypographie variant="text" weight="bold">{this.getRisks()}</SzTypographie>
                        </div>
                    </SzBox>
                </SzBox>
            </SzBox>
        )
    }

    renderBlock2() {
        const { t, userRequest } = this.props;
        const { data } = userRequest;
        return (
            <SzBox className="row box" tag="div">
                <SzBox className=" col d-flex pb-2 flex-column infobox" tag="div">
                    <div className="flex-fill">
                        <SzTypographie variant="text" color="inactive">{t("create_summary_bloc2_title")}</SzTypographie>
                        <SzTypographie variant="text" weight="bold">{t("create_summary_bloc2_text", {
                            "date": data.processingDuration ? DateService.formatDate(data.processingDuration) : '-'
                        })}</SzTypographie>
                    </div>
                </SzBox>
            </SzBox>
        )
    }

    renderBlock4() {
        const { t, userRequest } = this.props;
        const { data } = userRequest;
        if (data.desiredDate && data.desiredDateProof) {
            return (
                <SzBox className="row box" tag="div">
                    <SzBox className="col d-flex pb-2 flex-column infobox" tag="div">
                        <div className="flex-fill">
                            <SzTypographie variant="text" color="inactive">{t("create_summary_bloc4_title")}</SzTypographie>
                            <SzTypographie variant="text" weight="bold">{t("create_summary_bloc4_text", {
                                "date": DateService.formatDate(data.desiredDate),
                                "proof": data.desiredDateProof
                            })}</SzTypographie>
                        </div>
                    </SzBox>
                </SzBox>
            )
        }
    }

    renderContactTypeDetail() {
        const { userRequest, organisations, t } = this.props
        let { contactType } = userRequest.data
        let detailValue: string = '-'
        let label: string = ''

        switch (contactType) {
            case RequestAttributes.SUBSCRIBER_TYPE:
                label = t('contact_number_subscriber_form_label')
                // This value can be null
                if (userRequest.data.contactTypeSubscriber) {
                    detailValue = userRequest.data.contactTypeSubscriber
                }
                break;
            case RequestAttributes.OFFICE_TYPE:
                label = t('contact_office_name_form_label')

                // This value can be null
                if (userRequest.data.contactTypeOffice) {
                    detailValue = userRequest.data.contactTypeOffice
                }
                break;
            case RequestAttributes.ORGANISATION_TYPE:
                label = t('create_summary_bloc3_contact_type_organisation');
                const organisation = organisations[userRequest.data.contactTypeOrganisation];
                detailValue = organisation ? organisation.label : ''
                break;
        }

        if (detailValue.length > 0) {
            return (
                <div className="flex-fill">
                    <SzTypographie variant="text" color="inactive">{label}</SzTypographie>
                    <SzTypographie variant="text" weight="bold">{detailValue}</SzTypographie>
                </div>
            )
        }

        return <></>
    }

    renderBlock3() {
        const { userRequest, t, mobile } = this.props
        let { contactName, contactType, contactPhone, contactEmail } = userRequest.data

        contactType = contactType ? contactType : '-'
        contactPhone = contactPhone ? contactPhone : '-'
        contactEmail = contactEmail ? contactEmail : '-'
        const borderedClassName = mobile ? 'flex-fill d-md-flex d-block align-items-stretch p-2' : 'flex-fill d-flex align-items-stretch p-2 items-bordered';

        return (
            <SzBox className="row box" tag="div">
                <SzBox className="col d-flex pb-2 flex-column infobox" tag="div">
                    <SzBox className="d-flex p-2" tag="div">
                        <SzTypographie variant="h1">{t("create_summary_bloc3_title")}</SzTypographie>
                    </SzBox>
                    <SzBox className={borderedClassName} tag="div">
                        <div className="flex-fill">
                            <SzTypographie variant="text" color="inactive">{t("create_summary_bloc3_name")}</SzTypographie>
                            <SzTypographie variant="text" weight="bold">{contactName}</SzTypographie>
                        </div>
                        <div className="flex-fill">
                            <SzTypographie variant="text" color="inactive">{t("create_summary_bloc3_contact_type")}</SzTypographie>
                            <SzTypographie variant="text" weight="bold">{t(`create_summary_bloc3_contact_type_${contactType}`)}</SzTypographie>
                        </div>
                        {this.renderContactTypeDetail()}
                        <div className="flex-fill">
                            <SzTypographie variant="text" color="inactive">{t("create_summary_bloc3_contact_phone")}</SzTypographie>
                            <SzTypographie variant="text" weight="bold">{contactPhone}</SzTypographie>
                        </div>
                        <div className="flex-fill">
                            <SzTypographie variant="text" color="inactive">{t("create_summary_bloc3_contact_email")}</SzTypographie>
                            <SzTypographie variant="text" weight="bold">{contactEmail}</SzTypographie>
                        </div>
                    </SzBox>
                </SzBox>
            </SzBox>
        )
    }

    render() {
        const { userRequest } = this.props
        return (
            <>
                {
                    this.renderBlock1()
                }
                {
                    this.renderBlock4()
                }
                {
                    this.renderBlock2()
                }
                {
                    userRequest.data.contactName && this.renderBlock3()
                }</>
        )
    }
}

export default (withTranslation()(Step5DetailManager));
