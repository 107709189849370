import React from 'react';
import { SzDropDown, SzHeader, SzButton , SzTypographie } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import './header.scss';
import { Link } from 'react-router-dom';
import UserIcon from '../../../assets/icon/login.svg';
import FreshdeskComponent from '../../freshdesk/freshdesk';

interface IHeadersProps {
  isError: boolean;
  handleOnClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  setModalNotifActive: (show: boolean) => void;
  user: any;
}

export default function Header(Props: IHeadersProps) {
  const { handleOnClick, user, setModalNotifActive } = Props;
  const { t } = useTranslation();

  const menuOpt = [
    {
      content: t('menu_my_settings'),
      handleClick: () => {
        setModalNotifActive(true);
      },
      icon: 'sz-icon-line app-window-settings'
    },
    {
      content: t('menu_logout'),
      link: '/logout',
      icon: 'sz-icon-line logout-2'
    }
  ];

  const logoLink = (
    <a className="" href="#!">
      <img
        src="https://projets-suez.github.io/sds/v1/img/toulouse-metropole-logo.svg"
        alt=""
        height={40}
      />
    </a>
  );

  const title = (
    <a className="" href="#!">
      <SzTypographie variant="text" className="m-0" >{t('menu_title')}</SzTypographie>
    </a>
  );

  const defaultMenuOpt = (
    <>
      <img
        src={UserIcon}
        width="30px"
        height="30px"
        className="mr-3"
        alt="User icon"
      />
    </>
  );

  const userMenu = (
    <SzDropDown
      className="d-none d-md-flex justify-content-space-around align-items-center ml-auto px-3"
      value={defaultMenuOpt}
    >
      {menuOpt.map(({ link, handleClick, icon, content }, index) => {
        return (
          <SzDropDown.Item key={index} onClick={handleClick}>
            {link ? (
              <Link to={link} className="d-flex align-items-center">
                <SzButton variant="tertiary" icon={icon}>
                  {content}
                </SzButton>
              </Link>
            ) : (
              <SzButton variant="tertiary" icon={icon}>
                {content}
              </SzButton>
            )}
          </SzDropDown.Item>
        );
      })}
    </SzDropDown>
  );

  return (
    <SzHeader className="d-flex align-items-stretch">
      {!Props.isError && (
        <div className="group menu text-secondary" onClick={handleOnClick}>
          <span className="sz-icon-line navigation-menu" />
        </div>
      )}
      <div className="group">{logoLink}</div>
      <div className="group no-border title d-none d-sm-flex">{title}</div>
      {
        Props.user.logged &&
        <div className="group no-border px-0 d-flex justify-content-space-around align-items-center ml-auto px-3">
          {userMenu}
        </div>
      }
      <div className="group d-none d-sm-flex">
        <FreshdeskComponent user={user} />
      </div>
    </SzHeader>
  );
}
