
import React from "react";

interface IMapComponent {
    addressAutoComplete: any,
    autoCompleteClickHandle: Function,
}
const styles = { maxHeight: 250, zIndex: 100, width: "95%" }
const AutoCompleteItemsComponent = (props: IMapComponent) => {
    const { addressAutoComplete, autoCompleteClickHandle } = props;
    return (
        <>
            <div className="position-absolute list-group overflow-auto" style={styles}>
                {addressAutoComplete.items.map((item: any, index: any) => {
                    const { address, lat, lng, street, houseNumber, postcode, city } = item;
                    return address ? (
                        <button
                            key={index}
                            type="button"
                            className="list-group-item list-group-item-action"
                            onClick={() => { autoCompleteClickHandle(lat, lng, street, houseNumber, postcode, city, address) }}
                        >
                            {address}
                        </button>
                    ) : null;
                })}
            </div>

        </>
    );

}

export default AutoCompleteItemsComponent;