import {SzTypographie, SzBox, SzButton} from '@suezenv/react-theme-components';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {AppUrls} from "../../constants";

export const Block6 = (props: any) => {
  const {t} = useTranslation();
  const {
    userRequest,
  } = props;

  const {data} = userRequest;
  const {initialRequest, initialRequestIncrementalId} = data;
  const url = AppUrls.VIEW_REQUEST.replace(':requestId', initialRequest);

  return (
    <>
      {initialRequest &&
          <SzBox
              className="d-flex detail-block6 infobox pb-0 pt-0"
              tag="div"
          >
              <div className="flex-fill mt-5 pt-2">
                  <SzTypographie variant="text" weight="bold">{t("initial_request")}</SzTypographie>
              </div>
              <div className="flex-fill mt-5 pt-3">
                  <SzTypographie variant="text" weight="bold" color="inactive">
                    {t("demande_number")} {initialRequestIncrementalId}
                  </SzTypographie>
              </div>
              <div className="justify-content-end float-right pr-0">
                  <a
                     href={url}
                     target={'_blank'}
                     rel="noopener noreferrer"
                  >
                      <SzButton
                          icon="arrow-right"
                          variant="secondary"
                      >
                        {t("go_to_request")}
                      </SzButton>
                  </a>
              </div>
          </SzBox>
      }
    </>
  )
};
