import React, { useState, useEffect } from 'react'
import ErrorComponent from '../../components/error';
import { connect } from 'react-redux';
import { AppUrls, ErrorRoutes } from '../../constants';
import { updateError } from '../../actions';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const ErrorManager = (props: any) => {
    const [state, setState] = useState({ title: '', description: '', showButton: true, contactAdmin: true })
    const history = useHistory()
    const { t } = useTranslation();
    useEffect(() => {
        const pathname = history.location.pathname
        if (pathname === ErrorRoutes.NOT_FOUND) {
            setState({ description: '', title: t("NOT_FOUND_TITLE"), showButton: true, contactAdmin: true })
        } else if (pathname === ErrorRoutes.ACCESS_DENIED) {
            setState({ description: '', title: t("ACCESS_DENIED_TITLE"), showButton: true, contactAdmin: true })
        } else if (pathname === ErrorRoutes.SSO_ERROR) {
            setState({ title: t("ACCESS_SSO_ERROR_TITLE"), description: t("ACCESS_SSO_ERROR"), showButton: false, contactAdmin: true })
        } else if (pathname === ErrorRoutes.EXPIRED) {
            setState({ title: t("EXPIRED_ERROR_TITLE"), description: t("EXPIRED_ERROR"), showButton: false, contactAdmin: false })
        } else {
            setState({ description: '', title: t("OUPS_TITLE"), showButton: true, contactAdmin: true })
        }
    }, [history.location.pathname, t])

    const refreshPage = () => {
        props.updateError({ isError: false, message: '', status: null })
        history.push(AppUrls.HOME)
    }
    return (
        <ErrorComponent
          title={state.title}
          description={state.description}
          refreshPage={refreshPage}
          showButton={state.showButton}
          contactAdmin={state.contactAdmin}
        />
    )
}
const mapStateToProps = (state: any) => ({
    error: state.error
});
const mapDispatchToProps = {
    updateError
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorManager)