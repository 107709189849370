import React from 'react';
import { SzSpinner, SzIcon } from '@suezenv/react-theme-components';
import './mapPopup.scss'
import { NavLink } from 'react-router-dom';
import { AppUrls } from '../../../constants';
import { useTranslation } from 'react-i18next';
import VdMService from '../../../services/VdmService';

interface IMapPopup {
    request: any,
    interventions: any,
    loadingIntervention: boolean
}

const MapPopup = (props: IMapPopup) => {
    const { request, interventions, loadingIntervention } = props;
    const { t } = useTranslation();

    return (
        <div className="row map-popup">
            <div className="col">
                <div className="row">
                    <header className="col ml-4 mr-4 request">
                        <h3>{t('carto_popup_request_number', { number: request.id })}</h3>
                    </header>
                </div>
                <div className="row p-4">
                    <div className="col">
                        <div className="row">
                            <div className="col mb-4">
                                <label className="light-text"> {t("status")}</label>
                                <p className='m-0'>{t(request.statusId)}</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col mb-4">
                                <label className="light-text">{t("carto_popup_request_intervention", {
                                    number: interventions.length
                                })}</label>
                                <div className="row">
                                    <div className="col">
                                        {loadingIntervention ?

                                            <SzSpinner variant="primary" />
                                            :
                                            interventions.map((intervention: any) => {
                                                const status = VdMService.getStatus(intervention.statut)
                                                return (<div key={intervention.idintervention} className="row">
                                                    <div className="col-auto text-intervention">
                                                        {intervention.referenceg2} :
                                                    </div>
                                                    <div className="col-auto text-intervention">
                                                        {t(`INTERVENTION_STATUS_${status}`)}
                                                    </div>
                                                </div>
                                                )
                                            }
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col mb-4">
                                <label className="light-text">{t("requesterName")}</label>
                                <p className='mt-0'>{request.requesterName}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-4">
                                <label className="light-text">{t("address")}</label>
                                <p className='m-0'>{request.address}</p>
                            </div>
                        </div>
                        <div className="row justify-content-end">
                            <div className="col-auto">
                                <NavLink activeClassName="active"
                                    className="m-0"
                                    exact={true}
                                    to={AppUrls.VIEW_REQUEST.replace(':requestId', request.requestId)}
                                >
                                    <div className="row align-items-center">
                                        <div className="col-auto pr-0">
                                            {t("carto_popup_see_details")}
                                        </div>
                                        <div className="col-auto pl-4">
                                            <SzIcon icon='keyboard-arrow-right' variant="line" />
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MapPopup
