import React from "react";
import { SzButton } from "@suezenv/react-theme-components";
import './createForm.scss';
import { withTranslation } from 'react-i18next';
import { Status } from "../../../constants";


class Step5Form extends React.Component<any> {
    state = {
        isValid: true,
    };

    public render() {
        const { t, previousStepHandle, saveDraft, saveUserRequest, originalRequest, values, mobile } = this.props;
        const isDraft = values.id && values.currentStatus.status.id === Status.STATUS_DRAFT;
        const isAutoSavedDraft = values.id && !originalRequest.currentStatus.status.id;
        const showDraft = (isDraft || !values.id || isAutoSavedDraft);
        const labelPost = mobile ? t('post_request_btn_short') : t('post_request_btn');
        return (
            <>
                <div className="row mt-5" />
                <div className='row mt-5 step-actions'>
                    <div className='col-7 col-sm-5 col-md-3'>
                        <SzButton
                            className='previous-btn w-100'
                            icon="arrow-left"
                            type="button"
                            onClick={previousStepHandle}
                            variant="secondary">
                            {t('step_previous_btn', { number: 4 })}
                        </SzButton>
                    </div>
                    {showDraft &&
                        <div className="col-5 col-md-3 offset-md-3 d-none d-md-block">
                            <SzButton
                                className='draft-btn w-100'
                                onClick={() => saveDraft({})}
                                isDisabled={!this.state.isValid}
                                type="button"
                                variant="secondary">{t('draft_btn')}
                            </SzButton>
                        </div>
                    }
                    <div className={showDraft ? "col-5 col-md-3 col-sm-5 offset-sm-2 pb-3 offset-md-0" : "col-3 offset-6"}>
                        <SzButton
                            className='post_request_btn w-100'
                            isDisabled={!this.state.isValid}
                            onClick={saveUserRequest}
                            type="button"
                        >
                            {labelPost}
                        </SzButton>
                    </div>

                    {showDraft &&
                        <div className="col-12 pt-3 d-block d-md-none">
                            <SzButton
                              className='draft-btn w-100'
                              onClick={() => saveDraft({})}
                              isDisabled={!this.state.isValid}
                              type="button"
                              variant="secondary"
                            >
                                {t('draft_btn_full')}
                            </SzButton>
                        </div>
                    }
                </div>
            </>
        );
    }
}

export default withTranslation()(Step5Form);
