import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';

export const SzDropZone = (props: any) => {
    const { placeHolder, multiple, dropHandle, className, maxFiles = null, defaultFiles = [], accept = [], deleteHandle } = props;
    const [state, setState] = useState({ files: defaultFiles });
    const onDrop = (files: any) => {
        let filesList: any = multiple ? [...state.files, ...files] : files;
        filesList = (maxFiles && filesList.length > maxFiles) ? filesList.slice(0, maxFiles) : filesList;
        setState({ ...state, files: filesList });
        dropHandle(filesList);
    }
    const disabled = maxFiles && maxFiles <= state.files.length;
    const { getRootProps, getInputProps } = useDropzone({ multiple, onDrop, disabled, accept: accept.toString(',') });
    const deleteFileHandle = (index: number) => {
        const newFiles = [...state.files]
        newFiles.splice(index, 1);
        setState({ ...state, files: newFiles });
        deleteHandle(newFiles);
    }
    const files = state.files.map((file: any, index: number) => {
        // delete just not saved files
        const delLink = (file.id === undefined) ? <span className='delete-file' onClick={() => deleteFileHandle(index)}> X</span> : null;
        return (<li key={index}>
            {file.name} {delLink}
        </li >);
    });
    const classNames = classnames('dropzone', className)
    return (
        <>
            <div {...getRootProps({ className: classNames })}>
                <input {...getInputProps()} />
                <p>{placeHolder}</p>
            </div>
            <ul className="m-3 p-2 file-list">{files}</ul>
        </>
    );
}