import React from 'react';
import {getFormatedForm} from '../../../Models';
import {SzButton, SzDateRangePicker, SzModal} from '@suezenv/react-theme-components';
import {Form, Formik} from 'formik';
import step3Model from '../../../Models/createUserRequestModels/step3Model';
import {withTranslation} from 'react-i18next';
import * as Yup from 'yup';
import UserRequestsService from "../../../services/UserRequestsService";
import {connect} from "react-redux";

class DesiredDateField extends React.Component<any> {
  state: any = {
    isValid: false,
    desiredDate: null,
    draftDesiredDate: null,
    isConfirmEditModalShown: false,
    formTouched: false,
  };

  public constructor(props: any) {
    super(props);
  }

  public componentDidMount(): void {
    const {id, data} = this.props.userRequest;

    if (id !== null) {
      const dateTimeDesiredDate = new Date(data.desiredDate);
      this.setState({
        desiredDate: dateTimeDesiredDate,
      });
    }
  }

  public render(): JSX.Element {
    const [schema] = getFormatedForm({
      desiredDate: {
        ...step3Model.desiredDate,
        required: true,
        schema: Yup.string().required('required_validation'),
      },
    });

    return (
      <Formik
        validationSchema={schema}
        onSubmit={(): void => {
        }}
        initialValues={this.state}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({resetForm, setErrors}): JSX.Element => {
          schema.isValid(this.state)
            .then((isValid: boolean): void => {
              if (!this.state.isValid && isValid) {
                setErrors({});
                this.setState({isValid: isValid});
              }
            });

          return (
            <Form>
              <div className="col pl-0">
                {this.renderDesiredDate()}
                {this.renderConfirmUpdateModal(resetForm)}
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  private renderDesiredDate(): JSX.Element {
    return (
      <SzDateRangePicker
        onClickDay={(date: Date): void => {
          this.setState({
            isConfirmEditModalShown: !this.state.isConfirmEditModalShown,
            draftDesiredDate: date,
          });
        }}
        value={this.state.desiredDate}
        minDate={this.state.desiredDate}
        toggle={true}
      />
    );
  }

  private resetState(stateValues?: object): void {
    this.setState({
      ...stateValues,
      draftDesiredDate: null,
      isConfirmEditModalShown: false,
      isValid: false
    });
  }

  private renderConfirmUpdateModal(resetForm: Function): JSX.Element {
    const {t} = this.props;

    return (
      <SzModal
        size={'lg'}
        centered={true}
        show={this.state.isConfirmEditModalShown}
        title={t('edit_confirm_label')}
        handleClose={() => this.resetState()}
      >
        <div className="row justify-content-end">
          <div className="col-auto">
            <SzButton onClick={(): void => this.processSaveUserRequest(resetForm)}>
              {t('done_btn')}
            </SzButton>
          </div>
        </div>
      </SzModal>
    );
  };

  private processSaveUserRequest(resetForm: Function): void {
    const {currentUserRequest} = this.props;
    currentUserRequest.data.desiredDate = this.state.draftDesiredDate;
    UserRequestsService.putUserRequest(currentUserRequest).then((): void => {
      this.resetState({desiredDate: this.state.draftDesiredDate});
      resetForm();
    });
  }
}

const mapStateToProps = (state: any) => ({
  userRequest: state.userRequests.currentRequest.originalRequest,
  currentUserRequest: state.userRequests.currentRequest,
});

export default connect(
  mapStateToProps,
)(withTranslation()(DesiredDateField));
