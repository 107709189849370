import instanceRequest, { Request } from "./Request";

const NOTIFICATION_CONFIGURATION_URI = "/api/notification/settings";

class NotificationConfigurationService {

  private axiosService: Request;

  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }

  public getUserNotificationConfiguration(userId: string) {
    const url = `${NOTIFICATION_CONFIGURATION_URI}/${userId}`;
    return this.axiosService.get(url, Request.cancel).then((response) => {
      return response;
    });
  }

  public putUserNotificationConfiguration(conf: any, userId: string) {
    const url = `${NOTIFICATION_CONFIGURATION_URI}/${userId}`;
    return this.axiosService.put(url, conf).then((response) => {
      return response;
    });
  }

  public postUserNotificationConfiguration(conf: any) {
    const url = `${NOTIFICATION_CONFIGURATION_URI}`;
    return this.axiosService.post(url, conf).then((response) => {
      return response;
    });
  }

}

export default new NotificationConfigurationService(instanceRequest);
