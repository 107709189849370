import React, { PureComponent } from 'react';
import { SzTypographie } from '@suezenv/react-theme-components';
import { DateService } from '../../services/dateService';
import { withTranslation } from 'react-i18next';

class CommentList extends PureComponent<any> {
  constructor(props: any) {
    super(props);

    this.state = {
      currentRequestStatusList: props.currentRequestStatusList
    };
  }

  toTwoDigit(value: number) {
    return `0${value}`.slice(-2);
  }

  formatDate(stringdate: string) {
    const { t } = this.props;
    const date = DateService.stringToDate(stringdate);
    return t('comment_created_at', {
      date: `${this.toTwoDigit(date.getDate())}/${this.toTwoDigit(
        date.getMonth() + 1
      )}/${date.getFullYear()}`,
      time: `${this.toTwoDigit(date.getHours())}:${this.toTwoDigit(
        date.getMinutes()
      )}`
    });
  }

  render() {
    const { currentRequestStatusList } = this.props;

    return Object.values(currentRequestStatusList).map(
      (status: any, index: number) => {
        return (
          status.comment && (
            <div
              id={status.id}
              key={index}
              className=" p-1 pt-0 mt-0 ml-4 mr-4 border-bottom"
            >
              <SzTypographie variant="text">
                {this.formatDate(status.createdAt)}
              </SzTypographie>
              <SzTypographie variant="text">{`${status.applicationUser.firstName} ${status.applicationUser.lastName} `}</SzTypographie>
              <SzTypographie variant="text">{status.comment}</SzTypographie>
            </div>
          )
        );
      }
    );
  }
}

export default withTranslation()(CommentList);
