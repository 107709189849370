import * as Yup from 'yup';

export default {
  additionalReference: {
    schema: Yup.string(),
    label: 'additional_refence_form_label',
    icon: '',
    name: 'additionalReference',
    placeholder: '',
    required: false,
  },
  comment: {
    schema: Yup.string().max(2000),
    max: 2000,
    label: 'comment_form_label',
    icon: '',
    name: 'comment',
    type: 'textArea',
    placeholder: '',
    required: false,
    rows: 3,
  },
};
