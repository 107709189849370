import { Status } from '../constants';

export default class VdMService {
    public static getStatus(vdmStatus: string): string {
        switch(vdmStatus) {
            case "3":
                return Status.STATUS_PLANNED
            case "7":
                return Status.STATUS_ONGOING
            case "4":
            case "5":
                return Status.STATUS_DONE
            default:
                return ''
        }
    }
}