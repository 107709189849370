import * as Yup from 'yup';

export default {
    status: {
        schema: Yup.string().required('required_validation'),
        label: 'status',
        icon: '',
        name: 'status',
        type: 'select',
        placeholder: '',
        required: false
    },
    risk: {
        schema: Yup.string().required('required_validation'),
        label: 'risks',
        icon: '',
        name: 'risk',
        type: 'select',
        placeholder: '',
        required: false
    },
    category: {
        schema: Yup.string().required('required_validation'),
        label: 'category',
        icon: '',
        name: 'category',
        type: 'select',
        placeholder: '',
        required: false
    },
    subCategory: {
        schema: Yup.string().required('required_validation'),
        label: 'subCategory',
        icon: '',
        name: 'subCategory',
        type: 'select',
        placeholder: '',
        required: false
    },
    organisation: {
        schema: Yup.string().required('required_validation'),
        label: 'organisations',
        icon: '',
        name: 'organisation',
        type: 'select',
        placeholder: '',
        required: false
    },
    town: {
        schema: Yup.string().required('required_validation'),
        label: 'towns',
        icon: '',
        name: 'town',
        type: 'select',
        placeholder: '',
        required: false
    },
    operation: {
        schema: Yup.string().required('required_validation'),
        label: 'operation_type',
        icon: '',
        name: 'operation',
        type: 'select',
        placeholder: '',
        required: false
    },
    cleansing: {
        schema: Yup.string().required('required_validation'),
        label: 'cleasning_type',
        icon: '',
        name: 'cleansing',
        type: 'select',
        placeholder: '',
        required: false
    },
};
