import React from 'react'
import InterventionWidget from '../elements/interventionWidget';
interface IBlock4 {
    interventions: any
    user: any
    request: any
}
export const Block4 = (props: IBlock4) => {
    const renderInterventions = () => {
        return (
            <div className='m-4'>
                {
                    props.interventions && props.interventions.map((item: any) => {
                        const { referenceg2, libelletypeinterventionrealise, datedebutprevu, datefinprevu, statut } = item
                        return <InterventionWidget
                            key={referenceg2}
                            id={referenceg2}
                            label={libelletypeinterventionrealise}
                            beginDate={datedebutprevu}
                            endDate={datefinprevu}
                            status={statut}
                            user={props.user}
                            request={props.request}
                        ></InterventionWidget>
                    })
                }
            </div>
        )
    }
    return (
        renderInterventions()
    )
}
