import React, { useEffect, Suspense } from 'react';
import { ConnectedRouter }            from 'connected-react-router';
import { History }                    from 'history';
import { store }                      from './store';
import { Provider }                   from 'react-redux';
import { Route, Switch, Redirect }    from 'react-router-dom';
import { AuthenticateService }        from './services/AuthenticateService';
import NavigationManager              from './manager/navigation';
import CartographyPage                from './pages/cartography';
import CreateUserRequestPage          from './pages/createUserRequest';
import ViewRequestDetail              from './pages/viewRequestDetail';
import UserRequests                   from './pages/userRequests';
import Logout                         from './pages/logout';
import ErrorManager                   from './manager/error';
import { AppUrls, ErrorRoutes }       from './constants';
import ReactGA                        from 'react-ga';
import { config }                     from './config';
import { Theme }                      from '@suezenv/react-theme-components';
import MatomoLayout                   from "./components/matomo/matomoLayout";

interface IAppProps {
	history: History;
}

const AppRouter = ({ history }: IAppProps) => {
	useEffect(() => {
		if (config.googleAnalyticsId) {
			ReactGA.initialize(config.googleAnalyticsId);
			history.listen(location => {
				ReactGA.set({ page: location.pathname }); // Update the user's current page
				ReactGA.pageview(location.pathname); // Record a pageview for the given page
			});
		}
	});
	useEffect(() => {
		const pathName = history.location.pathname;
		const token = localStorage.getItem('token-atlas-pce');
		if (
			(!token || token === 'undefined') &&
			pathName !== ErrorRoutes.SSO_ERROR &&
			pathName !== ErrorRoutes.EXPIRED
		) {
			AuthenticateService.login();
		} else if (
			pathName !== ErrorRoutes.SSO_ERROR &&
			pathName !== ErrorRoutes.EXPIRED
		) {
			AuthenticateService.checkToken();
		}
	}, [history]);

	return (
		<Suspense fallback={<div></div>}>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<Theme.Provider brand={"EDTM"}>
						<NavigationManager>
							<MatomoLayout>
								<Switch>
									<Route exact path={AppUrls.LOGOUT} component={Logout}/>
									<Route
										exact
										path={AppUrls.HOME}
										render={() => <Redirect to={AppUrls.REQUESTS}/>}
									/>
									<Route exact path={AppUrls.REQUESTS} component={UserRequests}/>
									<Route
										exact
										path={AppUrls.CREATE_REQUEST}
										component={CreateUserRequestPage}
									/>
									<Route
										exact
										path={AppUrls.CARTOGRAPHY}
										component={CartographyPage}
									/>
									<Route
										path={AppUrls.VIEW_REQUEST}
										component={ViewRequestDetail}
									/>
									<Route
										exact
										path={[
											ErrorRoutes.NOT_FOUND,
											ErrorRoutes.ACCESS_DENIED,
											ErrorRoutes.OUPS,
											ErrorRoutes.SSO_ERROR,
											ErrorRoutes.EXPIRED
										]}
										component={ErrorManager}
									/>
									<Route render={() => <Redirect to={AppUrls.HOME}/>}/>
								</Switch>
							</MatomoLayout>
						</NavigationManager>
					</Theme.Provider>
				</ConnectedRouter>
			</Provider>
		</Suspense>
	);
};

export default AppRouter;
