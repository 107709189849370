declare global {
    interface Window {
        REACT_APP_ANALYTICS_KEY: any;
        REACT_APP_API_HOST: any;
        REACT_APP_SUPPORT_HOST: any;
        REACT_APP_USER_ADMIN: any;
        REACT_APP_ITV: any;
        REACT_APP_URGENT_REQUEST_PHONE_NUMBER: any;
        REACT_APP_URGENT_REQUEST_OPENING_TIME: any;
        REACT_APP_MATOMO_URL_BASE: string;
        REACT_APP_MATOMO_SET_SECURE_COOKIE: boolean;
        REACT_APP_MATOMO_DISABLED: boolean;
        REACT_APP_MATOMO_DIMENSION_CONTRACT_ID: number;
        REACT_APP_MATOMO_DIMENSION_SERVICE_ID: number;
        REACT_APP_MATOMO_SITE_ID: number;
    }
}
const url = new URL(window.location.href);

// force test env (use REACT_APP_API_TEST_HOST for api calls)
const testEnv = url.searchParams.get("test_env");
if (testEnv) {
    localStorage.setItem("test_env", testEnv);
}
const isTestEnv = localStorage.getItem("test_env");

let apiUrl = window.REACT_APP_API_HOST || process.env.REACT_APP_API_HOST;
let googleAnalyticsId = window.REACT_APP_ANALYTICS_KEY || process.env.REACT_APP_ANALYTICS_KEY;
let supportUrl = window.REACT_APP_SUPPORT_HOST || process.env.REACT_APP_SUPPORT_HOST;
let userAdminUrl = window.REACT_APP_USER_ADMIN || process.env.REACT_APP_USER_ADMIN;
let itvUrl = window.REACT_APP_ITV || process.env.REACT_APP_ITV;
let urgentRequestPhoneNumber = window.REACT_APP_URGENT_REQUEST_PHONE_NUMBER || process.env.REACT_APP_URGENT_REQUEST_PHONE_NUMBER;
let urgentRequestOpeningTime = window.REACT_APP_URGENT_REQUEST_OPENING_TIME || process.env.REACT_APP_URGENT_REQUEST_OPENING_TIME;

// MATOMO CONFIG
let matomoUrlBase = window.REACT_APP_MATOMO_URL_BASE || process.env.REACT_APP_MATOMO_URL_BASE || '';
let matomoSiteId = (window.REACT_APP_MATOMO_SITE_ID || process.env.REACT_APP_MATOMO_SITE_ID) as number;
let matomoSetSecureCookie = (window.REACT_APP_MATOMO_SET_SECURE_COOKIE || process.env.REACT_APP_MATOMO_SET_SECURE_COOKIE) === "true";
let matomoDisabled = (window.REACT_APP_MATOMO_DISABLED || process.env.REACT_APP_MATOMO_DISABLED) === "true";
let matomoDimensionContractId = window.REACT_APP_MATOMO_DIMENSION_CONTRACT_ID || process.env.REACT_APP_MATOMO_DIMENSION_CONTRACT_ID;
let matomoDimensionServiceId = window.REACT_APP_MATOMO_DIMENSION_SERVICE_ID || process.env.REACT_APP_MATOMO_DIMENSION_SERVICE_ID;

if (process.env.REACT_APP_API_TEST_HOST && isTestEnv && 1 === parseInt(isTestEnv)) {
    apiUrl = process.env.REACT_APP_API_TEST_HOST;
}

export const config = {
    apiUrl: apiUrl,
    googleAnalyticsId: googleAnalyticsId,
    supportUrl: supportUrl,
    userAdminUrl: userAdminUrl,
    itvUrl: itvUrl,
    urgentRequestPhoneNumber: urgentRequestPhoneNumber,
    urgentRequestOpeningTime: urgentRequestOpeningTime,
    matomoUrlBase: matomoUrlBase,
    matomoSetSecureCookie: matomoSetSecureCookie,
    matomoSiteId: matomoSiteId,
    matomoDisabled: matomoDisabled,
    matomoDimensionContractId: matomoDimensionContractId,
    matomoDimensionServiceId: matomoDimensionServiceId
};
