import * as Yup from 'yup';
import step4Model from './createUserRequestModels/step4Model';

export function getFormatedForm(model: any, defaultValues = []) {
  const schema = Yup.object().shape(
    ObjectFromEntries(
      Object.entries(model).map((value: any) => {
        return [value[0], value[1].schema];
      })
    )
  );
  return [schema];
}

export function getStep4Model(state: any) {
  step4Model.additionalReference.required = false;
  step4Model.comment.required = false;

  return step4Model;
}

const ObjectFromEntries = (entries: any): any => {
  let ret = [];
  for (let i = 0; i < entries.length; ++i) {
    ret[entries[i][0]] = entries[i][1];
  }
  return { ...ret };
};

export { default as step1Model } from './createUserRequestModels/step1Model';
export { default as step2Model } from './createUserRequestModels/step2Model';
export { default as step3Model } from './createUserRequestModels/step3Model';
