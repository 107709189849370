import * as Yup from 'yup';

export default {
    address: {
        schema: Yup.string().trim().required('required_validation'),
        label: '',
        name: 'address',
        placeholder: '',
        required: true

    },
    city: {
        schema: Yup.string().required('required_validation'),
        label: 'city',
        icon: '',
        name: 'city',
        placeholder: '',
        required: true
    },
    street: {
        schema: Yup.string().required('required_validation'),
        label: 'street',
        icon: '',
        name: 'street',
        placeholder: '',
        required: true
    },
    number: {
        schema: Yup.string(),
        label: 'street_Number',
        icon: '',
        name: 'number',
        placeholder: '',
        required: false
    },
    latitude: {
        schema: Yup.string(),
        label: 'lat',
        icon: '',
        name: 'latitude',
        placeholder: '',
        required: false
    },
    longitude: {
        schema: Yup.string(),
        label: 'long',
        icon: '',
        name: 'longitude',
        placeholder: '',
        required: false
    },
    addressAdditionalInformation: {
        schema: Yup.string(),
        label: 'additional_address',
        icon: '',
        name: 'addressAdditionalInformation',
        placeholder: '',
        maxlength: 50, // Not supported by SDS now, keep it just for fun.
        required: false
    },
};
