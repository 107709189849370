import { Constants } from "../constants";
// actions 
export const cordinatesToAddressAction = (payload: any) => ({
    type: Constants.COORDINATES_TO_ADDRESS,
    payload: payload
});

export const autoCompleteAddressAction = (payload: any) => ({
    type: Constants.AUTO_COMPLETE_ADDRESS,
    payload: payload
});
