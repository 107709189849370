import { savePDF } from '@progress/kendo-react-pdf';

class PdfService {
    createPdf = (html: any, name: string) => {
        savePDF(html, {
            scale: 0.5,
            paperSize: 'A4',
            fileName: name,
            margin: 6
        })
    }
}

const Doc = new PdfService();
export default Doc;