import React from 'react';
import ViewRequestDetailManager from '../../manager/Detail/ViewRequestDetailManager';
import './index.scss';
import { withRouter } from 'react-router-dom';

class ViewRequestDetail extends React.Component<any>  {
    render() {
        const requestId = this.props.match.params['requestId'];
        return (
            <>
                <ViewRequestDetailManager history={this.props.history} requestId={requestId} />
            </>
        )
    }
}

export default withRouter(ViewRequestDetail);
