import React from 'react';
import {
  SzButton,
  SzInput,
  SzTypographie,
  SzAlert
} from '@suezenv/react-theme-components';
import './createForm.scss';
import { withTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import step4Model from '../../../Models/createUserRequestModels/step4Model';
import { getFormatedForm, getStep4Model } from '../../../Models';
import { TextArea } from '../../elements/textArea';
import { Status } from '../../../constants';

class Step4Form extends React.Component<any> {
  state = {
    isNextStepDisabled: false,
    isValid: false,
    additionalReference: '',
    comment: '',
    textAreaCounter: 0,
    mobile: false,
  };

  componentWillMount() {
    const { values } = this.props;
    const { data, comment, id } = values;
    const additionalReference = data.additionalReference
      ? data.additionalReference
      : '';
    if (id) {
      this.setState({
        additionalReference,
        comment,
      });
      return;
    }
  }

  componentDidMount() {
    this.setState({
      mobile: window.innerWidth < 768
    });
    window.addEventListener('resize', () => {
      this.setState({
        mobile: window.innerWidth < 768
      });
    }, false);
  }

  changeHandle(e: any) {
    const { comment } = step4Model;
    let update = {
      ...this.state,
      [e.target.name]: e.target.value,
    };
    if (e.target.name === comment.name) {
      update.textAreaCounter = e.target.value.length;
    }
    this.setState(update);
  }

  tempNextStepDisabled(isNextStepDisabled: boolean) {
    this.setState({ isNextStepDisabled: isNextStepDisabled });
    setTimeout(() => {
      this.setState({ isNextStepDisabled: !isNextStepDisabled });
    }, 2000);
  }

  public render() {
    const [schema] = getFormatedForm(getStep4Model(this.state));
    const {
      t,
      nextStepHandle,
      saveDraft,
      previousStepHandle,
      values,
    } = this.props;
    const { isNextStepDisabled, textAreaCounter } = this.state;
    const isDraft =
      values.id && values.currentStatus.status.id === Status.STATUS_DRAFT;
    const isAutoSavedDraft = values.id && !values.currentStatus.status.id;
    const showDraft = isDraft || !values.id || isAutoSavedDraft;

    return (
      <>
        <Formik
          validationSchema={schema}
          object
          onSubmit={() => {}}
          initialValues={this.state}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ submitForm, setErrors }) => {
            const {
              additionalReference,
              comment,
            } = step4Model;
            schema.isValid(this.state).then((isValidForm: any) => {
              if (isValidForm !== this.state.isValid) {
                setErrors({});
                this.setState({ isValid: isValidForm });
              }
            });
            const nextStep = () => {
              if (!this.state.isValid) {
                submitForm();
              } else {
                this.tempNextStepDisabled(true);
                nextStepHandle(this.state);
              }
            };
            const borderedClassName = this.state.mobile ? 'col-12 d-md-flex d-block' : 'items-bordered col-12 d-md-flex d-block';
            return (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!this.state.isValid) {
                    submitForm();
                  } else {
                    saveDraft(this.state);
                  }
                }}
              >
                <div className="row box">
                  <div className="col-12">
                    <SzTypographie className="mb-5" variant="text">
                      {t('step4_title_text')}
                    </SzTypographie>
                  </div>
                  <div className={borderedClassName}>
                    <div className="col-md-6 flex-fill mb-4">
                      <div>
                        <SzInput
                          onChange={this.changeHandle.bind(this)}
                          label={t(additionalReference.label)}
                          name={additionalReference.name}
                          icon={additionalReference.icon}
                          required={additionalReference.required}
                          placeholder={additionalReference.placeholder}
                          value={this.state.additionalReference}
                        />
                      </div>
                      <div>
                        <TextArea
                          onChange={this.changeHandle.bind(this)}
                          label={`${t(comment.label)}  ${t(
                            'COMMENT_CARACTERS_COUNT',
                            {
                              count: textAreaCounter,
                              total: comment.max,
                            }
                          )}`}
                          name={comment.name}
                          icon={comment.icon}
                          required={comment.required}
                          placeholder={comment.placeholder}
                          type={comment.type}
                          value={this.state.comment}
                          rows={comment.rows}
                        />
                        {textAreaCounter > comment.max ? (
                          <div>
                            <SzAlert variant="danger">
                              {t('COMMENT_ERROR_MAX_VALUE')}
                            </SzAlert>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5 step-actions z-step-actions">
                  <div className="col-7 col-sm-5 col-md-3">
                    <SzButton
                      icon="arrow-left"
                      onClick={previousStepHandle}
                      className="previous-btn w-100"
                      variant="secondary"
                    >
                      {t('step_previous_btn', { number: 3 })}
                    </SzButton>
                  </div>
                  <div
                    className={
                      showDraft ? 'col-5 col-sm-5 offset-sm-2 col-md-2 offset-md-4' : 'col-5 col-sm-5 offset-sm-2 col-md-2 offset-md-7'
                    }
                  >
                    <SzButton
                      className="next-btn w-100"
                      isDisabled={!this.state.isValid || isNextStepDisabled}
                      onClick={nextStep}
                    >
                      {t('next_btn')}
                    </SzButton>
                  </div>
                  {showDraft && (
                    <>
                      <div className="col-sm-5 col-md-3 d-none d-md-block">
                        <SzButton
                          className="draft-btn w-100"
                          isDisabled={!this.state.isValid}
                          type="submit"
                          variant="secondary"
                        >
                          {t('draft_btn')}
                        </SzButton>
                      </div>
                      <div className="col-12 pt-3 d-block d-md-none">
                        <SzButton
                          className="draft-btn w-100"
                          isDisabled={!this.state.isValid}
                          type="submit"
                          variant="secondary"
                        >
                          {t('draft_btn_full')}
                        </SzButton>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(Step4Form);
