import React from "react";
import { getFormatedForm } from "../../../Models";
import step1Model from "../../../Models/createUserRequestModels/step1Model";
import { Formik, Form } from "formik";
import { debounce } from "lodash";
import {
  SzInput,
  SzButton,
  SzAlert,
  SzBox,
  SzTypographie,
} from "@suezenv/react-theme-components";
import "./createForm.scss";
import { MapGeoCodeService } from "../../../services/MapGeoCodeService";
import AutoCompleteItemsComponent from "../../autoCompleteItems";
import { withTranslation } from "react-i18next";
import MapManager from "../../../manager/createUserRequest/MapManager";
import { Status, requestAcceptedMimeTypes } from "../../../constants";
import { SzDropZone } from "../../elements/SzDropZone";
import { RequestAttachmentType } from "../../../constants";
import UserRequestsService from "../../../services/UserRequestsService";

const MAX_UPLOAD_FILES = 1;
const debounceDelay = 500;

class Step1Form extends React.Component<any> {
  state = {
    address: "",
    showAutoCompleteItems: true,
    city: "",
    street: "",
    number: "",
    latitude: "",
    longitude: "",
    addressAdditionalInformation: "",
    isAutoComplete: false,
    isValid: false,
    attachments: [],
    isOptionVisible: false,
    inContratArea: null,
    inUserArea: null,
    isGeocodeOk: true,
  };

  constructor(props: any) {
    super(props);
    this.autocompleteSearch = debounce(
      this.autocompleteSearch.bind(this),
      debounceDelay
    );
    this.autoCompleteHandle = this.autoCompleteHandle.bind(this);
  }

  dropHandle(files: any) {
    const file = files[0];
    if (file) {
      this.setState({
        attachments: [
          { file: file, type: RequestAttachmentType.LOCATION_PLAN },
        ],
      });
    }
  }

  deleteHandle() {
    this.setState({ attachments: [] });
  }

  componentDidMount() {
    const { values, currentStep } = this.props;
    const { address, data, id } = values;
    data.addressAdditionalInformation = data.addressAdditionalInformation
      ? data.addressAdditionalInformation
      : "";
    if (id) {
      // set empty string if attribute is null
      Object.keys(address).map((key) => {
        if (!address[key]) {
          address[key] = "";
        }
        return address;
      });
      this.setState({
        ...address,
        addressAdditionalInformation: data.addressAdditionalInformation,
        showAutoCompleteItems: false,
      });

      // If is the first step
      if (0 === currentStep) {
        UserRequestsService.getPerimeter(
          address.latitude,
          address.longitude
        ).then((response: any) => {
          this.setState({
            inContratArea: response.data.within_contract_area,
            inUserArea: response.data.within_user_area,
          });
        });
      }
    }
  }

  changeHandle(e: any) {
    let newState: any = { [e.target.name]: e.target.value };

    if ("address" === e.target.name && 0 === e.target.value.length) {
      newState.isAutoComplete = true;
    }

    this.setState(newState);
  }

  autocompleteSearch(value: string) {
    MapGeoCodeService.autoCompleteAddress(value).catch(() => {
      this.setState({ isGeocodeOk: false });
      // This previous call is cancelled as new call is launched
    });
  }

  autoCompleteHandle(e: any) {
    this.setState({ isAutoComplete: true });
    this.changeHandle(e);
    this.setState({ showAutoCompleteItems: true });

    this.autocompleteSearch(e.target.value);
  }

  autoCompleteClickHandle(
    lat: any,
    lng: any,
    street: any,
    houseNumber: any,
    postcode: any,
    city: any,
    address: any
  ) {
    this.setState({
      isAutoComplete: false,
      inContratArea: null,
      inUserArea: null,
    });
    MapGeoCodeService.autoCompleteItemToAddress(
      lat,
      lng,
      street,
      houseNumber,
      postcode,
      city,
      address
    );
    UserRequestsService.getPerimeter(lat, lng).then((response: any) => {
      this.setState({
        inContratArea: response.data.within_contract_area,
        inUserArea: response.data.within_user_area,
      });
    });
    this.setState({ showAutoCompleteItems: false });
  }

  componentDidUpdate(prevProps: any) {
    const { address } = this.props.values;

    // Always sync address
    if (address !== prevProps.values.address) {
      this.setState({
        ...address,
      });
    }

    if (
      !this.state.isAutoComplete &&
      address.address &&
      this.props !== prevProps
    ) {
      this.setState({
        showAutoCompleteItems: false,
      });
      if (0 === this.props.currentStep) {
        UserRequestsService.getPerimeter(
          address.latitude,
          address.longitude
        ).then((response: any) => {
          this.setState({
            inContratArea: response.data.within_contract_area,
            inUserArea: response.data.within_user_area,
          });
        });
      }
    }
  }

  public render() {
    const [schema] = getFormatedForm(step1Model);
    const {
      addressAutoComplete,
      t,
      nextStepHandle,
      saveDraft,
      values,
      locationPlan,
    } = this.props;
    const defaultFiles = locationPlan ? [locationPlan.file] : [];
    const isDraft =
      values.id && values.currentStatus.status.id === Status.STATUS_DRAFT;
    const isAutoSavedDraft = values.id && !values.currentStatus.status.id;
    const showDraft = isDraft || !values.id || isAutoSavedDraft;
    return (
      <>
        <Formik
          validationSchema={schema}
          onSubmit={() => {}}
          initialValues={this.state}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ submitForm, handleChange, errors, setFieldError, setErrors }) => {
            const {
              address,
              city,
              number,
              street,
              latitude,
              longitude,
              addressAdditionalInformation,
            } = step1Model;

            schema.isValid(this.state).then((isValidForm: any) => {
              if (isValidForm !== this.state.isValid) {
                setErrors({});
                this.setState({ isValid: isValidForm });
              }
            });

            const customHandleChange = (e: any) => {
              this.autoCompleteHandle(e);
              handleChange(e);
            };

            const customHandleBlur = (e: any) => {
              let targetName: string = e.target.name;
              this.setState({ changing: false });
              schema
                .validateAt(targetName, {
                  ...this.state,
                  [targetName]: e.target.value,
                })
                .then(() => {
                  const newErrors = { ...errors };
                  deleteFromObject(targetName, newErrors);
                  setErrors(newErrors);
                })
                .catch((error: any) => {
                  const { path, message } = error;
                  setFieldError(path, message);
                });
            };

            const deleteFromObject = (toDeleteKey: string, object: any) => {
              for (let index in object) {
                if (index === toDeleteKey) {
                  delete object[index];
                }
              }
            };

            const nextStep = () => {
              if (!this.state.isValid) {
                submitForm();
              } else {
                nextStepHandle(this.state);
              }
            };

            const isDisabled = () => {
              return (
                !this.state.isValid ||
                !this.state.inContratArea ||
                !this.state.isGeocodeOk
              );
            };
            return (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!this.state.isValid) {
                    submitForm();
                  } else {
                    saveDraft(this.state);
                  }
                }}
              >
                <SzBox className="row box" tag="div">
                  <div id="main-inputs" className="col-12 col-md-6">
                    <div className="search-address mb-5">
                      <label
                        className="sz-form-group__label sz-line-height-normal mb-1 d-block d-md-none"
                        htmlFor="address"
                      >
                        <p className="font-family-bold">
                          {t("step1_label_md")}
                        </p>
                        <br />
                        <p className="font-family-bold">
                          {t("search_the_address")}
                        </p>
                      </label>
                      <SzInput
                        autocomplete
                        onChange={customHandleChange}
                        onBlur={customHandleBlur}
                        label={t(address.label)}
                        name={address.name}
                        icon={"search"}
                        className={"bg-white input-address"}
                        required={address.required}
                        placeholder={address.placeholder}
                        value={this.state.address}
                      />
                      {!this.state.isGeocodeOk && (
                        <SzAlert variant="danger">{t("geocode_nok")}</SzAlert>
                      )}
                      {this.state.showAutoCompleteItems && (
                        <AutoCompleteItemsComponent
                          addressAutoComplete={addressAutoComplete}
                          autoCompleteClickHandle={this.autoCompleteClickHandle.bind(
                            this
                          )}
                        />
                      )}
                      {this.state.inContratArea === false && (
                        <SzAlert variant="danger">
                          <>{t("NOT_IN_CONTRACT_AREA")}</>
                        </SzAlert>
                      )}
                      {this.state.inContratArea === true &&
                        this.state.inUserArea === false && (
                          <div className="alert-warning">
                            <SzAlert variant="danger">
                              <>{t("NOT_IN_USER_AREA")}</>
                            </SzAlert>
                          </div>
                        )}
                    </div>

                    <div>
                      <SzInput
                        onChange={() => {}}
                        label={t(city.label)}
                        name={city.name}
                        onBlur={customHandleBlur}
                        icon={city.icon}
                        className={"input-address-readonly"}
                        required={city.required}
                        placeholder={city.placeholder}
                        value={this.state.city}
                        readOnly
                      />
                      {errors["city"] && (
                        <SzAlert variant="danger">
                          <>{t(errors["city"])}</>
                        </SzAlert>
                      )}
                    </div>
                    <div>
                      <SzInput
                        onChange={() => {}}
                        onBlur={customHandleBlur}
                        label={t(street.label)}
                        name={street.name}
                        icon={street.icon}
                        className={"input-address-readonly"}
                        required={street.required}
                        placeholder={street.placeholder}
                        value={this.state.street}
                        readOnly
                      />
                      {errors["street"] && (
                        <SzAlert variant="danger">
                          <>{t(errors["street"])}</>
                        </SzAlert>
                      )}
                    </div>
                    <div>
                      <SzInput
                        onChange={() => {}}
                        label={t(number.label)}
                        name={number.name}
                        icon={number.icon}
                        className={"input-address-readonly"}
                        required={number.required}
                        placeholder={number.placeholder}
                        value={this.state.number ? this.state.number : ""}
                        readOnly
                      />
                    </div>
                    <div>
                      <SzInput
                        onChange={this.changeHandle.bind(this)}
                        label={t(addressAdditionalInformation.label)}
                        name={addressAdditionalInformation.name}
                        icon={addressAdditionalInformation.icon}
                        className={"bg-white input-address"}
                        required={addressAdditionalInformation.required}
                        placeholder={addressAdditionalInformation.placeholder}
                        value={this.state.addressAdditionalInformation}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <MapManager />
                  </div>
                </SzBox>
                <SzBox className="row box" tag="div">
                  <div id="option-container" className="col">
                    <div
                      id="option-controller"
                      className="row justify-content-end"
                    >
                      <div className="col-auto">
                        <SzButton
                          className="control-collapse"
                          variant="tertiary"
                          icon={
                            this.state.isOptionVisible
                              ? "subtract-square"
                              : "add-square"
                          }
                          alignIcon="right"
                          onClick={() =>
                            this.setState({
                              isOptionVisible: !this.state.isOptionVisible,
                            })
                          }
                        >
                          {t(
                            this.state.isOptionVisible
                              ? "hide_options"
                              : "show_options"
                          )}
                        </SzButton>
                      </div>
                    </div>
                    {this.state.isOptionVisible && (
                      <div className="row">
                        <SzBox
                          id="drop-zone"
                          className="pr-5 pr-5 col-md-5"
                          tag="div"
                        >
                          <SzTypographie variant="text">
                            {t("location_plan")}
                          </SzTypographie>
                          <SzDropZone
                            defaultFiles={defaultFiles}
                            maxFiles={MAX_UPLOAD_FILES}
                            dropHandle={this.dropHandle.bind(this)}
                            multiple={false}
                            deleteHandle={this.deleteHandle.bind(this)}
                            placeHolder={t("drop_location_plan")}
                            accept={requestAcceptedMimeTypes}
                          />
                        </SzBox>
                        <SzBox id="location" className="p-2 col-md-7" tag="div">
                          <div className="row">
                            <div className="col-sm-6">
                              <SzInput
                                onChange={() => {}}
                                label={t(latitude.label)}
                                name={latitude.name}
                                icon={latitude.icon}
                                required={latitude.required}
                                placeholder={latitude.placeholder}
                                value={this.state.latitude}
                              />
                            </div>
                            <div className="col-sm-6">
                              <SzInput
                                onChange={() => {}}
                                label={t(longitude.label)}
                                name={longitude.name}
                                icon={longitude.icon}
                                required={longitude.required}
                                placeholder={longitude.placeholder}
                                value={this.state.longitude}
                              />
                            </div>
                          </div>
                        </SzBox>
                      </div>
                    )}
                  </div>
                </SzBox>
                <div className="row step-actions">
                  <div
                    className={
                      showDraft
                        ? "col-6 offset-6 col-sm-5 offset-sm-2 col-md-2 offset-md-7"
                        : "col-6 offset-6 col-md-2 offset-md-10"
                    }
                  >
                    <SzButton
                      className="next-btn w-100"
                      isDisabled={isDisabled()}
                      onClick={nextStep}
                    >
                      {t("next_btn")}
                    </SzButton>
                  </div>
                  {showDraft && (
                    <>
                      <div className="col-sm-5 col-md-3 d-none d-sm-block">
                        <SzButton
                          className="draft-btn w-100"
                          isDisabled={isDisabled()}
                          type="submit"
                          variant="secondary"
                        >
                          {t("draft_btn")}
                        </SzButton>
                      </div>
                      <div className="col-12 pt-3 d-block d-sm-none">
                        <SzButton
                          className="draft-btn w-100"
                          isDisabled={isDisabled()}
                          type="submit"
                          variant="secondary"
                        >
                          {t("draft_btn_full")}
                        </SzButton>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(Step1Form);
