import { Constants } from "../constants";
import { AppActions } from "../types";

export const loadingSpinner = (value: boolean): AppActions => {
    return {
        type: Constants.DATA_LOADING,
        payload: {
            loading: value
        }
    }
}