import React from "react";
import { SzTypographie } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { config } from "../../../config";
import "./navUrgentRequest.scss";

const NavUrgentRequest = (props: any) => {
    const { t } = useTranslation();
    const { closeMenuHandler } = props;

    return (
        <>
            <div className="main-overlay" onClick={closeMenuHandler}/>
            <nav className="container-fluid nav-urgent-request">
                <div className="row">
                    <header className="col">
                        <SzTypographie variant="text">{t('urgent_request')}</SzTypographie>
                    </header>
                </div>
                <div className="row">
                    <div className="col py-4 px-5">
                        <SzTypographie variant="caption">{t('urgent_request_phone_number')}</SzTypographie>
                        <SzTypographie variant="text">{config.urgentRequestPhoneNumber}</SzTypographie>
                    </div>
                </div>
                <div className="row">
                    <div className="col py-4 px-5">
                        <SzTypographie variant="caption"
                                       className="label">{t('urgent_request_opening_time')}</SzTypographie>
                        <SzTypographie variant="text">{config.urgentRequestOpeningTime}</SzTypographie>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default NavUrgentRequest;
