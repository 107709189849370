import "./mapComponent.scss";
import React, { useRef, useEffect } from "react";
import { Map, ScaleControl, LatLng, GeoJSON, ZoomControl } from 'react-leaflet';
import { svgZoomIn, svgZoomOut } from './icons';

interface IMapComponent {
    children: any,
    geoShapes: any,
    center: LatLng,
    zooms: any,
    maxBounds?: LatLng[],
    onClick?: Function,
    disable?: boolean,
    ref?: any,
}

const MapComponent = (props: IMapComponent) => {
    const { children, geoShapes, center, zooms, maxBounds, onClick, disable } = props;
    const mapRef = useRef<any>();
    const geoJsonRef = useRef<any>();

    let enabled = !disable;
    useEffect(() => {
        if (mapRef.current && geoJsonRef.current) {
            if (Object.keys(geoJsonRef.current.leafletElement.getBounds()).length > 0) {
                mapRef.current.leafletElement.fitBounds(geoJsonRef.current.leafletElement.getBounds());
            }
        }
    }, [geoShapes]);

    return (
        <div className='my-map'>
            <Map
                ref={mapRef}
                center={center}
                {...zooms}
                maxBounds={maxBounds}
                onclick={onClick}
                zoomControl={false}
                scrollWheelZoom={enabled}
                dragging={enabled}
            >
                {children}

                {geoShapes && <GeoJSON
                    ref={geoJsonRef}
                    key={Math.floor(Math.random() * (1000 + 1))}
                    data={geoShapes}
                    style={(feature: any) => ({
                        color: '#4689F7',
                        weight: 1.5,
                        fillOpacity: 0.1,
                        strokeWidth: "1",
                    })}>
                </GeoJSON>}

                {enabled &&
                    <ScaleControl
                      position="bottomright"
                      imperial={false}
                    />
                }
                {enabled &&
                    <ZoomControl
                    position='bottomright'
                    zoomInText={svgZoomIn}
                    zoomOutText={svgZoomOut}
                    />
                }
            </Map>
        </div>
    );
};

export default MapComponent;
