import React, { useState } from 'react';
import SzPopover from '../elements/SzPopover';
import { SzDropZone } from '../elements/SzDropZone';
import { SzTypographie, SzBox, SzButton } from '@suezenv/react-theme-components';
import {
  RequestAttachmentType,
  requestAcceptedMimeTypes
} from '../../constants';
import { useTranslation } from 'react-i18next';
import './UploadAttachment.scss';

const MAX_UPLOAD_FILES = 1;

const UploadAttachment = (props: any) => {
  const [attachments, setAttachments] = useState({});
  const { t } = useTranslation();
  const { loadingAttachment, uploadAttachmentHandle } = props;

  const dropHandle = (files: any) => {
    const file = files[0];
    if (file) {
      setAttachments({ file: file, type: RequestAttachmentType.OTHER_TYPE });
    }
  };
  const deleteHandle = () => {
    setAttachments({});
  };

  const renderAttachmentPopover = () => {
    return (
      <div className="flex-fill">
        <SzTypographie variant="text">{t('drop_file_title')}</SzTypographie>
        <SzBox tag="div">
          <SzDropZone
            defaultFiles={[]}
            maxFiles={MAX_UPLOAD_FILES}
            dropHandle={dropHandle}
            deleteHandle={deleteHandle}
            multiple={false}
            placeHolder={t('drop_files')}
            accept={requestAcceptedMimeTypes}
          />
        </SzBox>
        <div className="d-flex justify-content-center ">
          <SzButton
            loader={loadingAttachment}
            isDisabled={!Object.keys(attachments).length || loadingAttachment}
            onClick={() => uploadAttachmentHandle(attachments)}
          >
            {t('done_btn')}
          </SzButton>
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex align-items-center justify-content-end">
      <SzPopover
        buttonLabel={t('add_attachment_label')}
        placement="bottom"
        variant="secondary"
        icon={'attachment'}
        btnClassName="border-0 add-attachment"
        onEnterHandle={() => setAttachments({})}
      >
        {renderAttachmentPopover()}
      </SzPopover>
    </div>
  );
};

export default UploadAttachment;
