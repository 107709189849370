import React, { useState } from 'react';
import { SzIcon, SzModal, SzButton } from '@suezenv/react-theme-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import UserRequestsService from '../../../services/UserRequestsService';
import './deleteRequestBtn.scss';

const DeleteRequestBtn = (props: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isModalShown, setisModalShown] = useState(false);
  const onClickHandle = () => {
    setisModalShown(true);
  };

  const cancelRequest = () => {
    const { userRequestId } = props;
    UserRequestsService.deleteUserRequest(userRequestId, history);
  };

  return (
    <div>
      <SzIcon
        className="delete-request-bin"
        icon="bin-2-alternate"
        variant="line"
        onClick={onClickHandle}
      />
      <SzModal
        className="delete-modal"
        size={'xl'}
        handleClose={() => {
          setisModalShown(false);
        }}
        show={isModalShown}
        title={t('cancel_user_request_title')}
      >
        <div className="row justify-content-end">
          <div className="col-auto">
            <SzButton
              variant="primary"
              icon={'remove'}
              onClick={() => {
                cancelRequest();
              }}
            >
              {t('delete_modal_delete')}
            </SzButton>
          </div>
          <div className="col-auto">
            <SzButton
              variant="secondary"
              onClick={() => {
                setisModalShown(false);
              }}
            >
              {t('delete_modal_cancel')}
            </SzButton>
          </div>
        </div>
      </SzModal>
    </div>
  );
};

export default DeleteRequestBtn;
