import { Constants } from "../constants";
import { ILoading, LoadingActionTypes } from "../types";

const initialState: ILoading = {
    loading: false
};
const loadingReducers = (state = initialState, action: LoadingActionTypes): ILoading => {
    switch (action.type) {
        case Constants.DATA_LOADING:
            return { ...state, loading: action.payload.loading }
        default:
            return state
    }
}
export default loadingReducers