import { SzTypographie, SzBox, SzButton, SzTab } from '@suezenv/react-theme-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextArea } from '../elements/textArea';
import classnames from 'classnames';
import "./Block5.scss";
import { Status, Roles } from '../../constants';
import CommentList from './CommentList';
import UserService from '../../services/UserService';
import commentService from '../../services/commentService';
import { DateService } from '../../services/dateService';
import UploadAttachment from './UploadAttachment';

export const Block5 = (props: any) => {
    const { t } = useTranslation();
    const [state, setState] = useState({ comment: '', showBtnSaveComment: false });
    const [tab, setTab] = useState("tab-0");
    const changeHandle = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value, showBtnSaveComment: (e.target.value ? true : false) });

    };
    const sortBydate = (DateA: any, DateB: any) => {
        return DateService.stringToDate(DateA.createdAt).getTime() < DateService.stringToDate(DateB.createdAt).getTime() ? 1 : -1;
    };
    const { saveCommentHandle, currentRequestStatusList, userRequest, user, loadingAttachment, uploadAttachmentHandle } = props;

    // BEGIN show add attachment button
    const { currentStatus } = userRequest;
    const editAllowOnStatus = [Status.STATUS_TO_QUALIFY, Status.STATUS_TO_SPECIFY];
    const allowAddAttachment = !(~editAllowOnStatus.indexOf(currentStatus.status.id));
    // END show add attachment button

    currentRequestStatusList.reverse() // show comments from newer to older

    const statusId = userRequest.currentStatus.status.id;
    const isClosed = (statusId === Status.STATUS_CLOSED || statusId === Status.STATUS_CLOSED_REJECTED);
    const saveComment = (roles: string[]) => {
        saveCommentHandle(state.comment, roles).then(() => {
            setState({
                ...state, comment: '', showBtnSaveComment: false
            })
        });
    };
    const commentWidget = (roles: string[], comments: any) => {
        return (
            <SzBox className="d-flex detail-block5 pb-2 flex-column infobox m-0 p-0" tag="div">
                {!isClosed &&
                    <div className={commentInputsDiv}>
                        <TextArea
                            className='request-comment  p-0'
                            onChange={changeHandle}
                            label=''
                            placeholder={t('comment_placeholder')}
                            name="comment"
                            type='textArea'
                            value={state.comment}
                            bgLight={false}
                        />
                        {state.showBtnSaveComment &&
                            <SzBox className="d-flex m-0 justify-content-end align-items-center" tag="div">
                                <div>
                                    <SzButton onClick={() => saveComment(roles)}>{t('add_comment_btn')}</SzButton>
                                </div>
                            </SzBox>
                        }
                    </div>
                }
                <CommentList currentRequestStatusList={comments} />
            </SzBox>
        )
    }
    const SuezCommentWidget = () => {
        const sharedComments = currentRequestStatusList.filter((el: any) => el.comment && !commentService.isSuez(el.commentRole)).sort(sortBydate)
        const privateComments = currentRequestStatusList.filter((el: any) => el.comment && commentService.isSuez(el.commentRole)).sort(sortBydate)
        return (
            <SzBox className="d-flex detail-block5 pb-2 flex-column m-0 p-0" tag="div">
                <SzTab id="comments-tab" activeKey={tab} className="comments-tab mb-0" selectTabHandle={(tab: string) => setTab(tab)}>
                    <SzTab.SzTabContent eventKey="tab-0" title={t('comment_shared', {
                        count: sharedComments.length
                    })}>
                        <div className="row ">
                            <div className="col">
                                {commentWidget([], sharedComments)}
                            </div>
                        </div>
                    </SzTab.SzTabContent>
                    <SzTab.SzTabContent eventKey="tab-1" title={t('comment_private', {
                        count: privateComments.length
                    })}>
                        <div className="row ">
                            <div className="col">
                                {commentWidget([Roles.ROLE_SUEZ], privateComments)}
                            </div>
                        </div>
                    </SzTab.SzTabContent>
                </SzTab>
            </SzBox>
        )
    }

    const commentInputsDiv = classnames('p-1 pt-0 ml-4 mr-4 mt-4', state.showBtnSaveComment ? 'border' : 'border-bottom');

    let attachment = null

    if (allowAddAttachment) {
        attachment = <UploadAttachment
        loadingAttachment={loadingAttachment}
        uploadAttachmentHandle={uploadAttachmentHandle}
        />
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-end">
                <SzTypographie tag="div" className="w-50 mb-3" variant="text" weight="bold">{t("comment_title")}</SzTypographie>
                <div className="w-50">{attachment}</div>
            </div>
            {
                UserService.isSuez(user) ?
                    SuezCommentWidget()
                    :
                    commentWidget([], currentRequestStatusList.filter((el: any) => el.comment && !commentService.isSuez(el.commentRole)).sort(sortBydate))
            }
        </>
    )
};
