import React from "react";
import ReactLeafletSearch from "react-leaflet-search";
import { useTranslation } from "react-i18next";

const MapSearch = (props: any) => {
    const { t } = useTranslation();
    const { maxBounds, searchZoom } = props;

    return (
        <ReactLeafletSearch
            position="topright"
            inputPlaceholder={t('search_address')}
            zoom={searchZoom}
            showMarker={true}
            showPopup={false}
            closeResultsOnClick={true}
            openSearchOnLoad={false}
            providerOptions={{ searchBounds: maxBounds }}
        />
    );
};

export default MapSearch;
