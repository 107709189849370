import React, {useState, useEffect} from "react";
import {SzBox, SzButton} from "@suezenv/react-theme-components";
import "./interventionWidget.scss"
import { DateService } from "../../../services/dateService";
import VdmService from "../../../services/VdmService";
import { useTranslation } from "react-i18next";
import { AppUrls, Roles, Status } from "../../../constants";
import {config} from "../../../config";

interface IIntervention {
    id: string,
    label: string,
    beginDate: string,
    endDate: string,
    status: string,
    user: any
    request: any
}
const InterventionWidget = (props: IIntervention) => {
    const { t } = useTranslation();
    const { user, request } = props;
    const status = VdmService.getStatus(props.status)
    const interventionLink = config.itvUrl + AppUrls.ITV.replace(':interventionId', props.id);
    const [canSeeInterventionList, setCanSeeInterventionList] = useState<boolean>(false);

    useEffect(() => {
        const requestStatus = request.currentStatus.status.id
        const rolesAuthorized = user.roles.includes(Roles.ROLE_SUEZ) || user.roles.includes(Roles.ROLE_ADMIN)
        const statusAuthorized = requestStatus === Status.STATUS_DONE || requestStatus === Status.STATUS_CLOSED
        if (rolesAuthorized && statusAuthorized) {
            setCanSeeInterventionList(true)
        }
    }, []);

    return (
        <SzBox className={'row  infobox intervention status-' + status}>
            <div className="col">
                <p>{props.id}</p>
                <p className='light-text'>{props.label}</p>
            </div>

            <div className="col">
                <p className='light-text'>{t('INTERVENTION_BEGINNING')}</p>
                <p>{DateService.formatDate(props.beginDate)}</p>
            </div>
            <div className="col">
                <p className='light-text'>{t("INTERVENTION_END")}</p>
                <p>{props.endDate ? DateService.formatDate(props.endDate) : ''}</p>
            </div>

            <div className="col">
                <p className='light-text'>{t("INTERVENTION_STATUS")}</p>
                <div className="row">
                    <div className="col-auto">
                        <div className={`status-sticker status-sticker-${status}`} />
                    </div>
                    <div className="col">
                        <p>{t(`INTERVENTION_STATUS_${status}`)}</p>
                    </div>
                </div>
            </div>
            {
                canSeeInterventionList &&
                <a className={'mt-4 pr-3'}
                   href={interventionLink}
                   target={'_blank'}
                   rel="noopener noreferrer"
                >
                  <SzButton
                      icon='arrow-rectangle-right'
                      variant="tertiary">
                  </SzButton>
                </a>
            }

        </SzBox>
    )
}
export default InterventionWidget
