import instanceRequest, { Request } from './Request';
import { store } from '../store';
import RequesterService, { SUBSCRIBER_CONST, OFFICE_CONST, ORGANISATION_CONST } from '../services/RequesterService';
import {
    loadingSpinner,
    setAllRequests,
    setAssignedRequests,
    setMyRequests,
    setPendingRequests,
    setMyIndicators,
    setAllIndicators,
    setAssignedIndicators,
    setPendingIndicators,
    updateCurrentUserRequest,
    resetCurrentUserRequest,
    setRequest,
    setRequestActions,
    setRequestHistory,
    toggleListToast,
    setMDMData,
    setRequestSatusList,
    computeDelay,
    resetCurrentAttachmentRequest,
    addAttachmentRequest,
    triggerAllFilters,
    triggerAssignedFilters,
    triggerMyFilters,
    triggerPendingFilters,
    updateErrorIntervention,
} from '../actions';
import {AppUrls, Status, NOT_SET_VALUE, RequestAttachmentType, InterventionsIds, RequestListPages} from '../constants';
import { setCurrentInterventions, setGeoShapes } from '../actions/userRequests';
import {IError, IUserRequestData, IUserRequestRawTreatment} from '../types';
import moment from 'moment';
import {DateService} from "./dateService";

const GET_ALL_REQUESTS_URI = "/api/requests";
const GET_ONE_REQUEST_URI = "/api/request/__request_id__";
const GET_ONE_REQUEST_HISTORY_URI = "/api/request/__request_id__/history";
const GET_MY_REQUESTS_URI = "/api/my-requests";
const GET_MY_INDICATORS_URI = "/api/request/per-status-by-user";
const GET_ALL_INDICATORS_URI = "/api/request/per-status";
const GET_PENDING_INDICATORS_URI = "/api/request/per-additional-treatment";
const COPY_REQUEST = "api/request/copy";
const DRAFT_REQUEST = "api/request/draft";
const POST_USER_REQUEST = "api/request";
const GET_INTERVENTIONS_URI = "/api/interventions/__request_itv_ref__";
const DELETE_USER_REQUEST = "api/delete-request/__request_id__";
const PUT_USER_REQUEST = "/api/request/__request_id__";
const PAGINATION_TOTAL_COUNT = 'x-pagination-total-count';
const PAGINATION_PAGE_COUNT = 'x-pagination-page-count';
const PAGINATION_CURRENT_PAGE = 'x-pagination-current-page';
const PAGINATION_PER_PAGE = 'x-pagination-per-page';
const PAGINATION_HEARDERS = [PAGINATION_TOTAL_COUNT, PAGINATION_PAGE_COUNT, PAGINATION_CURRENT_PAGE, PAGINATION_PER_PAGE];
const MDM_DATA_URL = 'api/mdm/data';
const GEOSHAPE_DATA_URL = 'api/mdm/geoshapes'
const STATUS_URL = '/api/request/__request_id__/status';
const COMMENT_URL = '/api/request/__request_id__/comment';
const SUPERVISOR_URL = '/api/request/__request_id__/supervisor';
const COMPUTE_DELAY_URL = '/api/mdm/compute-delay';
const ATTACHMENT_URL = '/api/request/__request_id__/attachment';
const DOWNLOAD_ATTACHMENT_URL = '/api/request/__request_id__/attachment/__attachment_id__';
const GET_PERIMETER_URI = '/api/mdm/get-perimeter';
const GET_HEIMDALL_USER = '/api/heimdall/type/__role__';

class UserRequestsService {

    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    /**
     * Gets remaining delay and the deadline of a "demande".
     */
    public processDelayDetails(userRequest: any, currentRequestStatusList: any): any {
        const {currentStatus} = userRequest
        const {processingDuration, realisationDate, desiredDate} = userRequest.data;
        // get first "done" status in case the "demande" is realized without "realisationDate".
        const firstDoneStatus = this.getRequestFirstStatusById(currentRequestStatusList, Status.STATUS_DONE);
        const concreteRealisationDate = realisationDate ?? firstDoneStatus?.createdAt;
        const concreteRealisationDateObj = DateService.stringToDate(concreteRealisationDate);
        const isRealisationDone = !!(this.isRealized(currentStatus.status.id) && concreteRealisationDate);
        const dateDiffEnd = isRealisationDone ? concreteRealisationDateObj : new Date();
        let delayObject;
        let dateToShow;

        if (desiredDate) {
            delayObject = DateService.daysDiff(DateService.stringToDate(desiredDate), dateDiffEnd);
            dateToShow = desiredDate;
        } else {
            delayObject = DateService.daysDiff(DateService.stringToDate(processingDuration), dateDiffEnd);
            dateToShow = processingDuration;
        }

        return {
            delayObject,
            dateToShow,
        };
    }

    public isRealized(statusId: Status): boolean {
        return [
            Status.STATUS_CLOSED,
            Status.STATUS_CLOSED_REJECTED,
            Status.STATUS_DONE,
        ].includes(statusId);
    }

    /**
     * A request is immutable when it's realized or canceled.
     *
     * @see isRealized
     */
    public isImmutable(statusId: Status): boolean {
        return this.isRealized(statusId) || statusId === Status.STATUS_CANCELED;
    }

    /**
     * Gets the first created status from a given identifier.
     * A "demande" could have multiple same status because of comment feature, here we only retrieve the first.
     */
    public getRequestFirstStatusById(currentRequestStatusList: any, statusId: Status): {createdAt: string}|null {
        try {
            return currentRequestStatusList
              .filter((requestStatus: any): boolean => requestStatus.status.id === statusId)
              .sort((prevStatus: { createdAt: string }, nextStatus: { createdAt: string }): number =>
                (prevStatus.createdAt < nextStatus.createdAt) ? -1 : ((prevStatus.createdAt > nextStatus.createdAt) ? 1 : 0),
              )[0] ?? null
          ;
        } catch (exception) {
            return null;
        }
    }

    public getRawTreatments(categories: object, data: IUserRequestData): IUserRequestRawTreatment[]|[] {
        try {
            if (Object.keys(data || {}).length === 0 || Object.keys(categories || {}).length === 0) {
                return [];
            }

            const { requestCategory, requestSubCategory, requestExpectedTreatment } = data;

            if (! (Array.isArray(requestExpectedTreatment) && requestExpectedTreatment.length > 0)
                || !requestCategory?.id
                || !requestSubCategory?.id
            ) {
                return [];
            }

            const requestExpectedTreatmentIds = requestExpectedTreatment?.map((treatment) => treatment.id);

            // @ts-ignore
            return Object.entries(categories[requestCategory.id].subcategories[requestSubCategory?.id].treatments)
                .filter(([id]) => requestExpectedTreatmentIds.includes(id))
                .map(([id, treatment]) => Object.assign({id}, treatment))
            ;
        } catch (exception) {
            return [];
        }
    }

    public downloadFilteredRequestVisibleToUser(filter: any = {}, page?: number) {
        const body = this.axiosService.bodyToFormData(filter);
        const url = `${GET_ALL_REQUESTS_URI}?format=csv` + (page ? `&currentPage=${page}` : '');
        return this.axiosService.getBlobWithPost(url, body).then((response) => {
            return response;
        });
    }

    public downloadFilteredMyUserRequest(filter: any = {}, page?: number) {
        const body = this.axiosService.bodyToFormData(filter);
        const url = `${GET_MY_REQUESTS_URI}?format=csv` + (page ? `&currentPage=${page}` : '');
        return this.axiosService.getBlobWithPost(url, body).then((response) => {
            return response;
        });
    }

    public downloadFilteredPendingUserRequest(filter: any = {}, page?: number) {
        filter = {
            ...filter,
            withAdditionnalTreatment: true,
            status: 'done'
        }
        const body = this.axiosService.bodyToFormData(filter);
        const url = `${GET_ALL_REQUESTS_URI}?format=csv` + (page ? `&currentPage=${page}` : '');
        return this.axiosService.getBlobWithPost(url, body).then((response) => {
            return response;
        });
    }

    public downloadFilteredAssignedUserRequest(filter: any = {}, page?: number) {
        const body = this.axiosService.bodyToFormData(filter);
        const url = `${GET_ALL_REQUESTS_URI}?format=csv` + (page ? `&currentPage=${page}` : '');
        return this.axiosService.getBlobWithPost(url, body).then((response) => {
            return response;
        });
    }

    public static getPaginationInfo(headers: any): any {
        let pagination: any = [];
        Object.entries(headers).map(
          (header: any) => {
            if (PAGINATION_HEARDERS.includes(header[0])) {
                pagination[header[0]] = header[1];
            }
            return pagination;
            }
        );

        return pagination;
    }

    public getMyUserRequest(page = 1, itemsPerPage?: number) {
        store.dispatch(loadingSpinner(true));
        const endPoint = `${GET_MY_REQUESTS_URI}?currentPage=${page}` + (itemsPerPage ? `&itemsPerPage=${itemsPerPage}` : '');
        this.axiosService.post(endPoint, [])
            .then((response) => {
                const pagination = UserRequestsService.getPaginationInfo(response.headers);
                store.dispatch(setMyRequests({ 'items': response.data.requests, 'pagination': { ...pagination } }));
                store.dispatch(loadingSpinner(false));
            });
    }

    public getFilteredMyUserRequest(page = 1, filter: any = {}, sortField = 'createdAt', sortDirection = 'desc', itemsPerPage = RequestListPages.ITEMS_PER_PAGE) {
        // User Form Data here so that API will receive body as formData and not request BODY
        const body = this.axiosService.bodyToFormData(filter);

        store.dispatch(loadingSpinner(true));
        store.dispatch(triggerMyFilters({ ...filter }));
        const endPoint = `${GET_MY_REQUESTS_URI}?currentPage=${page}&sortField=${sortField}&sortDirection=${sortDirection}`
            + (itemsPerPage ? `&itemsPerPage=${itemsPerPage}` : '')
        this.axiosService.post(endPoint, body)
            .then((response) => {
                const pagination = UserRequestsService.getPaginationInfo(response.headers);
                store.dispatch(setMyRequests({ 'items': response.data.requests, 'pagination': { ...pagination } }));
                store.dispatch(loadingSpinner(false));
            });
    }

    public getFilteredPendingUserRequest(page = 1, filter: any = {}, sortField = 'createdAt', sortDirection = 'desc', itemsPerPage = RequestListPages.ITEMS_PER_PAGE) {
        // User Form Data here so that API will receive body as formData and not request BODY
        filter = {
            ...filter,
            withAdditionnalTreatment: true,
            status: 'done'
        }

        const body = this.axiosService.bodyToFormData(filter);

        store.dispatch(loadingSpinner(true));
        store.dispatch(triggerPendingFilters({ ...filter }));
        const endPoint = `${GET_ALL_REQUESTS_URI}?currentPage=${page}&sortField=${sortField}&sortDirection=${sortDirection}`
            + (itemsPerPage ? `&itemsPerPage=${itemsPerPage}` : '')
        this.axiosService.post(endPoint, body)
            .then((response) => {
                const pagination = UserRequestsService.getPaginationInfo(response.headers);
                store.dispatch(setPendingRequests({ 'items': response.data.requests, 'pagination': { ...pagination } }));
                store.dispatch(loadingSpinner(false));
            });
    }

    public getFilteredAssignedUserRequest(page = 1, filter: any = {}, sortField = 'createdAt', sortDirection = 'desc', itemsPerPage = RequestListPages.ITEMS_PER_PAGE) {
        // User Form Data here so that API will receive body as formData and not request BODY
        const body = this.axiosService.bodyToFormData(filter);

        store.dispatch(loadingSpinner(true));
        store.dispatch(triggerAssignedFilters({ ...filter }));
        const endPoint = `${GET_ALL_REQUESTS_URI}?currentPage=${page}&sortField=${sortField}&sortDirection=${sortDirection}`
            + (itemsPerPage ? `&itemsPerPage=${itemsPerPage}` : '')
        this.axiosService.post(endPoint, body)
            .then((response) => {
                const pagination = UserRequestsService.getPaginationInfo(response.headers);
                store.dispatch(setAssignedRequests({ 'items': response.data.requests, 'pagination': { ...pagination } }));
                store.dispatch(loadingSpinner(false));
            });
    }

    public getFilteredRequestVisibleToUser(page: number, filter: any = {}, sortField = 'createdAt', sortDirection = 'desc', itemsPerPage = RequestListPages.ITEMS_PER_PAGE) {
        // User Form Data here so that API will receive body as formData and not request BODY
        const body = this.axiosService.bodyToFormData(filter);

        store.dispatch(loadingSpinner(true));
        store.dispatch(triggerAllFilters({ ...filter }));
        const endPoint = `${GET_ALL_REQUESTS_URI}`
            + (page ? `?currentPage=${page}&sortField=${sortField}&sortDirection=${sortDirection}` : '')
            + (itemsPerPage ? `&itemsPerPage=${itemsPerPage}` : '');
        this.axiosService.post(endPoint, body)
            .then((response) => {
                const pagination = UserRequestsService.getPaginationInfo(response.headers);
                store.dispatch(setAllRequests({ 'items': response.data.requests, 'pagination': { ...pagination } }));
                store.dispatch(loadingSpinner(false));
            });
    }

    public getMyIndicators(filter = {}) {
        const body = this.axiosService.bodyToFormData(filter);

        this.axiosService.post(GET_MY_INDICATORS_URI, body)
            .then((response) => {
                store.dispatch(setMyIndicators({ 'count': response.data }));
            });
    }

    public getPendingIndicators(filter = {}) {
        filter = {
            ...filter,
            itemsPerPage: 9999,
            withAdditionnalTreatment: true,
            status: 'done'
        }
        const body = this.axiosService.bodyToFormData(filter);

        this.axiosService.post(GET_PENDING_INDICATORS_URI, body)
            .then((response) => {
                store.dispatch(setPendingIndicators({ 'count': response.data }));
            });
    }

    public getAssignedIndicators(filter = {}) {
        const body = this.axiosService.bodyToFormData(filter);
        this.axiosService.post(GET_ALL_INDICATORS_URI, body)
            .then((response) => {
                store.dispatch(setAssignedIndicators({ 'count': response.data }));
            });
    }

    public getAllIndicators(filter = {}) {
        const body = this.axiosService.bodyToFormData(filter);

        this.axiosService.post(GET_ALL_INDICATORS_URI, body)
            .then((response) => {
                store.dispatch(setAllIndicators({ 'count': response.data }));
            });
    }

    protected cleanPostData(currentUserRequest: any) {
        const { data } = currentUserRequest;
        // clean data before post data to server (put or get)
        delete currentUserRequest['currentStatus'];
        delete currentUserRequest['functionalId'];
        delete currentUserRequest['incrementalId'];
        delete currentUserRequest['languageCode'];
        delete currentUserRequest['originalRequest'];
        delete currentUserRequest['nextPossibleStatuses'];
        delete currentUserRequest['ownerApplication'];
        delete currentUserRequest['requestType'];
        delete currentUserRequest['context'];
        delete currentUserRequest['attachments'];
        delete currentUserRequest['id'];
        delete currentUserRequest['isAutoComplete'];
        delete currentUserRequest['createdAt'];
        delete data['userId'];
        delete data['requesterName'];
        delete data['requesterEmail'];
        delete data['deletedAt'];

        if (data.requestNature && !data.requestNature.id) {
            delete data["requestNature"];
        }
        if (!data.contactTypeOrganisation && data.contactType !== ORGANISATION_CONST) {
            delete data["contactTypeOrganisation"];
        }
        if (!data.contactTypeOffice && data.contactType !== OFFICE_CONST) {
            delete data["contactTypeOffice"];
        }
        if (!data.contactTypeSubscriber && data.contactType !== SUBSCRIBER_CONST) {
            delete data["contactTypeSubscriber"];
        }
        if (data.requestSubCategory === NOT_SET_VALUE) {
            delete data["requestSubCategory"];
        }
        if (data["requestExpectedTreatment"] && !data["requestExpectedTreatment"].length) {
            delete data["requestExpectedTreatment"];
        }
        if (!data["requestCategory"]) {
            delete data["requestSubCategory"];
        }
        if (data['desiredDate']) {
            data['desiredDate'] = moment(data['desiredDate'].toISOString(), moment.ISO_8601).format("YYYY-MM-DD HH:mm:ss")
        }

        currentUserRequest.data = data;

        return currentUserRequest;
    }

    public postDraftRequest(currentUserRequest: any, history: any, requestFiles = null, isAutoSave = false) {
        store.dispatch(loadingSpinner(!isAutoSave));
        return this.axiosService.post(DRAFT_REQUEST, this.cleanPostData(currentUserRequest))
            .then((response) => {
                this.saveAttachments(response.data.id, requestFiles);
                setTimeout(() => {
                    store.dispatch(updateCurrentUserRequest({ ...currentUserRequest, id: response.data.id }));
                    store.dispatch(loadingSpinner(false));
                    store.dispatch(toggleListToast({ show: true, isDraft: true }));
                    if (!isAutoSave) {
                        history.push(AppUrls.HOME);
                    }
                }, 1000);
                return response;
            });
    }

    public putDraftRequest(currentUserRequest: any, history: any, requestFiles = null, isAutoSave = false) {
        store.dispatch(loadingSpinner(!isAutoSave));
        const { id } = currentUserRequest;
        const url = PUT_USER_REQUEST.replace('__request_id__', id);

        return this.axiosService.put(url, this.cleanPostData(currentUserRequest))
            .then((response) => {
                this.saveAttachments(id, requestFiles);
                this.getUserRequest(id);

                setTimeout(() => {
                    store.dispatch(loadingSpinner(false));
                    store.dispatch(toggleListToast({ show: true, isDraft: true }));
                    if (!isAutoSave) {
                        history.push(AppUrls.HOME);
                    }
                }, 1000);
                return response;
            });
    }

    public resetCurrentUserRequest() {
        store.dispatch(resetCurrentUserRequest());
        store.dispatch(resetCurrentAttachmentRequest());
    }

    public getRequest(requestId: string) {
        this.getUserRequest(requestId);
        const history_url = GET_ONE_REQUEST_HISTORY_URI.replace('__request_id__', requestId);
        this.axiosService.get(history_url, false)
            .then((response) => {
                store.dispatch(setRequestHistory(response.data.data));
                store.dispatch(loadingSpinner(false));
            });
    }

    protected getUserRequest(requestId: string): any {
        store.dispatch(resetCurrentAttachmentRequest());
        store.dispatch(loadingSpinner(true));
        const url = GET_ONE_REQUEST_URI.replace('__request_id__', requestId);

        return this.axiosService.get(url, false)
            .then((response) => {
                let userRequest = response.data.data;
                userRequest.originalRequest = { ...userRequest, data: { ...userRequest.data } };
                const { data, attachments } = userRequest;
                data.contactTypeOrganisation = userRequest.data.contactTypeOrganisation.id;
                data.contactType = userRequest.data.contactType.id;
                data.requestCleansingType = userRequest.data.requestCleansingType.id;
                let requestOperationType: any = [];
                if (data.requestOperationType) {
                    data.requestOperationType.map((value: any) => {
                        requestOperationType.push(value.id);
                        return value;
                    });
                }
                data.requestOperationType = requestOperationType;
                data.requestCategory = userRequest.data.requestCategory.id;
                data.requestSubCategory = userRequest.data.requestSubCategory.id;
                data.interventionReference = userRequest.data.interventionReference;
                if (userRequest.data.desiredDate) {
                    data.desiredDate = new Date(userRequest.data.desiredDate);
                }
                let requestExpectedTreatment: any = [];
                if (data.requestExpectedTreatment) {
                    data.requestExpectedTreatment.map((value: any) => {
                        requestExpectedTreatment.push(value.id);
                        return value;
                    });
                }
                data.requestExpectedTreatment = requestExpectedTreatment;
                data.requestHumanRisk = (userRequest.data.requestHumanRisk === 'true');
                data.requestPropertyRisk = (userRequest.data.requestPropertyRisk === 'true');
                data.requestServiceRisk = (userRequest.data.requestServiceRisk === 'true');
                userRequest.data = data;

                store.dispatch(setRequest(userRequest));
                store.dispatch(setRequestActions(response.data.actions));
                let otherAttachment: any = [];
                let locationPlan: any = [];
                attachments.forEach((value: any) => {
                    const { file } = value;
                    const { id, originalFileName, size, mimeType, type } = file;
                    const fileObject = { file: { name: originalFileName, size, mimeType, id }, type };
                    if (type === RequestAttachmentType.LOCATION_PLAN) {
                        locationPlan.push(fileObject);
                    } else {
                        otherAttachment.push(fileObject);
                    }
                });
                if (locationPlan.length > 0) {
                    store.dispatch(addAttachmentRequest(locationPlan))
                }
                if (otherAttachment.length > 0) {
                    store.dispatch(addAttachmentRequest(otherAttachment))
                }
                if (data.interventionReference) {
                    this.getInterventions(data.interventionReference, false)
                } else {
                    this.clearInterventions()
                }

                store.dispatch(loadingSpinner(false));
            });
    }

    public copyRequest(requestId: string, history: any) {
        const body = {
            'id': requestId
        };
        store.dispatch(loadingSpinner(true));
        this.axiosService.post(COPY_REQUEST, body, false)
            .then((response) => {
                const requestId = response.data['id'];
                this.getUserRequest(requestId).then(() => {
                    store.dispatch(loadingSpinner(false));
                    history.push(AppUrls.CREATE_REQUEST);
                });
            });
    }

    public editRequest(draftIs: string, history: any) {
        this.getUserRequest(draftIs).then(() => {
            history.push(AppUrls.CREATE_REQUEST);
        });
    }

    public getInterventions(interventionReference: string, isCancel: boolean) {
        const urlItv = GET_INTERVENTIONS_URI.replace('__request_itv_ref__', interventionReference);
        return this.axiosService.get(urlItv, isCancel).then((response) => {
            const interventions = response.data['interventions'];
            let currentInterventions: any = [];
            if (undefined !== interventions[0] && undefined !== interventions[0].g2RequestIntervention) {
                currentInterventions = interventions[0].g2RequestIntervention.filter((item: any) => {
                    const { statut } = item;
                    if (Object.values(InterventionsIds).includes(statut)) {
                        return item;
                    }
                    return false
                });
            }
            store.dispatch(setCurrentInterventions(currentInterventions));
        })
            .catch(() => {
            });
    }

    public resetInterventionError() {
        const initialState: IError = {
            isError: false,
            message: "",
            status: null,
        };
        store.dispatch(updateErrorIntervention(initialState))
    }

    public clearInterventions() {
        store.dispatch(setCurrentInterventions([]));
    }

    public getMdmData() {
        store.dispatch(loadingSpinner(false));
        return this.axiosService.get(MDM_DATA_URL, false)
            .then((response) => {
                store.dispatch(setMDMData(response.data.data));
                RequesterService.setDataForm(response.data.data);
                store.dispatch(loadingSpinner(false));
            });
    }

    public getGeoShapes() {
        store.dispatch(loadingSpinner(true));
        return this.axiosService.get(GEOSHAPE_DATA_URL, false)
            .then((response) => {
                store.dispatch(setGeoShapes(UserRequestsService.formatGeoshapes(response.data)));
                store.dispatch(loadingSpinner(false));
            });
    }

    private static formatGeoshapes(data: any) {
        return {
            "type": "FeatureCollection",
            "features":
                data.geoshapes.flatMap((item: any) => {
                        return item.geojson.map((shape: any) => {
                            return shape
                        })

                    }
                )
        }
    }

    public postUserRequest(currentUserRequest: any, history: any, requestFiles = null) {
        store.dispatch(loadingSpinner(true));
        return this.axiosService.post(POST_USER_REQUEST, this.cleanPostData(currentUserRequest))
            .then((response) => {
                this.saveAttachments(response.data.id, requestFiles);
                setTimeout(() => {
                    store.dispatch(loadingSpinner(false));
                    store.dispatch(toggleListToast({ show: true, isDraft: false }));
                    history.push(AppUrls.HOME);
                }, 1000);
                return response;
            });
    }

    public deleteUserRequest(requestId: string, history: any) {
        store.dispatch(loadingSpinner(true));
        const delete_url = DELETE_USER_REQUEST.replace('__request_id__', requestId);
        this.axiosService.delete(delete_url, false)
            .then(() => {
                setTimeout(() => {
                    history.push(AppUrls.HOME);
                    store.dispatch(loadingSpinner(false));
                    store.dispatch(toggleListToast({ show: false, isDraft: false }));
                }, 1000);
            });
    }

    public putUserRequest(currentUserRequest: any, requestFiles = null, notifySupervisor = false) {
        store.dispatch(loadingSpinner(true));
        const { originalRequest, id } = currentUserRequest;
        const currentStatus = originalRequest.currentStatus.status.id;
        // Drafts and request to specify should be PUT as to qualify
        const postAsToQualify = ~[Status.STATUS_DRAFT, Status.STATUS_TO_SPECIFY].indexOf(currentStatus);
        const url = PUT_USER_REQUEST.replace('__request_id__', id) + "?isValidatedRequest=true";

        return this.axiosService.put(url, this.cleanPostData(currentUserRequest))
            .then(() => {
                if (postAsToQualify || !currentStatus) {
                    this.postStatus(id, Status.STATUS_TO_QUALIFY)
                }
                this.saveAttachments(id, requestFiles);
                this.getUserRequest(id).then(() => {
                    const {currentRequest} = store.getState().userRequests;
                    if (currentRequest["data"]["supervisorUserId"] && notifySupervisor) {
                        setTimeout(() => {
                            this.postSupervisor(id, currentRequest["data"]["supervisorUserId"], true)
                        }, 2000);
                    }
                });
                store.dispatch(loadingSpinner(true));
                setTimeout(() => {
                    store.dispatch(loadingSpinner(false));
                    store.dispatch(toggleListToast({ show: true, isDraft: false }));
                }, 2000);
            });
    }

    public postStatus(requestId: string, status: string, comment = '') {
        const url = STATUS_URL.replace('__request_id__', requestId);
        const data = {
            "status": status,
            "comment": comment
        };
        store.dispatch(loadingSpinner(true));
        return this.axiosService.post(url, data).then(() => {
            store.dispatch(loadingSpinner(false));
        });
    }

    public postComment(requestId: string, comment: string, roles: string[]) {
        const url = COMMENT_URL.replace('__request_id__', requestId);
        const data: any = {
            "comment": comment,
            "commentRole": roles
        };
        store.dispatch(loadingSpinner(true));
        return this.axiosService.post(url, data).then(() => {
            store.dispatch(loadingSpinner(false));
        });
    }

    public postSupervisor(requestId: string, supervisor: string, isAutomaticSupervisorAllocation: boolean) {
        const url = SUPERVISOR_URL.replace('__request_id__', requestId);
        const data: any = {
            "supervisor": supervisor,
            "isAutomaticSupervisorAllocation": isAutomaticSupervisorAllocation
        };
        store.dispatch(loadingSpinner(true));
        return this.axiosService.post(url, data).then(() => {
            store.dispatch(loadingSpinner(false));
        });
    }

    public getStatusList(requestId: string) {
        const url = STATUS_URL.replace('__request_id__', requestId);
        store.dispatch(loadingSpinner(true));
        return this.axiosService.get(url, false).then((response) => {
            store.dispatch(setRequestSatusList(response.data.data));
            store.dispatch(loadingSpinner(false));
        });
    }

    public computeDelay(categoryId: string, desiredDate: Date, treatment: string[], requestHumanRisk: boolean, requestPropertyRisk: boolean, requestServiceRisk: boolean) {
        let desiredDateToSend = desiredDate ? moment(desiredDate.toISOString(), moment.ISO_8601).format() : null

        let data: any = {
            "categoryId": categoryId,
            "desiredDate": desiredDateToSend,
            "treatments": treatment,
            "humanRisk": requestHumanRisk,
            "propertyRisk": requestPropertyRisk,
            "serviceRisk": requestServiceRisk,
        };
        return this.axiosService.post(COMPUTE_DELAY_URL, data, true).then((response) => {
            store.dispatch(computeDelay(response.data.estimated));
        }).catch(() => {
        });
    }

    public resetDelay() {
        store.dispatch(computeDelay(''));
    }

    postAttachments(userRequestId: string, attachment: any) {
        const url = ATTACHMENT_URL.replace('__request_id__', userRequestId);
        const data = new FormData();
        data.append('attachment', attachment.file);
        data.append('type', attachment.type);

        attachment.file.uploading = true;
        return this.axiosService.post(url, data).then((response) => {
            attachment.file.uploading = undefined;
            attachment.file.id = response.data.id_attachment;
        });
    }

    saveAttachments(userRequestId: string, requestFiles: any) {
        if (requestFiles !== null) {
            let allFiles = (requestFiles.locationPlan) ? [requestFiles.locationPlan] : [];
            allFiles = [...allFiles, ...requestFiles.other];
            allFiles.forEach((attachment: any) => {
                // if the attachment is not already saved
                if (attachment.file && attachment.file.id === undefined && attachment.file.uploading !== true) {
                    this.postAttachments(userRequestId, attachment);
                }
            });
        }
    }

    downloadAttachment(userRequestId: string, attachmentId: string) {
        const url = DOWNLOAD_ATTACHMENT_URL.replace('__request_id__', userRequestId).replace('__attachment_id__', attachmentId);
        return this.axiosService.getBlob(url, false).then((response) => {
            return response;
        });
    }

    getPerimeter(lat: any, lng: any) {
        store.dispatch(loadingSpinner(true));
        const body = this.axiosService.bodyToFormData({ lat, lng });
        return this.axiosService.post(GET_PERIMETER_URI, body)
            .then((response) => {
                store.dispatch(loadingSpinner(false));
                return response
            });
    }

    getHeimdallUsers(role: string) {
        store.dispatch(loadingSpinner(true));
        const url = GET_HEIMDALL_USER.replace('__role__', role).replace('ROLE_', '').toLowerCase();
        return this.axiosService.get(url, false, true).then((response) => {
            store.dispatch(loadingSpinner(false));
            return response
        })
        .catch(err => {
        });
    }
}

export default new UserRequestsService(instanceRequest);
