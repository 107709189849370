import { Constants } from "../constants";
import { IStep, StepActionTypes } from "../types";

const initialState: IStep = {
    steps: []
};
const stepReducers = (state = initialState, action: StepActionTypes): IStep => {
    switch (action.type) {
        case Constants.GET_STEPS:
            return { ...state, steps: action.payload.steps }
        default:
            return state
    }
}
export default stepReducers