import React, { useState } from "react";
import Control from 'react-leaflet-control';
import { useTranslation } from "react-i18next";
import { ReactComponent as SvgRequestClosed } from "../mapComponent/img/closed.svg";
import { ReactComponent as SvgRequestClosedRejected } from "../mapComponent/img/closed_rejected.svg";
import { ReactComponent as SvgRequestDone } from "../mapComponent/img/done.svg";
import { ReactComponent as SvgRequestOngoing } from "../mapComponent/img/ongoing.svg";
import { ReactComponent as SvgRequestPlanned } from "../mapComponent/img/planned.svg";
import { ReactComponent as SvgQuestion } from "../mapComponent/img/question.svg";
import { ReactComponent as SvgRequestToPlan } from "../mapComponent/img/to_plan.svg";
import { ReactComponent as SvgRequestToQualifySpecify } from "../mapComponent/img/to_qualify_specify.svg";
import './mapLegend.scss';

const MapLegend = () => {
    const { t } = useTranslation();
    const [showLegend, setShowLegend] = useState(false);

    return (
        <Control position="topright">
            <div className="leaflet-bar">
                <button onClick={() => setShowLegend(!showLegend)}>
                    <SvgQuestion/>
                </button>
            </div>
            {showLegend &&
            <div className="map-legend">
                <div className="box-shadow">
                    <p className="legend-title">{t('map_legend')}</p>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <SvgRequestToQualifySpecify className="legend-marker request-icon__to-qualify"/>
                            <span>{t('to_qualify')}</span>
                        </li>
                        <li className="list-group-item">
                            <SvgRequestToQualifySpecify className="legend-marker request-icon__to-specify"/>
                            <span>{t('to_specify')}</span>
                        </li>
                        <li className="list-group-item">
                            <SvgRequestToPlan className="legend-marker request-icon__to-plan"/>
                            <span>{t('to_plan')}</span>
                        </li>
                        <li className="list-group-item">
                            <SvgRequestPlanned className="legend-marker request-icon__planned"/>
                            <span>{t('planned')}</span>
                        </li>
                        <li className="list-group-item">
                            <SvgRequestOngoing className="legend-marker request-icon__ongoing"/>
                            <span>{t('ongoing')}</span>
                        </li>
                        <li className="list-group-item">
                            <SvgRequestDone className="legend-marker request-icon__done"/>
                            <span>{t('done')}</span>
                        </li>
                        <li className="list-group-item">
                            <SvgRequestClosed className="legend-marker request-icon__closed"/>
                            <span>{t('closed')}</span>
                        </li>
                        <li className="list-group-item">
                            <SvgRequestClosedRejected className="legend-marker request-icon__closed-rejected"/>
                            <span>{t('closed_rejected')}</span>
                        </li>
                    </ul>
                </div>
            </div>
            }
        </Control>
    );
};

export default MapLegend;
