import { Constants, RequestAttachmentType } from "../constants";
const initialState = {
    tab: 'tab-0',
    all: [],
    my: [],
    pending: [],
    assigned: [],
    pages: {
        all: 1,
        my: 1,
        pending: 1,
        assigned: 1,
    },
    sortBy: {
        all: { direction: 'desc', field: 'submissionDate' },
        my: { direction: 'desc', field: 'submissionDate' },
        pending: { direction: 'desc', field: 'submissionDate' },
        assigned: { direction: 'desc', field: 'submissionDate' },
    },
    allFilters: {
        status: null,
        risk: null,
        category: null,
        subCategory: null,
        organisation: null,
        town: null,
        operation: null,
        cleansing: null,
        incrementalId: '',
        isValid: false,
        dateRange: [null, null],
        selectedFilter: [],
    },
    myFilters: {
        status: null,
        risk: null,
        category: null,
        subCategory: null,
        organisation: null,
        town: null,
        operation: null,
        cleansing: null,
        incrementalId: '',
        isValid: false,
        dateRange: [null, null],
        selectedFilter: [],
    },
    pendingFilters: {
        status: null,
        risk: null,
        category: null,
        subCategory: null,
        organisation: null,
        town: null,
        operation: null,
        cleansing: null,
        incrementalId: '',
        isValid: false,
        dateRange: [null, null],
        selectedFilter: [],
    },
    assignedFilters: {
        status: null,
        risk: null,
        category: null,
        subCategory: null,
        organisation: null,
        town: null,
        operation: null,
        cleansing: null,
        incrementalId: '',
        isValid: false,
        dateRange: [null, null],
        selectedFilter: [],
    },
    currentActions: {
        copy: false
    },
    currentRequest: {
        subject: '',
        comment: '',
        address: {
            address: '',
            city: '',
            street: '',
            number: '',
            postalCode: '',
            latitude: '',
            longitude: '',
            country: ''
        },
        incrementalId: null,
        functionalId: '',
        data: {
            addressAdditionalInformation: '',
            requestCategory: null,
            processingDuration: ''
        },
        currentStatus: {
            status: {
                label: ''
            }
        },
        originalRequest: {
            subject: '',
            comment: '',
            address: {
                address: '',
                city: '',
                street: '',
                number: '',
                postalCode: '',
                latitude: '',
                longitude: '',
                country: ''
            },
            data: {
                addressAdditionalInformation: '',
                requestCategory: { id: '', label: '' },
                requestSubCategory: { id: '', label: '' },
                requestCleansingType: { id: '', label: '' },
                requestOperationType: [],
                selectedTreatments: [],
            },
            currentStatus: {
                status: {
                    label: ''
                }
            }
        }
    },
    currentRequestHistory: [],
    currentInterventions: [],
    currentRequestStatusList: [],
    currentRequestFiles: {
        locationPlan: null,
        other: []
    },
    showToast: { show: false, isDraft: false },
    mdmData: {
        categories: null,
        contactTypes: null,
        organisations: null,
        operationTypes: null,
        cleansingTypes: null
    },
    shapes: null
};

const userRequestsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Constants.SET_ALL_USER_REQUESTS:
            return { ...state, all: action.payload };
        case Constants.SET_MY_USER_REQUESTS:
            return { ...state, my: action.payload };
        case Constants.SET_PENDING_USER_REQUESTS:
            return { ...state, pending: action.payload };
        case Constants.SET_ASSIGNED_USER_REQUESTS:
            return { ...state, assigned: action.payload };
        case Constants.TOGGLE_LIST_TOAST:
            return { ...state, showToast: action.payload };
        case Constants.SET_REQUEST:
            return { ...state, currentRequest: action.payload };
        case Constants.SET_REQUEST_ACTIONS:
            return { ...state, currentActions: action.payload };
        case Constants.SET_REQUEST_HISTORY:
            return { ...state, currentRequestHistory: action.payload };
        case Constants.UPDATE_CURRENT_USER_REQUEST:
        case Constants.COORDINATES_TO_ADDRESS:
            return { ...state, currentRequest: { ...state.currentRequest, ...action.payload } };
        case Constants.UPDATE_CURRENT_INTERVENTIONS:
            return { ...state, currentInterventions: action.payload };
        case Constants.RESET_CURRENT_USER_REQUEST:
            return { ...state, currentRequest: { ...initialState.currentRequest } };
        case Constants.SET_MDM_DATA:
            return { ...state, mdmData: action.payload };
        case Constants.SET_GEOSHAPES:
            return { ...state, shapes: action.payload };
        case Constants.SET_REQUEST_STATUS_LIST:
            return { ...state, currentRequestStatusList: action.payload };
        case Constants.COMPUTE_DELAY:
            return {
                ...state, currentRequest: { ...state.currentRequest, data: { ...state.currentRequest.data, processingDuration: action.payload } }
            };
        case Constants.ADD_ATTACHMENT_REQUEST:
            const files = action.payload;
            if (files[0].type === RequestAttachmentType.LOCATION_PLAN) {
                return { ...state, currentRequestFiles: { ...state.currentRequestFiles, locationPlan: files[0] } }
            } else {
                return { ...state, currentRequestFiles: { ...state.currentRequestFiles, other: [...files] } }
            }
        case Constants.RESET_CURRENT_ATTACHMENT_REQUEST:
            return { ...state, currentRequestFiles: initialState.currentRequestFiles }
        case Constants.SET_MY_INDICATORS:
            return { ...state, myIndicators: action.payload.count };
        case Constants.SET_PENDING_INDICATORS:
            return { ...state, pendingIndicators: action.payload.count };
        case Constants.SET_ASSIGNED_INDICATORS:
            return { ...state, assignedIndicators: action.payload.count };
        case Constants.SET_ALL_INDICATORS:
            return { ...state, allIndicators: action.payload.count };
        case Constants.TRIGGER_ALL_FILTERS:
            return { ...state, allFilters: action.payload };
        case Constants.TRIGGER_MY_FILTERS:
            return { ...state, myFilters: action.payload };
        case Constants.TRIGGER_PENDING_FILTERS:
            return { ...state, pendingFilters: action.payload };
        case Constants.TRIGGER_ASSIGNED_FILTERS:
            return { ...state, assignedFilters: action.payload };
        case Constants.SET_LIST_TAB:
            return { ...state, tab: action.payload };
        case Constants.SET_CURRENT_PAGE:
            return { ...state, pages: action.payload };
        case Constants.SET_SORT_BY:
            return { ...state, sortBy: {...state.sortBy, ...action.payload} };
        default:
            return state;
    }
};


export default userRequestsReducer;
