import { Constants } from "../constants";

const initialState: any = { items: [] }
const addressAutoCompleteReducers = (state = initialState, action: any): any => {
    switch (action.type) {
        case Constants.AUTO_COMPLETE_ADDRESS:
            return { ...state, items: action.payload };
        default:
            return state;
    }
}
export default addressAutoCompleteReducers